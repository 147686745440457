import axios from 'axios';
import {
  INVALIDATE_PROPERTY_ENQUIRY,
  POST_PROPERTY_ENQUIRY,
  RECEIVE_PROPERTY_ENQUIRY
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/constants';
import {
  selectApiLycanHostConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  makeSelectPropertyEnquiryData,
  selectPropertyEnquiryPropertyId
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/selectors';

const createEnquiry = store => next => action => {

  // Pass all actions through by default
  next(action);

  switch (action.type) {
    case POST_PROPERTY_ENQUIRY:

      const state = store.getState();
      const { app } = selectElasticSearchConfig(state);
      const host = selectApiLycanHostConfig(state);
      const siteName = selectSiteNameConfig(state);
      const propertyId = selectPropertyEnquiryPropertyId(state);
      const enquiryData = makeSelectPropertyEnquiryData(state);
      const enquiryEndpoint = host + "/api/listings/" + propertyId + "/reservation";

      /*
      console.log({enquiryData});
      setTimeout(() => {
        next({type: RECEIVE_PROPERTY_ENQUIRY, data: enquiryData});
      }, 1000);
      */

      axios({
        method: 'post',
        url: enquiryEndpoint,
        data: enquiryData,
        headers: {
          'Accept': 'application/json',
          'X-App': siteName,
          'X-Index': app,
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      }).then(({ status, data }) => {
        if (status >= 200 || status < 300) {
          next({type: RECEIVE_PROPERTY_ENQUIRY, data});
        } else {
          next({type: INVALIDATE_PROPERTY_ENQUIRY, error: 'Could not send enquiry'});
        }
      }).catch((error) => {
        console.log({error: error.message});
        next({type: INVALIDATE_PROPERTY_ENQUIRY, error: error.message});
      });

      break;

    // Do nothing if the action does not interest us
    default:
      break;
  }
};

export default createEnquiry;
