import Input from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/input';

const parts = ["field", "stepper", "stepperGroup"];

const { variants, defaultProps } = Input;

const baseStyleField = Input.baseStyle.field;


const baseStyle = (props) => ({
  field: baseStyleField,
});

export default {
  parts,
  baseStyle,
  variants,
  defaultProps
}
