import Button from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/button';
import Link from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/link';
import Heading from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/heading';
import Text from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/text';
import Popover from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/popover';
import Tag from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/tag';
import HeaderNavbar from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/headerNavbar';
import Menu from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/menu';
import Badge from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/badge';
import Input from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/input';
import Textarea from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/textarea';
import Select from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/select';
import NumberInput from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/numberInput';
import UpdateBar from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/updateBar';

const components = {
  Button,
  Menu,
  Link,
  Heading,
  Text,
  Popover,
  Input,
  Textarea,
  Select,
  NumberInput,
  Tag,
  Badge,
  HeaderNavbar,
  UpdateBar
};


export default components;
