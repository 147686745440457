import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateLanguage } from '@rentivo/gatsby-core/src/containers/LanguageProvider/actions';
import isEmpty from 'lodash/isEmpty';
import { setCollection } from '@rentivo/gatsby-core/src/containers/SearchProvider/actions';
//import { hasUrlParams } from '@rentivo/gatsby-core/src/utils/urlParams';

const App = ({ pageContext: { lang, locale, searchData, collection }, uri, children, ...rest }) => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  //const hasParams = hasUrlParams();

  // Update uri from router
  dispatch(updateLanguage({uri, lang, locale}));

  useEffect(() => {
    if(!mounted.current) {

      //console.log({uri, lang, locale});

      // Update uri from router
      //dispatch(updateLanguage({uri, lang, locale}));

      // Update details if there...

      // TODO: This has recently been removed. Not sire if it's going to break anything.
      //if(!isEmpty(collection)) {
        //console.log('collection is not empty');
        //dispatch(setCollection((collection) ? collection : null));
      //}

      //if(!isEmpty(searchData)) dispatch(receiveData(stripSearchDataOfHits(searchData))); // Don't update hits...
      mounted.current = true;
    }
  }, []);

  return children;
};



export default App;
