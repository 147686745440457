import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import createStore from '@rentivo/gatsby-core/src/state/createStore';
import { StoreProvider } from '@rentivo/gatsby-core/src/context/StoreContext';
import { LocationProvider } from '@reach/router';
import ThemeProvider from '@rentivo/gatsby-core/src/containers/ThemeProvider';
import { ErrorBoundary } from '@sentry/gatsby';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'intersection-observer';
import 'resize-observer-polyfill';

// TODO: Move this to the build... or make it dynamic?
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/it';

//import siteConfigLocal from '@rentivo/gatsby-schema/siteConfig.tv'; // minus

config.autoAddCss = false;

export default ({ element, pathname }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mount

  return (
    <StaticQuery
      query={graphql`
      query WrapRootQuery {
        globalContext {
          siteConfigString
        }
      }
    `}
      render={(result) => {
        let theme = {};
        let initialState = {};
        let siteConfig = {};
        const { globalContext } = result || {};
        const { siteConfigString } = globalContext || {};

        siteConfig = (siteConfigString) ? JSON.parse(siteConfigString) : {}; // Compression could save around 50-70 kb.... 70kb would only be possible on webkit browsers.

        // plus
        // DEVELOPMENT... TURN OFF FOR PROD
        //siteConfig = { ...siteConfig, ...siteConfigLocal, currency: { ...siteConfigLocal.currency, currencyExchangeMap: siteConfig.currency.currencyExchangeMap } };

        const {
          language: siteLanguage,
          currency: { defaultCurrency: currentCurrency, currencyExchangeMap },
          theme: { variables: { base, theme: themeVars } },
          chakraTheme, // change
          translations
        } = siteConfig;
        const { languages, locales, defaultLang } = siteLanguage;
        const matchAllAppLocalesRegex = '/:locale(' + languages.join('|') + ')/:path*';

        ///For prod
        theme = {
          ...base,
          template: themeVars
        };

        // For dev
        /*
        theme = {
          ...antdVars,
          ...coreVars,
          template: themeVars
        };*/

        initialState = {
          global: {
            siteConfig // TODO: Move this outside of global??
          },
          language: {
            locales,
            languages,
            defaultLang,
            translations,
            matchAllAppLocalesRegex
          },
          currency: {
            currentCurrency,
            currencyExchangeMap
          }
        };

        const store = createStore(initialState);
        const persistor = persistStore(store);

        return (
          <ErrorBoundary fallback={"An error has occurred"}>
            <Provider store={store}>
              <StoreProvider store={store} persistor={persistor}>
                <LocationProvider>
                  <ThemeProvider theme={theme} chakraTheme={chakraTheme}>
                    {element}
                  </ThemeProvider>
                </LocationProvider>
              </StoreProvider>
            </Provider>
          </ErrorBoundary>
        );

      }}
    />
  );
};
