import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from '@rentivo/gatsby-core/src/containers/App/reducer'
import languageProviderReducer from '@rentivo/gatsby-core/src/containers/LanguageProvider/reducer'
import searchProviderReducer from '@rentivo/gatsby-core/src/containers/SearchProvider/reducer'
import currencyProviderReducer from '@rentivo/gatsby-core/src/containers/CurrencyProvider/reducer'
import commerceProviderReducer from '@rentivo/gatsby-core/src/containers/CommerceProvider/reducer'
import pricingReducer from '@rentivo/gatsby-core/src/containers/PricingProvider/reducer'
import propertyEnquiryReducer from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/reducer'
import enquiryProviderReducer from '@rentivo/gatsby-core/src/containers/EnquiryProvider/reducer'
import themeReducer from '@rentivo/gatsby-core/src/theme/reducer'
import esReducer from '@rentivo/gatsby-core/src/containers/ESProvider/reducer'

const currencyPersistConfig = {
  key: 'currency',
  storage: storage,
  whitelist: ['currentCurrency'],
  blacklist: ['currencyExchangeMap'],
}

const propertyEnquiryPersistConfig = {
  key: 'propertyEnquiry',
  storage: storage,
  whitelist: ['form'],
}

const enquiryProviderReducerPersistConfig = {
  key: 'enquiry',
  storage: storage,
  whitelist: ['formsValues'],
}

const commercePersistConfig = {
  key: 'commerce',
  storage: storage,
  //whitelist: ['cart', 'cartTotal', 'customer', '']
  blacklist: ['data', 'error', 'didInvalidate', 'isFetching'],
}

const searchPersistConfig = {
  key: 'search',
  storage: storage,
  whitelist: ['mapOpen'],
}

export default combineReducers({
  global: appReducer,
  language: languageProviderReducer,
  search: persistReducer(searchPersistConfig, searchProviderReducer),
  commerce: persistReducer(commercePersistConfig, commerceProviderReducer),
  pricing: pricingReducer,
  propertyEnquiry: persistReducer(
    propertyEnquiryPersistConfig,
    propertyEnquiryReducer
  ),
  es: esReducer,
  enquiry: persistReducer(
    enquiryProviderReducerPersistConfig,
    enquiryProviderReducer
  ),
  currency: persistReducer(currencyPersistConfig, currencyProviderReducer),
  theme: themeReducer,
})
