const baseStylePopper = {
  w: "100%",
  maxW: "xs",
  zIndex: 10,
};

export default {
  parts: ["popper", "content"],
  baseStyle: {
    popper: ({width, w}) => ({
      ...baseStylePopper,
      maxW: (width) ? width : (w) ? w : baseStylePopper.maxW,
    }),
    content: {
      boxShadow: "lg",
      "_focus": {
        boxShadow: "lg"
      }
    }
  }
}
