import { mode } from '@chakra-ui/theme-tools';
import Input from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/input';

const { sizes, defaultProps, variants } = Input;

const parts = ["field", "icon"];

function baseStyleField(props) {
  return {
    ...Input.baseStyle.field,
    appearance: "none",
    paddingBottom: "1px",
    lineHeight: "normal",
    "> option": {
      bg: mode("white", "gray.700")(props),
    }
  }
}

const baseStyleInput = {
  color: "currentColor",
  fontSize: "1.25rem",
  _disabled: { opacity: 0.5 }
};

const baseStyle = (props) => ({
  field: baseStyleField(props),
  icon: baseStyleInput
});

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}
