import axios from 'axios'
import {
  INVALIDATE_PRICING_DATA,
  RECEIVE_PRICING_DATA,
  REQUEST_PRICING_DATA,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants'
import {
  selectApiLycanHostConfig,
  selectApiLycanSendCurrencyOnPriceRequestsConfig,
  selectDefaultCurrencyConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig,
} from '@rentivo/gatsby-core/src/selectors/siteConfig'
import { selectPricing } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors'
import {
  invalidateData,
  startAddPricingToCart,
  startReceiveData,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/actions'

const fetchPricingData = store => next => action => {
  // Pass all actions through by default
  next(action)

  const { dispatch } = store

  switch (action.type) {
    case REQUEST_PRICING_DATA:
      const inCheckout = action.inCheckout

      const state = store.getState()
      const { app } = selectElasticSearchConfig(state)
      const host = selectApiLycanHostConfig(state)
      // const host = 'https://nice-shrimp-21.loca.lt';
      const sendCurrencyOnPriceRequests = selectApiLycanSendCurrencyOnPriceRequestsConfig(
        state
      )
      const siteName = selectSiteNameConfig(state)
      const defaultCurrency = selectDefaultCurrencyConfig(state)
      const {
        guests,
        adults,
        children,
        infants,
        pets,
        startDate,
        endDate,
        propertyId,
        propertyName,
      } = selectPricing(state)
      const inputsCache = {
        guests,
        adults,
        children,
        infants,
        pets,
        startDate,
        endDate,
        propertyId,
        propertyName,
      }

      const currency = action.currency ? action.currency : defaultCurrency

      let params =
        '?arrival=' +
        startDate +
        '&departure=' +
        endDate +
        '&propertyUuid=' +
        propertyId +
        '&propertyName=' +
        (propertyName ? encodeURIComponent(propertyName) : '') +
        '&guests=' +
        guests +
        '&adults=' +
        adults + // Lycan doesn't support just yet
        '&children=' +
        children + // Lycan doesn't support just yet
        '&infants=' +
        infants + // Lycan doesn't support just yet
        '&pets=' +
        pets

      if (sendCurrencyOnPriceRequests || action.currency) {
        params += '&currency=' + currency
      }

      const priceEndpoint =
        host + '/api/public/listing/' + propertyId + '/pricing' + params

      axios({
        method: 'get',
        baseURL: priceEndpoint,
        headers: {
          Accept: 'application/json',
          'X-App': `Rentivo Gatsby3: ${siteName}`,
          //'X-Index': app,
          //'Bypass-Tunnel-Reminder': 'Yes'
        },
      })
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch(startReceiveData(data, inputsCache, inCheckout)) //next({type: RECEIVE_PRICING_DATA, data});
          } else {
            next({
              type: INVALIDATE_PRICING_DATA,
              error: 'Could not generate price',
            })
          }
        })
        .catch(error => {
          console.log({ error: error.message })
          next({ type: INVALIDATE_PRICING_DATA, error: error.message })
        })

      break

    // Do nothing if the action does not interest us
    default:
      break
  }
}

export default fetchPricingData
