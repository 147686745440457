import {
  UPDATE_SITE_CONFIG,
  UPDATE_WINDOW_SIZE,
  INIT_WINDOW_SIZE,
  ROUTE_CHANGE,
  INIT_DEVICE,
  UPDATE_IS_LOADER_ON,
  CURRENT_DEVICE
} from './constants';

// The initial state of the App
export const initialState = {
  loading: false,
  isLoaderOn: false,
  error: false,
  currentUser: null,
  siteConfig: {},
  windowSize: INIT_WINDOW_SIZE,
  device: INIT_DEVICE,
  routeMatch: null,
  routeLocation: null,
  routeMeta: null
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SITE_CONFIG:
      return {
        ...state,
        siteConfig: action.siteConfig,
      };
    case UPDATE_WINDOW_SIZE:
      return {
        ...state,
        device: CURRENT_DEVICE(action.windowSize.windowWidth),
        windowSize: action.windowSize,
      };
    case ROUTE_CHANGE:
      return {
        ...state,
        routeMatch: action.computedMatch,
        routeLocation: action.location,
        routeMeta: action.routeMeta
      };
    case UPDATE_IS_LOADER_ON:
      return {
        ...state,
        isLoaderOn: action.isLoaderOn
      };
    default:
      return state;
  }
}

export default appReducer;
