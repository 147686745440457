import get from 'lodash/get';
import { createSelector } from 'reselect';
import { selectGlobal } from '@rentivo/gatsby-core/src/containers/App/selectors';
import {
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import {
  selectCheckoutDefaultPrivacyPolicyConfig, selectCheckoutDefaultTermsAndConditionsConfig,
  selectCheckoutShowMarketingOptInConfig, selectCheckoutShowPrivacyPolicyConfig,
  selectCheckoutShowTermsAndConditionsConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const selectCommerce = state => get(state, 'commerce', {});
export const selectCommerceCart = state => get(selectCommerce(state), 'cart', []);
export const selectCommerceReservationType = state => get(selectCommerce(state), 'reservationType', RESERVATION_TYPE_INSTANT_BOOKING);
export const selectCommerceChargeCurrency = state => get(selectCommerce(state), 'chargeCurrency', 'USD');
export const selectCommerceChargeExchangeRate = state => get(selectCommerce(state), 'chargeExchangeRate', 1);
export const selectCommerceCartTotalDueToday = state => get(selectCommerce(state), 'cartTotalDueToday', 0);
export const selectCommerceCartTotalChargeDueToday = state => get(selectCommerce(state), 'cartTotalChargeDueToday', 0);
export const selectCommerceCartTotal = state => get(selectCommerce(state), 'cartTotal', 0);
export const selectCommerceCurrentStep = state => get(selectCommerce(state), 'currentStep', 0);
export const selectCommerceSteps = state => get(selectCommerce(state), 'steps', []);
export const selectCommerceCustomer = state => get(selectCommerce(state), 'customer', null);
export const selectCommerceCustomerInfo = state => get(selectCommerceCustomer(state), 'info', {});
export const selectCommerceCustomerBilling = state => get(selectCommerceCustomer(state), 'billing', {});

export const selectCommerceData = state => get(selectCommerce(state), 'data', null);
export const selectCommerceIsFetching = state => get(selectCommerce(state), 'isFetching', false);
export const selectCommerceIsThreeDSChallenge = state => get(selectCommerce(state), 'isThreeDSChallenge', false);
export const selectCommerceTransactionToken = state => get(selectCommerce(state), 'transactionToken', null);
export const selectCommercePotentialData = state => get(selectCommerce(state), 'potentialData', null);
export const selectCommerceDidInvalidate = state => get(selectCommerce(state), 'didInvalidate', false);
export const selectCommerceError = state => get(selectCommerce(state), 'error', false);

export const selectCommerceReservationData = state => get(selectCommerceData(state), 'reservationData', {});
export const selectCommerceReservationDataId = state => get(selectCommerceReservationData(state), 'reservationId', null);
export const selectCommerceReservationDataAdults = state => get(selectCommerceReservationData(state), 'adults', 0);
export const selectCommerceReservationDataChildren = state => get(selectCommerceReservationData(state), 'children', 0);
export const selectCommerceReservationDataInfants = state => get(selectCommerceReservationData(state), 'infants', 0);
export const selectCommerceReservationDataPets = state => get(selectCommerceReservationData(state), 'pets', 0);

export const makeSelectCommercePropertyAndPricingInCart = createSelector(
  selectCommerceCart,
  (cart) => {
    const itemWithProperty = cart.filter(p => p.property && p.pricing);
    const propertyInCart = (itemWithProperty.length) ? itemWithProperty[0].property : null;
    const pricingInCart = (itemWithProperty.length) ? itemWithProperty[0].pricing : null;
    return {
      propertyInCart,
      pricingInCart
    }
  }
);

export const makeSelectCommercePropertyAndPricingInData = createSelector(
  selectCommerceData,
  (data) => {
    if(!data || !data.cart || !data.cart.length) return { propertyInData: null, pricingInData: null };
    const cart = data.cart;
    const itemWithProperty = cart.filter(p => p.property && p.pricing);
    const propertyInData = (itemWithProperty.length) ? itemWithProperty[0].property : null;
    const pricingInData = (itemWithProperty.length) ? itemWithProperty[0].pricing : null;
    return {
      propertyInData,
      pricingInData
    }
  }
);

export const selectBookableTypeFromPricingInCart = (pricingInCart) => get(pricingInCart, 'data.bookableType', null);

export const selectCommerceIsValidCartFromCart = (cart = []) => {
  if(cart.length === 0) return false;
  const doesPropertiesExist = cart.filter(p => p.property && p.valid);
  return (doesPropertiesExist.length > 0);
};

export const selectPropertyFromCart = (cart = []) => {
  if(cart.length === 0) return null;
  const properties = cart.filter(p => p.property);
  return (properties.length) ? properties[0].property : null
};

export const commercePricingSelectorEmulator = (pricingSelector) => {
  return createSelector(
    makeSelectCommercePropertyAndPricingInCart,
    selectGlobal,
    ({pricingInCart}, global) => {
      return {
        ...pricingSelector({global, pricing: pricingInCart})
      }
    }
  );
};

export const commerceReservationDataPricingSelectorEmulator = (pricingSelector) => {
  return createSelector(
    makeSelectCommercePropertyAndPricingInData,
    selectGlobal,
    ({pricingInData}, global) => {
      return {
        ...pricingSelector({global, pricing: pricingInData})
      }
    }
  );
};

export const commerceDataSelectorEmulator = (commerceSelector) => {
  return createSelector(
    selectCommerceData,
    (data) => {
      return {
        ...commerceSelector({ commerce: data })
      }
    }
  );
};

export const makeSelectCheckboxes = createSelector(
  selectCheckoutShowMarketingOptInConfig,
  selectCheckoutShowTermsAndConditionsConfig,
  selectCheckoutShowPrivacyPolicyConfig,
  selectCheckoutDefaultPrivacyPolicyConfig,
  selectCheckoutDefaultTermsAndConditionsConfig,
  makeSelectCommercePropertyAndPricingInCart,
    (showMarketingOptInConfig, showTermsAndConditionsConfig, showPrivacyPolicyConfig, defaultPrivacyPolicyConfig, defaultTermsAndConditionsConfig, {
      propertyInCart,
      pricingInCart
    }) => {
      const termsAndConditions = get(pricingInCart, 'data.termsAndConditions', null);
      const termsToUse = (termsAndConditions) ? termsAndConditions : defaultTermsAndConditionsConfig;
      return {
        privacyPolicy: (showPrivacyPolicyConfig && defaultPrivacyPolicyConfig) ? defaultPrivacyPolicyConfig : null,
        termsAndConditions: (showTermsAndConditionsConfig && termsToUse) ? termsToUse : null,
        showMarketingOptInConfig
      };
    }
  );
