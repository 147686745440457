import Input from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/input';

const baseStyle = {
  ...Input.baseStyle.field,
  paddingY: "8px",
  minHeight: "80px",
  lineHeight: "short",
};

const variants = {
  outline: (props) => Input.variants.outline(props).field,
  flushed: (props) => Input.variants.flushed(props).field,
  filled: (props) => Input.variants.filled(props).field,
  unstyled: Input.variants.unstyled.field
};

const sizes = {
  sm: Input.sizes.sm.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.lg.field
};

const defaultProps = {
  size: "md",
  variant: "outline"
};

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps
}
