import { find, get, set } from 'lodash';
import { document, window } from 'browser-monads';
import { CDN_IMAGEKIT } from '@rentivo/gatsby-core/src/containers/CdnProvider/constants';
import { createSelector } from 'reselect';
import { selectGlobal } from '@rentivo/gatsby-core/src/containers/App/selectors';
import { selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { addIdToObjects } from '@rentivo/gatsby-core/src/utils/objects';
import { PRICE_PERIOD_NIGHTLY, PRICE_TYPE_RANGE } from '@rentivo/gatsby-core/src/constants/property';
import { trimLastSlash } from '@rentivo/gatsby-core/src/utils/strings';
import {
  PRICING_DATA_CONFIG,
  PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE,
  PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants';

const customMarker = {
  "offsetLeft": -12,
  "offsetTop": -24,
  "image": "http://www.pngall.com/wp-content/uploads/2017/05/Map-Marker-PNG-Picture.png",
  "width": 24,
  "height": 24
};

// App / General ------
// ------------------------------------------
export const selectSiteConfig = state => get(selectGlobal(state), 'siteConfig', {});
export const makeSelectSiteConfig = () => createSelector(selectGlobal, globalState => globalState.siteConfig);

export const selectTranslationsConfig = state => get(selectSiteConfig(state), 'translations', {});

export const selectCustomHTMLConfig = state => get(selectSiteConfig(state), 'customHTML', {});

export const selectNoIndexConfig = state => get(selectSiteConfig(state), 'noIndex', false);

export const selectSiteFromConfig = state => get(selectSiteConfig(state), 'site', {});

export const selectThemeFromConfig = state => get(selectSiteConfig(state), 'theme', {});
export const selectThemeVariablesFromConfig = state => get(selectThemeFromConfig(state), 'variables', {});
export const selectThemeVariablesAntdFromConfig = state => get(selectThemeVariablesFromConfig(state), 'antd', {});
export const selectThemeVariablesCoreFromConfig = state => get(selectThemeVariablesFromConfig(state), 'core', {});
export const selectThemeMergedVariablesFromConfig = state => {
  return { ...selectThemeVariablesAntdFromConfig(state), ...selectThemeVariablesCoreFromConfig(state) };
};

export const selectThemeConfig = state => get(selectSiteConfig(state), 'theme', {});
export const makeSelectSiteThemeIdConfig = () => createSelector(selectSiteThemeIdConfig, state => state);
export const selectDynamicPropertyPagesConfig = state => get(selectSiteConfig(state), 'dynamicPropertyPages', false);
export const selectSearchAsHomeConfig = state => get(selectSiteConfig(state), 'searchAsHome', false);
export const selectSiteNameConfig = state => get(selectSiteConfig(state), 'name', '');
export const selectSiteShortNameConfig = state => get(selectSiteConfig(state), 'shortName', selectSiteNameConfig(state));
export const selectSiteThemeIdConfig = state => get(selectThemeConfig(state), 'id', 'barcelona');

export const selectSiteBaseUrlConfig = state => trimLastSlash(get(selectSiteConfig(state), 'baseUrl', ''));

export const selectSiteWebsiteUrlConfig = state => trimLastSlash(get(selectSiteConfig(state), 'websiteUrl', ''));

// Content
export const selectSiteContentConfig = state => get(selectSiteFromConfig(state), 'content', {});
export const makeSelectSiteContentConfig = () => createSelector(selectSiteLogoConfig, state => state);

export const selectSiteIconsConfig = state => get(selectSiteFromConfig(state), 'icons', {});
export const selectSiteFavIconConfig = state => {
  const icons = selectSiteIconsConfig(state);
  return (icons['32']) ? { icon: icons['32'], sizes: '32x32' } : (icons['16']) ? { icons: icons['16'], sizes: '16x16' } : null
};

export const selectSiteContentVariablesConfig = state => get(selectSiteContentConfig(state), 'variables', {});
export const makeSelectSiteContentVariablesConfig = () => createSelector(selectSiteContentVariablesConfig, state => state);

/*
export const selectSiteContentVariablesSepConfig = state => get(selectSiteContentVariablesConfig(state), 'sep', '—');
export const makeSelectSiteContentVariablesSepConfig = () => createSelector(selectSiteLogoConfig, state => state);

export const selectSiteContentVariablesSiteTitleConfig = state => get(selectSiteContentVariablesConfig(state), 'siteTitle', selectSiteNameConfig(state));
export const makeSelectSiteContentVariablesSiteTitleConfig = () => createSelector(selectSiteLogoConfig, state => state);
*/
// ---

// Formatting
export const selectSiteFormattingConfig = state => get(selectSiteFromConfig(state), 'formatting', {});
export const selectSiteFormattingFullDateConfig = state => get(selectSiteFormattingConfig(state), 'fullDate', 'Do MMM YY');

// Sharing
export const selectSiteSharingConfig = state => get(selectSiteFromConfig(state), 'sharing', {});
export const selectSiteSharingOgImageConfig = state => get(selectSiteSharingConfig(state), 'ogImage', null);
export const selectSiteSharingTwitterImageConfig = state => get(selectSiteSharingConfig(state), 'twitterImage', null);

// Logo
export const selectSiteLogoConfig = state => get(selectSiteFromConfig(state), 'logo', {});
export const selectSiteLogoUseImageConfig = state => get(selectSiteLogoConfig(state), 'useImage', false);
export const selectSiteLogoTextConfig = state => get(selectSiteLogoConfig(state), 'text', selectSiteNameConfig(state));
export const makeSelectSiteLogoTextConfig = () => createSelector(selectSiteLogoTextConfig, state => state);
export const selectSiteLogoImageConfig = state => get(selectSiteLogoConfig(state), 'image', {});
export const selectSiteLogoImageWidthConfig = state => get(selectSiteLogoImageConfig(state), 'width', 'auto');
export const selectSiteLogoImageHeightConfig = state => get(selectSiteLogoImageConfig(state), 'height', 32);
export const selectSiteLogoImageTypesConfig = state => get(selectSiteLogoImageConfig(state), 'types', {});
export const selectSiteLogoImageTypesDefaultConfig = state => get(selectSiteLogoImageTypesConfig(state), 'default', null);
export const selectSiteLogoImageTypesDefaultMobileConfig = state => get(selectSiteLogoImageTypesConfig(state), 'defaultMobile', selectSiteLogoImageTypesDefaultConfig(state));
export const selectSiteLogoImageTypesLightConfig = state => get(selectSiteLogoImageTypesConfig(state), 'light', selectSiteLogoImageTypesDefaultConfig(state));
export const selectSiteLogoImageTypesLightMobileConfig = state => get(selectSiteLogoImageTypesConfig(state), 'lightMobile', selectSiteLogoImageTypesLightConfig(state));
export const selectSiteLogoImageTypesDarkConfig = state => get(selectSiteLogoImageTypesConfig(state), 'dark', selectSiteLogoImageTypesDefaultConfig(state));
export const selectSiteLogoImageTypesDarkMobileConfig = state => get(selectSiteLogoImageTypesConfig(state), 'darkMobile', selectSiteLogoImageTypesDarkConfig(state));

// Structured Data
export const selectSiteStructuredDataConfig = state => get(selectSiteFromConfig(state), 'structuredData', {});
export const selectSiteStructuredDataBrandConfig = state => get(selectSiteStructuredDataConfig(state), 'brand', null);
export const selectSiteStructuredDataOrganizationConfig = state => get(selectSiteStructuredDataConfig(state), 'organization', null);

// Design
export const selectSiteDesignConfig = state => get(selectSiteFromConfig(state), 'design', {});

// Cancellation Policies
export const selectSiteCancellationPoliciesConfig = state => get(selectSiteFromConfig(state), 'cancellationPolicies', {});

// Route Paths
export const selectRoutePathsConfig = state => get(selectSiteConfig(state), 'routePaths', {});

// Cookies
export const selectCookiesConfig = state => get(selectSiteFromConfig(state), 'cookies', {});
export const selectCookiesActiveConfig = state => get(selectCookiesConfig(state), 'active', false);
export const selectCookiesPolicyUrlConfig = state => get(selectCookiesConfig(state), 'policyUrl', null);
export const selectCookiesPositionConfig = state => get(selectCookiesConfig(state), 'position', 'right');

// API
export const selectApiConfig = state => get(selectSiteFromConfig(state), 'api', {});
export const selectApiLycanConfig = state => get(selectApiConfig(state), 'lycan', {});
export const selectApiLycanChannelIdConfig = state => get(selectApiLycanConfig(state), 'channelId', null);
export const selectApiLycanHostConfig = state => get(selectApiLycanConfig(state), 'host', null);
export const selectApiLycanSendCurrencyOnPriceRequestsConfig = state => get(selectApiLycanConfig(state), 'sendCurrencyOnPriceRequests', true);
export const selectGoogleApiKeyConfig = state => get(selectApiConfig(state), 'googleApiKey', null);
export const selectTrackingConfig = state => get(selectApiConfig(state), 'tracking', null);
export const selectGoogleAnalyticsConfig = state => get(selectApiConfig(state), 'googleAnalytics', null);
export const selectGoogleTagManagerConfig = state => get(selectApiConfig(state), 'googleTagManager', null);
export const selectGtagConfig = state => get(selectApiConfig(state), 'gtag', null);
export const selectFacebookConfig = state => get(selectApiConfig(state), 'facebook', null);
export const selectGoogleAnalyticsChoice = state => {
  const gtag = selectGtagConfig(state);
  const ga = selectGoogleAnalyticsConfig(state);
  return (gtag && gtag.trackingId && gtag.active) ? { type: 'gtag', options: gtag } : (ga && ga.trackingId && ga.active) ? { type: 'ga', options: ga } : null;
};
export const selectFacebookChoice = state => {
  const facebook = selectFacebookConfig(state);
  return (facebook && facebook.pixelId && facebook.active) ? { type: 'fbp', options: facebook } : null;
};
export const makeSelectGoogleApiKeyConfig = (onLoad) => createSelector(selectGoogleApiKeyConfig, state => state);
export const selectLoadGoogle = state => {
  const apiKey = selectGoogleApiKeyConfig(state);
  if(!apiKey) return (onLoad) => onLoad(false);
  return (onLoad) => {
    const waitForGoogle = async () => {
      while(!window.hasOwnProperty('google')) // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 250)); // TODO: Add clause to break out after x number of tries..
      return onLoad(true);
    };
    if (!window.loadingGoogle && !window.google) {
      window.loadingGoogle = true;
      const script = document.createElement(`script`);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      document.head.append(script);
      return (waitForGoogle)();
    } else if(window.loadingGoogle) {
      return (waitForGoogle)();
    } else {
      onLoad(true);
    }
  }
};
export const makeSelectLoadGoogle = () => createSelector(selectLoadGoogle, state => state);


export const selectCdnConfig = state => get(selectApiConfig(state), 'cdn', {});

export const selectCdnProviderConfig = state => get(selectCdnConfig(state), 'cdn', CDN_IMAGEKIT);
export const makeSelectCdnProviderConfig = () => createSelector(selectCdnProviderConfig, state => state);

export const selectCdnOptionsConfig = state => get(selectCdnConfig(state), 'options', {});
export const makeSelectCdnOptionsConfig = () => createSelector(selectCdnOptionsConfig, state => state);

export const selectCdnOptionsEndPointConfig = state => get(selectCdnOptionsConfig(state), 'urlEndpoint', null);

export const selectMapConfig = state => get(selectApiConfig(state), 'map', {});
export const selectMapProviderConfig = state => get(selectMapConfig(state), 'provider', 'MAPBOX');
export const selectMapKeyConfig = state => get(selectMapConfig(state), 'mapKey', null);
export const selectMapStyleConfig = state => get(selectMapConfig(state), 'mapStyle', 'mapbox://styles/mapbox/streets-v1');


// Currency Provider ------
// ------------------------------------------

export const selectCurrencyConfig = state => get(selectSiteConfig(state), 'currency', {});
export const selectDefaultCurrencyConfig = state => get(selectCurrencyConfig(state), 'defaultCurrency', 'USD');
export const selectSupportedCurrenciesConfig = state => get(selectCurrencyConfig(state), 'supportedCurrencies', ['USD']);
export const makeSelectSupportedCurrenciesConfig = () => createSelector(selectSupportedCurrenciesConfig, state => state);


// Language Provider ------
// ------------------------------------------

export const selectLanguageConfig = state => get(selectSiteConfig(state), 'language', {});
export const selectFullLocalesConfig = state => get(selectLanguageConfig(state), 'locales', []);
export const selectFullLocale = state => {
  const localeCode = selectLang(state);
  const fullLocales = selectFullLocalesConfig(state);
  const defaultFullLocale = 'en_US_test';
  const fullLocale = find(fullLocales, (fL) => fL.lang === localeCode);
  return (fullLocale && fullLocale.locale) ? fullLocale.locale : defaultFullLocale;
};
export const makeSelectFullLocale = () => createSelector(selectFullLocale, state => state);

// Indices ------
// ------------------------------------------
export const selectIndicesConfig = state => get(selectSiteConfig(state), 'indices', {});
export const selectPostsIndexConfig = state => get(selectIndicesConfig(state), 'posts', null);
export const selectCollectionsIndexConfig = state => get(selectIndicesConfig(state), 'collections', null);
export const selectExperiencesIndexConfig = state => get(selectIndicesConfig(state), 'experiences', null);

// Search Provider ------
// ------------------------------------------

export const selectSearchConfig = state => get(selectSiteConfig(state), 'search', {});

export const selectSearchQueryConfig = state => get(selectSearchConfig(state), 'query', {});
export const selectSearchQueryFunctionsConfig = state => get(selectSearchQueryConfig(state), 'functions', []);

export const selectElasticSearchConfig = state => get(selectSearchConfig(state), 'elasticSearch', null);

export const selectSearchCollectionsConfig = state => get(selectSearchConfig(state), 'collections', {});
export const selectSearchCollectionsRootCollectionDatabaseIdConfig = state => get(selectSearchCollectionsConfig(state), 'rootCollectionDatabaseId', undefined);

export const selectSearchResultsConfig = state => get(selectSearchConfig(state), 'results', {});

export const selectSearchResultsTotalHitsConfig = state => get(selectSearchResultsConfig(state), 'totalHits', 10000);
export const makeSelectSearchResultsTotalHitsConfig = () => createSelector(selectSearchResultsTotalHitsConfig, state => state);

export const selectSearchResultsPerPageConfig = state => get(selectSearchResultsConfig(state), 'perPage', 12);
export const makeSelectSearchResultsPerPageConfig = () => createSelector(selectSearchResultsPerPageConfig, state => state);

export const selectSearchResultsOpenInNewTabConfig = state => get(selectSearchResultsConfig(state), 'openInNewTab', false);
export const selectSearchResultsIncludeDiscountsConfig = state => get(selectSearchResultsConfig(state), 'includeDiscounts', false);

export const selectSearchMapConfig = state => get(selectSearchConfig(state), 'map', {});

export const selectSearchMapExperiencesConfig = state => get(selectSearchMapConfig(state), 'experiences', {});
export const selectSearchMapExperiencesIncludeConfig = state => get(selectSearchMapExperiencesConfig(state), 'include', false);
export const selectSearchMapExperiencesShowByDefaultConfig = state => get(selectSearchMapExperiencesConfig(state), 'showByDefault', false);
export const selectSearchMapExperiencesUseCustomMarkerConfig = state => get(selectSearchMapExperiencesConfig(state), 'useCustomMarker', false);
export const selectSearchMapExperiencesCustomMarkerConfig = state => get(selectSearchMapExperiencesConfig(state), 'customMarker', customMarker);

export const selectSearchMapProviderConfig = state => get(selectSearchMapConfig(state), 'provider', 'MAPBOX');
export const makeSelectSearchMapProviderConfig = () => createSelector(selectSearchMapProviderConfig, state => state);

export const selectSearchMapKeyConfig = state => get(selectSearchMapConfig(state), 'mapKey', null);
export const makeSelectSearchMapKeyConfig = () => createSelector(selectSearchMapKeyConfig, state => state);

export const selectSearchMapStyleConfig = state => get(selectSearchMapConfig(state), 'mapStyle', 'mapbox://styles/mapbox/streets-v11');
export const makeSelectSearchMapStyleConfig = () => createSelector(selectSearchMapStyleConfig, state => state);

export const selectSearchMapDefaultZoomConfig = state => get(selectSearchMapConfig(state), 'defaultZoom', 14);
export const makeSelectSearchMapDefaultZoomConfig = () => createSelector(selectSearchMapDefaultZoomConfig, state => state);

export const selectSearchMapMaxZoomConfig = state => get(selectSearchMapConfig(state), 'maxZoom', 20);
export const makeSelectSearchMapMaxZoomConfig = () => createSelector(selectSearchMapMaxZoomConfig, state => state);

export const selectSearchMapMinZoomConfig = state => get(selectSearchMapConfig(state), 'minZoom', 1);
export const makeSelectSearchMapMinZoomConfig = () => createSelector(selectSearchMapMinZoomConfig, state => state);

export const selectSearchMapUseFuzzyRadiusConfig = state => get(selectSearchMapConfig(state), 'useFuzzyRadius', false);
export const makeSelectSearchMapUseFuzzyRadiusConfig = () => createSelector(selectSearchMapUseFuzzyRadiusConfig, state => state);

export const selectSearchMapFuzzyRadiusConfig = state => get(selectSearchMapConfig(state), 'fuzzyRadius', 200);
export const makeSelectSearchMapFuzzyRadiusConfig = () => createSelector(selectSearchMapFuzzyRadiusConfig, state => state);

export const selectSearchMapOnMoveDefaultConfig = state => get(selectSearchMapConfig(state), 'searchMapOnMoveDefault', true);
export const makeSelectSearchMapOnMoveDefaultConfig = () => createSelector(selectSearchMapOnMoveDefaultConfig, state => state);

export const selectSearchMapIsOpenOnLoadConfig = state => get(selectSearchMapConfig(state), 'isOpenOnLoad', false);
export const selectSearchMapPlaceNamesConfig = state => get(selectSearchMapConfig(state), 'placeNames', {});
export const selectSearchMapPlaceNamesUsePlaceNameConfig = state => get(selectSearchMapPlaceNamesConfig(state), 'usePlaceNames', true);

export const selectSearchMapDefaultCenterConfig = state => get(selectSearchMapConfig(state), 'defaultCenter', {
  "lat": 50.26604709999999,
  "lng": -5.052712499999984
});
export const makeSelectSearchMapDefaultCenterConfig = () => createSelector(selectSearchMapDefaultCenterConfig, state => state);

export const selectSearchFiltersConfig = state => {
  let filters = get(selectSearchConfig(state), 'filters', null);
  if(filters) filters = addIdToObjects(filters);
  return filters;
};

export const selectSearchFilterConfigByKey = (filtersConfig, key) => get(filtersConfig, key, null);

export const makeSelectSearchFiltersConfig = (filterConfigKey = null) =>
  createSelector(selectSearchFiltersConfig, state => {
    if(!filterConfigKey) return state;
    return get(state, filterConfigKey, null);
  });

export const selectSearchBedroomsFilterConfig = state => get(selectSearchFiltersConfig(state), 'bedrooms', null);


// Property Provider ------
// ------------------------------------------

export const selectPropertyConfig = state => get(selectSiteConfig(state), 'property', {});

export const selectFeefoSkuPathPriceConfig = state => get(selectPropertyConfig(state), 'feefoSkuPath', {});

export const selectPropertyPriceConfig = state => get(selectPropertyConfig(state), 'price', {});

export const selectPropertyPriceTypeConfig = state => get(selectPropertyPriceConfig(state), 'type', PRICE_TYPE_RANGE);

export const selectPropertyPricePeriodConfig = state => get(selectPropertyPriceConfig(state), 'period', PRICE_PERIOD_NIGHTLY);

export const selectPropertyHeadlineConfig = state => get(selectPropertyConfig(state), 'headline', {});
export const selectPropertyHeadlineShowSubtitleConfig = state => get(selectPropertyHeadlineConfig(state), 'showSubtitle', true);

export const selectPropertyTagsConfig = state => get(selectPropertyConfig(state), 'tags', {});
export const selectPropertyTagsShowMaxTagsConfig = state => get(selectPropertyTagsConfig(state), 'showMaxTags', 1);
export const selectPropertyTagsAcceptsConfig = state => get(selectPropertyTagsConfig(state), 'accepts', []);

export const selectPropertyMapConfig = state => get(selectPropertyConfig(state), 'map', {});
export const selectPropertyMapExperiencesConfig = state => get(selectSearchMapConfig(state), 'experiences', {});
export const selectPropertyMapExperiencesIncludeConfig = state => get(selectPropertyMapExperiencesConfig(state), 'include', false);
export const selectPropertyMapExperiencesShowByDefaultConfig = state => get(selectPropertyMapExperiencesConfig(state), 'showByDefault', false);
export const selectPropertyMapMinZoomConfig = state => get(selectPropertyMapConfig(state), 'minZoom', 1);
export const selectPropertyMapMaxZoomConfig = state => get(selectPropertyMapConfig(state), 'maxZoom', 20);
export const selectPropertyMapDefaultZoomConfig = state => get(selectPropertyMapConfig(state), 'defaultZoom', 14);
export const selectPropertyMapUseFuzzyRadiusConfig = state => get(selectPropertyMapConfig(state), 'useFuzzyRadius', false);
export const selectPropertyMapFuzzyRadiusConfig = state => get(selectPropertyMapConfig(state), 'fuzzyRadius', 200);
export const selectPropertyMapUseCustomMarkerConfig = state => get(selectPropertyMapConfig(state), 'useCustomMarker', false);
export const selectPropertyMapCustomMarkerConfig = state => get(selectPropertyMapConfig(state), 'customMarker', customMarker);

// Property Pricing Provider ------
// ------------------------------------------

export const selectPropertyPricingConfig = state => get(selectPropertyConfig(state), 'pricing', {});

export const selectPropertyPricingAlwaysShowBeforeArrivalFees = state => get(selectPropertyPricingConfig(state), 'alwaysShowBeforeArrivalFees', false);
export const selectPropertyPricingAlwaysShowAfterArrivalFees = state => get(selectPropertyPricingConfig(state), 'alwaysShowAfterArrivalFees', false);


export const selectPropertyPricingMinNightsConfig = state => get(selectPropertyPricingConfig(state), 'minNights', 1);
export const selectPropertyPricingMaxNightsConfig = state => get(selectPropertyPricingConfig(state), 'maxNights', 62);
export const selectPropertyPricingDataConfig = state => get(selectPropertyPricingConfig(state), 'data', PRICING_DATA_CONFIG);

export const selectPropertyPricingActionsConfig = state => get(selectPropertyPricingConfig(state), 'actions', {});
//export const selectPropertyPricingActionsEnquiryDisplayModeConfig = state => get(selectPropertyPricingActionsConfig(state), 'enquiryDisplayMode', PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE);
//export const selectPropertyPricingActionsRequestToBookDisplayModeConfig = state => get(selectPropertyPricingActionsConfig(state), 'requestToBookDisplayMode', PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE);

export const selectPropertyPricingActionsEnquiryDisplayModeConfig = (state) => get(selectPropertyPricingActionsConfig(state), 'enquiryDisplayMode', PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE);
export const selectPropertyPricingActionsRequestToBookDisplayModeConfig = (state) => get(selectPropertyPricingActionsConfig(state), 'requestToBookDisplayMode', PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE);

// Countries Config ------
// ------------------------------------------

export const selectSiteCountriesConfig = state => get(selectSiteFromConfig(state), 'countries', {});
export const selectSiteCountriesImportantConfig = state => get(selectSiteCountriesConfig(state), 'important', []);
export const selectSiteCountriesIncludeConfig = state => get(selectSiteCountriesConfig(state), 'include', []);
export const selectSiteCountriesExcludeConfig = state => get(selectSiteCountriesConfig(state), 'exclude', []);

// Checkout Config ------
// ------------------------------------------
export const selectCheckoutConfig = state => get(selectSiteConfig(state), 'checkout', {});
export const selectCheckoutPaymentConfig = state => get(selectCheckoutConfig(state), 'payment', {});
export const selectCheckoutInfoConfig = state => get(selectCheckoutConfig(state), 'info', {});
export const selectCheckoutSuccessConfig = state => get(selectCheckoutConfig(state), 'success', {});
export const selectCheckoutSuccessReservationIdPathConfig = state => get(selectCheckoutSuccessConfig(state), 'reservationIdPath', "reservationId");
export const selectCheckoutInfoPaymentRequiredConfig = state => get(selectCheckoutInfoConfig(state), 'phoneRequired', true);
export const selectCheckoutPaymentDefaultAcceptedPaymentBrandsConfig = state => get(selectCheckoutPaymentConfig(state), 'defaultAcceptedPaymentBrands', ['visa', 'mastercard']);
export const selectCheckoutPaymentShowGatewaysConfig = state => get(selectCheckoutPaymentConfig(state), 'showGateways', false);
export const selectCheckoutMessageToAgentConfig = state => get(selectCheckoutConfig(state), 'messageToAgent', {});
export const selectCheckoutMessageToAgentActiveConfig = state => get(selectCheckoutMessageToAgentConfig(state), 'active', true);
export const selectCheckoutMessageToAgentRequiredConfig = state => get(selectCheckoutMessageToAgentConfig(state), 'required', false);
export const selectCheckoutEnquiryModalConfig = state => get(selectCheckoutConfig(state), 'enquiryModal', {});
export const selectCheckoutEnquiryModalActiveConfig = state => get(selectCheckoutConfig(state), 'active', true);
export const selectCheckoutCheckboxesConfig = state => get(selectCheckoutConfig(state), 'checkboxes', {});
export const selectCheckoutShowMarketingOptInConfig = state => get(selectCheckoutCheckboxesConfig(state), 'showMarketingOptIn', false);
export const selectCheckoutShowTermsAndConditionsConfig = state => get(selectCheckoutCheckboxesConfig(state), 'showTermsAndConditions', true);
export const selectCheckoutShowPrivacyPolicyConfig = state => get(selectCheckoutCheckboxesConfig(state), 'showPrivacyPolicy', false);
export const selectCheckoutDefaultPrivacyPolicyConfig = state => get(selectCheckoutCheckboxesConfig(state), 'defaultPrivacyPolicy', null);
export const selectCheckoutDefaultTermsAndConditionsConfig = state => get(selectCheckoutCheckboxesConfig(state), 'defaultTermsAndConditions', null);

// Blog Config ------
// ------------------------------------------
export const selectBlogConfig = state => get(selectSiteConfig(state), 'blog', {});
export const selectBlogPerPageConfig = state => get(selectBlogConfig(state), 'perPage', 10);
