import { get, toUpper, round, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';
import {
  calculateChargeAmountDue,
  calculateMinimumPayableAmount,
  extractSpreedlyPairing,
  getCanRequestToBook,
  getCanSendEnquiry,
  getHasPrice,
  getPricingRoles,
  retrieveChargeCurrency,
  retrieveChargeCurrencyExchangeRate
} from '@rentivo/gatsby-core/src/containers/PricingProvider/utils';
import {
  BOOKABLE_TYPE_ENQUIRY_ONLY,
  BOOKABLE_TYPE_ENQUIRY_WITH_PRICE,
  BOOKABLE_TYPE_INSTANT_BOOKABLE,
  BOOKABLE_TYPE_REQUEST_TO_BOOK,
  ORDER_ITEM_TYPE_DAMAGE_DEPOSIT,
  ORDER_ITEM_TYPE_FEE,
  ORDER_ITEM_TYPE_RENTAL,
  ORDER_ITEM_TYPE_TAX,
  PAYMENT_ITEM_DISCR_PAYMENT,
  PAYMENT_ITEM_STATUS_DUE,
  PAYMENT_ITEM_TYPE_CARD,
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK,
  ORDER_ITEM_TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE, ORDER_ITEM_TYPE_DISCOUNT
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import {
  selectPropertyPricingActionsEnquiryDisplayModeConfig,
  selectPropertyPricingActionsRequestToBookDisplayModeConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { PRODUCT_TYPE_PROPERTY } from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import dayjs from 'dayjs';

export const selectPricing = state => get(state, 'pricing', {});
export const selectPricingLastUpdated = state => get(state, 'lastUpdated', null);
export const selectPricingPropertyId = state => get(selectPricing(state), 'propertyId', null);
export const selectPricingPropertyName = state => get(selectPricing(state), 'propertyName', null);
export const selectPricingProperty = state => get(selectPricing(state), 'property', null);
export const selectPricingStartDate = state => get(selectPricing(state), 'startDate', null);
export const selectPricingStartDateFromPricing = pricing => get(pricing, 'startDate', null);
export const selectPricingEndDate = state => get(selectPricing(state), 'endDate', null);
export const selectPricingEndDateFromPricing = pricing => get(pricing, 'endDate', null);
export const selectPricingDatesPopoverOpen = state => get(selectPricing(state), 'datesPopoverOpen', false);
export const selectPricingGuestsPopoverOpen = state => get(selectPricing(state), 'guestsPopoverOpen', false);

export const selectPricingGuestsObject = state => {
  const pricing = selectPricing(state);
  return {
    guests:   get(pricing, 'guests', 0),
    adults:   get(pricing, 'adults', 0),
    children: get(pricing, 'children', 0),
    infants:  get(pricing, 'infants', 0),
    pets:     get(pricing, 'pets', 0)
  }
};

// Make pets 0, if not pet friendly...
// TODO: Is this necessary, we can just fix this at the setGuests level?
export const selectPricingGuestsObjectWithPetsConfig = (guestsObject, isPetFriendly) => {
  if(!isPetFriendly) guestsObject.pets = 0;
  return guestsObject;
};

export const selectPricingData = state => get(selectPricing(state), 'data', null);
export const selectPricingIsFetching = state => get(selectPricing(state), 'isFetching', false);
export const selectPricingDidInvalidate = state => get(selectPricing(state), 'didInvalidate', false);
export const selectPricingError = state => get(selectPricing(state), 'error', false);

export const selectPricingDataIsPriced = state => get(selectPricingData(state), 'isPriced', false);
export const selectPricingDataIsAvailable = state => get(selectPricingData(state), 'isAvailable', false);
export const selectPricingDataDamageDeposit = state => get(selectPricingData(state), 'damageDeposit', 0);
export const selectPricingDataBookableType = state => get(selectPricingData(state), 'bookableType', BOOKABLE_TYPE_ENQUIRY_WITH_PRICE);
export const selectPricingDataBookableTypeIsInstantBook = state => (selectPricingDataBookableType(state) === BOOKABLE_TYPE_INSTANT_BOOKABLE);
export const selectPricingDataBookableTypeIsRequestToBook = state => (selectPricingDataBookableType(state) === BOOKABLE_TYPE_REQUEST_TO_BOOK);
export const selectPricingDataBookableTypeIsEnquiryOnly = state => (selectPricingDataBookableType(state) === BOOKABLE_TYPE_ENQUIRY_ONLY);
export const selectPricingDataBookableTypeIsEnquiryWithPrice = state => (selectPricingDataBookableType(state) === BOOKABLE_TYPE_ENQUIRY_WITH_PRICE);
export const selectPricingDataReservationType = state => (selectPricingDataBookableType(state) === BOOKABLE_TYPE_INSTANT_BOOKABLE) ? RESERVATION_TYPE_INSTANT_BOOKING : RESERVATION_TYPE_REQUEST_TO_BOOK;

export const selectPricingDataBasePrice = state => get(selectPricingData(state), 'basePrice', 0);
export const selectPricingDataTotal = state => get(selectPricingData(state), 'total', 0);
export const selectPricingDataTotalWithoutDamageDeposit = state => get(selectPricingData(state), 'totalWithoutDamageDeposit', 0);
export const selectPricingDataBasePriceTaxable = state => get(selectPricingData(state), 'basePriceTaxable', 0);
export const selectPricingDataCurrency = state => get(selectPricingData(state), 'currency', 'USD');
export const selectPricingDataErrors = state => get(selectPricingData(state), 'errors', []);
export const selectPricingDataTermsAndConditions = state => get(selectPricingData(state), 'termsAndConditions', null);
export const selectPricingDataPayableCurrencies = state => get(selectPricingData(state), 'payableCurrencies', null);
export const selectPricingDataDamageDepositSplitMethod = state => get(selectPricingData(state), 'damageDepositSplitMethod', null);
export const selectPricingDataPairings = state => get(selectPricingData(state), 'pairings', []);
export const selectPricingDataMainPairing = state => get(selectPricingDataPairings(state), '0', null);
export const selectPricingDataSpreedlyPairing = state => extractSpreedlyPairing(selectPricingDataPairings(state));
export const makeSelectPricingDataSpreedlyPairing = (state, pairingIndex) => extractSpreedlyPairing(selectPricingDataPairings(state), pairingIndex);

//((spreedlyPairing !== null  && spreedlyPairing.gateway !== undefined)) ? spreedlyPairing['gateway']['environment'] : null
export const makeSelectPricingDataSpreedlyEnvironment = createSelector(
  selectPricingDataSpreedlyPairing,
  (spreedlyPairing) =>  {
    if(spreedlyPairing !== null && (spreedlyPairing.type === 'gatewaychannelpairing' || spreedlyPairing.type === 'gatewayuserpairing') && spreedlyPairing.gateway !== undefined) {
      return spreedlyPairing['gateway']['environment'];
    } else if(spreedlyPairing !== null && spreedlyPairing.type === 'receiverpairing' && spreedlyPairing.receiver !== undefined) {
      return spreedlyPairing['receiver']['environment'];
    } else {
      return null;
    }
  }
);

export const makeSelectPricingDataPairingDetails = createSelector(
  selectPricingDataMainPairing,
  selectPricingDataPairings,
  (pairing, pairings) =>  {
    const supportedCards = uniq(Object.values(get(pairing, 'supportedCards', {})));
    const merchantName = get(pairing, 'merchantName', null);
    return {
      supportedCards,
      merchantName,
      pairings
    }
  }
);

export const selectPricingDataMessages = state => get(selectPricingData(state), 'messages', []);
export const selectPricingDataAdjustments = state => get(selectPricingData(state), 'adjustments', []);

export const selectPricingDataSplitDetails= state => get(selectPricingData(state), 'splitDetails', {});
export const selectPricingDataOrigin = state => get(selectPricingData(state), 'origin', {});
export const selectPricingDataStayBreakdown = state => get(selectPricingData(state), 'stayBreakdown', {});

export const selectPricingDataStayBreakdownArrivalDate = state => get(selectPricingDataStayBreakdown(state), 'arrivalDate', null);
export const selectPricingDataStayBreakdownDepartureDate = state => get(selectPricingDataStayBreakdown(state), 'departureDate', null);
export const selectPricingDataStayBreakdownBookingDate = state => get(selectPricingDataStayBreakdown(state), 'bookingDate', null);
export const selectPricingDataStayBreakdownNoNights = state => get(selectPricingDataStayBreakdown(state), 'noNights', 0);
export const selectPricingDataStayBreakdownNoNightsFromPricing = pricing => get(pricing, 'data.stayBreakdown.noNights', 0);
export const selectPricingDataStayBreakdownGuests = state => get(selectPricingDataStayBreakdown(state), 'guests', 0);
export const selectPricingDataStayBreakdownAdults = state => get(selectPricingDataStayBreakdown(state), 'adults', 0);
export const selectPricingDataStayBreakdownChildren = state => get(selectPricingDataStayBreakdown(state), 'children', 0);
export const selectPricingDataStayBreakdownInfants = state => get(selectPricingDataStayBreakdown(state), 'infants', 0);
export const selectPricingDataStayBreakdownPets = state => get(selectPricingDataStayBreakdown(state), 'pets', 0);
export const selectPricingDataStayBreakdownDaysBeforeArrival = state => get(selectPricingDataStayBreakdown(state), 'daysBeforeArrival', 0);
export const selectPricingDataStayBreakdownExtras = state => get(selectPricingDataStayBreakdown(state), 'extras', []);
export const selectPricingDataStayBreakdownNights = state => get(selectPricingDataStayBreakdown(state), 'nights', {});

export const makeSelectPricingDueNow = createDeepEqualSelector(
  selectPricingDataSplitDetails,
  selectPricingDataTotal,
  (splitDetails, total) => calculateMinimumPayableAmount(splitDetails, total)
);

export const makeSelectPricingDataDisplay = createDeepEqualSelector(
  selectPricingDataIsAvailable,
  selectPricingDataIsPriced,
  selectPricingDataSplitDetails,
  selectPricingDataSpreedlyPairing,
  selectPricingDataPayableCurrencies,
  selectPricingDataBasePrice,
  selectPricingDataTotal,
  selectPricingDataTotalWithoutDamageDeposit,
  selectPricingDataBasePriceTaxable,
  selectPricingDataAdjustments,
  selectPricingDataCurrency,
  selectPricingDataStayBreakdownNoNights,
  selectPricingDataBookableType,
  selectPricingDataMessages,
  (isAvailable, isPriced, splitDetails, spreedlyPairing, payableCurrencies, basePrice, total, totalWithoutDamageDeposit, basePriceTaxable, adjustments, currency, noNights, bookableType, customMessages) => {
    const beforeArrivalAdjustments = adjustments.filter(a => a.priceGroup !== 'on_arrival' && !a.hidden);
    const afterArrivalAdjustments = adjustments.filter(a => a.priceGroup === 'on_arrival' && !a.hidden);
    // TODO: These might not work... need to test on a real property..
    const chargeCurrency = retrieveChargeCurrency(currency, splitDetails, spreedlyPairing);
    const chargeAmountDue = calculateChargeAmountDue(chargeCurrency, payableCurrencies);
    const chargeAmountExchangeRate = retrieveChargeCurrencyExchangeRate(chargeCurrency, payableCurrencies);
    const isInstantBook = (bookableType === BOOKABLE_TYPE_INSTANT_BOOKABLE);
    //const isInstantBook = (bookableType === BOOKABLE_TYPE_INSTANT_BOOKABLE && spreedlyPairing);
    //const isRequestToBook = (bookableType === BOOKABLE_TYPE_REQUEST_TO_BOOK || (bookableType === BOOKABLE_TYPE_INSTANT_BOOKABLE && !spreedlyPairing));
    const isRequestToBook = (bookableType === BOOKABLE_TYPE_REQUEST_TO_BOOK);
    
    return {
      isAvailable,
      isPriced,
      basePrice,
      total,
      totalWithoutDamageDeposit,
      basePriceTaxable,
      currency,
      dueNow: calculateMinimumPayableAmount(splitDetails, total),
      noNights,
      perNightPrice: parseFloat((basePrice / noNights).toFixed(2)),
      chargeCurrency,
      chargeAmountDue,
      chargeAmountExchangeRate,
      beforeArrivalAdjustments,
      afterArrivalAdjustments,
      adjustments,
      isInstantBook,
      isRequestToBook,
      customMessages,
      bookableType
    };
  }
);

export const makeSelectPricingHasPrice = createDeepEqualSelector(
  selectPricingDataTotal,
  selectPricingDataErrors,
  (total, errors) => getHasPrice(total, errors)
);

export const makeSelectPricingRoles = createDeepEqualSelector(
  selectPropertyPricingActionsEnquiryDisplayModeConfig,
  selectPropertyPricingActionsRequestToBookDisplayModeConfig,
  selectPricingDataIsAvailable,
  selectPricingDataIsPriced,
  selectPricingDataBookableType,
  makeSelectPricingHasPrice,
  (enquiryDisplayMode, requestToBookDisplayMode, isAvailable, isPriced, bookableType, hasPrice) => {
    return {
      ...getPricingRoles({enquiryDisplayMode, requestToBookDisplayMode, isAvailable, bookableType, hasPrice, isPriced})
    }
  }
);

export const selectPricingOrderItemsFromData = (pricingDisplayData, hasPrice) => {
  if(!hasPrice) return null;
  const { total, chargeCurrency, chargeAmountExchangeRate, adjustments } = pricingDisplayData;
  const orderItems = [];
  let lycanExtras = 0;

  for (let i = 0; i < adjustments.length; i++) {
    let adj = adjustments[i];

    if (toUpper(adj.calculationOperand) !== 'ADDITION') continue;
    if (toUpper(adj.priceGroup) === 'ON_ARRIVAL') continue;
    let type = ORDER_ITEM_TYPE_FEE;

    if (toUpper(adj.identifier) === 'DAMAGE_DEPOSIT') {
      type = ORDER_ITEM_TYPE_DAMAGE_DEPOSIT;
    } else if (toUpper(adj.type) === 'TAX' || toUpper(adj.identifier) === 'TAX') {
      type = ORDER_ITEM_TYPE_TAX;
    }

    if (toUpper(adj.identifier) === ORDER_ITEM_TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE) {
      type = ORDER_ITEM_TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE
    }

    // Hack to map VRBOIPM to Lycan ENUM -- CF. Line 53, RatesHydrator.php
    if(toUpper(adj.productCode) === 'DISCOUNT') {
      type = ORDER_ITEM_TYPE_DISCOUNT;
    }

    orderItems.push({
      type: type,
      currency: chargeCurrency,
      preTaxAmount: round(adj.amount * chargeAmountExchangeRate, 2),
      externalId: adj.externalId ? adj.externalId : adj.identifier,
      productName: adj.description
    });

    lycanExtras += adj.amount;
  }

  orderItems.push({
    type: ORDER_ITEM_TYPE_RENTAL,
    currency: chargeCurrency,
    preTaxAmount: round((total - lycanExtras) * chargeAmountExchangeRate, 2),
  });

  return orderItems;
};

export const makeSelectPricingOrderItems = createDeepEqualSelector(
  makeSelectPricingHasPrice,
  makeSelectPricingDataDisplay,
  (hasPrice, pricingDisplayData) => selectPricingOrderItemsFromData(pricingDisplayData, hasPrice)
);

export const selectPricingPaymentItemsFromData = (pricingDisplayData, hasPrice, reservationType, splitDetails) => {
  const payments = [];

  if(!hasPrice) return null;
  const { chargeCurrency, chargeAmountDue } = pricingDisplayData;

  if (chargeAmountDue === 0) return [];

  const payment = {
    discr: PAYMENT_ITEM_DISCR_PAYMENT,
    type: PAYMENT_ITEM_TYPE_CARD,
    status: PAYMENT_ITEM_STATUS_DUE,
    amount: chargeAmountDue, // TODO: Potential currency bug (round)
    currency: chargeCurrency,
    surcharge: 0,
    cleared: 0,
    dueDate: dayjs().format('YYYY-MM-DD')
  };
  if (reservationType === RESERVATION_TYPE_REQUEST_TO_BOOK) {
    delete payment['discr'];
  }

  payments.push(payment);

  if(splitDetails && splitDetails.balance && splitDetails.balance.dueDate && splitDetails.balance.amount > 0 && splitDetails.balance.dueNow === false) {
    payments.push({
      discr: PAYMENT_ITEM_DISCR_PAYMENT,
      type: PAYMENT_ITEM_TYPE_CARD,
      status: PAYMENT_ITEM_STATUS_DUE,
      amount: splitDetails.balance.amount,
      currency: chargeCurrency,
      surcharge: 0,
      cleared: 0,
      dueDate: splitDetails.balance.dueDate
    });
  }

  return payments;
};

export const makeSelectPricingPaymentItems = createDeepEqualSelector(
  makeSelectPricingHasPrice,
  makeSelectPricingDataDisplay,
  selectPricingDataSplitDetails,
  (hasPrice, pricingDisplayData, splitDetails) => {
    const reservationType = (pricingDisplayData.bookableType === BOOKABLE_TYPE_INSTANT_BOOKABLE) ? RESERVATION_TYPE_INSTANT_BOOKING : RESERVATION_TYPE_REQUEST_TO_BOOK;
    return selectPricingPaymentItemsFromData(pricingDisplayData, hasPrice, reservationType, splitDetails)
  }
);

export const makeSelectPricingAsProduct = createDeepEqualSelector(
  selectPricing,
  selectPricingPropertyId,
  selectPricingPropertyName,
  selectPricingProperty,
  makeSelectPricingDataDisplay,
  makeSelectPricingRoles,
  (pricing, propertyId, propertyName, property, pricingDisplay, pricingRoles) => {
    const { canRequestToBook, isInstantBook } = pricingRoles;
    const { guests, adults, children, infants, pets, startDate, endDate, data } = pricing;
    const { total, dueNow, chargeCurrency, chargeAmountDue, chargeAmountExchangeRate, isAvailable, bookableType } = pricingDisplay;
    let price = (dueNow) ? dueNow : total;
    price = (canRequestToBook && !isInstantBook) ? 0 : price; // Make the price 0 if it's a RTB...
    return {
      id: propertyId,
      name: propertyName,
      type: PRODUCT_TYPE_PROPERTY,
      quantity: 1,
      price,
      fullPrice: total,
      chargeCurrency: chargeCurrency,
      chargePrice: (chargeAmountDue > 0) ? chargeAmountDue : price,
      chargeExchangeRate: chargeAmountExchangeRate,
      property,
      pricing: { guests, adults, children, infants, pets, startDate, endDate, data },
      valid: isAvailable
    };
  }
);
