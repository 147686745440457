export const SORT_NONE = 'SORT_NONE';
export const SORT_RELEVANCE_ASC = 'RELEVANCE_ASC';
export const SORT_NIGHTLY_PRICE_ASC = 'NIGHTLY_PRICE_ASC';
export const SORT_NIGHTLY_PRICE_DESC = 'NIGHTLY_PRICE_DESC';
export const SORT_SLEEPS_ASC = 'SLEEPS_ASC';
export const SORT_SLEEPS_DESC = 'SLEEPS_DESC';
export const SORT_CREATED_AT_ASC = 'CREATED_AT_ASC';
export const SORT_CREATED_AT_DESC = 'CREATED_AT_DESC';

export const sortMap = {
  [SORT_RELEVANCE_ASC]: {
    dataField: null,
    order: 'asc'
  },
  [SORT_NIGHTLY_PRICE_ASC]: {
    dataField: 'pricing.visual.nightlyLow',
    order: 'asc'
  },
  [SORT_NIGHTLY_PRICE_DESC]: {
    dataField: 'pricing.visual.nightlyLow',
    order: 'desc'
  },
  [SORT_SLEEPS_ASC]: {
    dataField: 'listing.sleeps',
    order: 'asc'
  },
  [SORT_SLEEPS_DESC]: {
    dataField: 'listing.sleeps',
    order: 'desc'
  },
  [SORT_CREATED_AT_ASC]: {
    dataField: '$createdAt',
    order: 'asc'
  },
  [SORT_CREATED_AT_DESC]: {
    dataField: '$createdAt',
    order: 'desc'
  }
};

export const DEFAULT_OPTIONS = [
  {
    "urlValue": "relevance",
    "value": SORT_RELEVANCE_ASC
  },
  {
    "urlValue": "nightly_price_asc",
    "value": SORT_NIGHTLY_PRICE_ASC
  },
  {
    "urlValue": "nightly_price_desc",
    "value": SORT_NIGHTLY_PRICE_DESC
  },
  {
    "urlValue": "sleeps_asc",
    "value": SORT_SLEEPS_ASC
  },
  {
    "urlValue": "sleeps_desc",
    "value": SORT_SLEEPS_DESC
  }
];
