import { defineMessages } from 'react-intl';

export const scope = 'components.generic.CookiePopup.cookies';

export default defineMessages({
  analyticsTitle: {
    id: `${scope}.analytics.title`,
    defaultMessage: 'Analytics',
  },
  analyticsDescription: {
    id: `${scope}.analytics.description`,
    defaultMessage: 'We use track browser, device and usage data to help us analyze your use of our sites and diagnose technical issues.',
  },
  marketingTitle: {
    id: `${scope}.marketing.title`,
    defaultMessage: 'Marketing',
  },
  marketingDescription: {
    id: `${scope}.marketing.description`,
    defaultMessage: 'When you visit our website, we may use Personal Data collected from you and your device to target advertisements for our services, where allowed by applicable law.',
  }
});
