export default {
  parts: ["item", "list"],
  baseStyle: {
    item: {
      fontWeight: "semibold",
      color: "text"
    },
    list: {
      boxShadow: "lg"
    }
  }
}
