import React from 'react';
import { Match } from '@reach/router';

export const RouterContext = React.createContext({});

export const withRouter = Component => (props) => (
  <RouterContext.Consumer>
    {router => <Component {...props} {...router} />}
  </RouterContext.Consumer>
);

export const RouterProvider = ({ path, location, children }) => {
  return (
    <Match path={path}>
      {matchProps =>
        <RouterContext.Provider value={{ location, match: matchProps.match }}>
          {children}
        </RouterContext.Provider>
      }
    </Match>
  );
};
