export default {
  baseStyle: {
    color: 'link',
    '_hover': {
      textDecoration: 'none',
      color: 'linkHover'
    },
    '_focus': {
      boxShadow: 'none'
    }
  }
}
