import React from 'react';

export const StoreContext = React.createContext({});

export const withStore = Component => (props) => (
  <StoreContext.Consumer>
    {data => <Component {...props} store={data.store} persistor={data.persistor} />}
  </StoreContext.Consumer>
);

export const StoreProvider = ({ store, persistor, children }) => {
  return (
    <StoreContext.Provider value={{store, persistor}}>
      {children}
    </StoreContext.Provider>
  );
};
