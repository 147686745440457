import React from 'react';
import { IntlProvider } from 'react-intl';
import { createSelector } from 'reselect';
import { makeSelectSiteConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { useSelector } from 'react-redux';

const mapStateToProps = createSelector(
  makeSelectSiteConfig(),
  (siteConfig) => ({
    siteConfig
  })
);

const LanguageProvider = ({children, locale}) => {

  const { siteConfig: { language, translations } } = useSelector(mapStateToProps);
  const { defaultLang } = language;

  return (
    <IntlProvider
      defaultLocale={defaultLang}
      locale={locale}
      key={locale}
      messages={translations[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
