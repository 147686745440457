export const PRIMARY_DESCRIPTION = 'PRIMARY_DESCRIPTION'
export const WHAT_IS_INCLUDED = 'WHAT_IS_INCLUDED'
export const WHAT_IS_NOT_INCLUDED = 'WHAT_IS_NOT_INCLUDED'
export const FAQ = 'FAQ'
export const BOOKING_TERMS = 'BOOKING_TERMS'
export const PHOTO = 'PHOTO'
export const FLOORPLAN = 'FLOORPLAN'
export const VIDEO = 'VIDEO'
export const AMENITIES = 'Amenities'
export const SUITABILITY = 'Suitability'
export const BEDROOM = 'BEDROOM'
export const BATHROOM = 'BATHROOM'
export const LISTING_TYPE_UNSPECIFIED = 'LISTING_TYPE_UNSPECIFIED'
export const GENERAL_PET_FRIENDLY = 'GENERAL_PET_FRIENDLY'
export const SUITABILITY_PETS_ALLOWED = 'SUITABILITY_PETS_ALLOWED'

export const AVAILABILITY_CONFIG_DEFAULT =
  'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'
export const CHANGEOVER_CONFIG_DEFAULT =
  '0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
export const AVAILABILITY_INSTANT_BOOKABLE_NO = 'N'
export const AVAILABILITY_INSTANT_BOOKABLE_YES = 'Y'

export const BOOKABLE_TYPE_INSTANT_BOOKABLE = 'instant_bookable'
export const BOOKABLE_TYPE_REQUEST_TO_BOOK = 'request_to_book'
export const BOOKABLE_TYPE_ENQUIRY_ONLY = 'enquiry_only'
export const BOOKABLE_TYPE_ENQUIRY_WITH_PRICE = 'enquiry_with_price'

export const RESERVATION_TYPE_ENQUIRY = 'TYPE_ENQUIRY'
export const RESERVATION_TYPE_REQUEST_TO_BOOK = 'TYPE_REQUEST_TO_BOOK'
export const RESERVATION_TYPE_INSTANT_BOOKING = 'TYPE_INSTANT_BOOKING'

export const STATUS_UNCONFIRMED = 'STATUS_UNCONFIRMED'
export const STATUS_CANCELLED = 'STATUS_CANCELLED'
export const STATUS_TENTATIVE = 'STATUS_TENTATIVE'
export const STATUS_PENDING = 'STATUS_PENDING'
export const STATUS_CONFIRMED = 'STATUS_CONFIRMED'
export const STATUS_ACCEPTED = 'STATUS_ACCEPTED'
export const STATUS_DENIED = 'STATUS_DENIED'
export const STATUS_PENDING_VOIDED = 'STATUS_PENDING_VOIDED'
export const STATUS_PENDING_PAYMENT = 'STATUS_PENDING_PAYMENT'
export const STATUS_CANCELLED_BY_ADMIN = 'STATUS_CANCELLED_BY_ADMIN'
export const STATUS_CANCELLED_BY_GUEST = 'STATUS_CANCELLED_BY_GUEST'

/*
export const ORDER_ITEM_TYPE_RENTAL = 'TYPE_RENTAL';
export const ORDER_ITEM_TYPE_DAMAGE_DEPOSIT = 'TYPE_DAMAGE_DEPOSIT';
export const ORDER_ITEM_TYPE_EQUIPMENT = 'TYPE_EQUIPMENT';
export const ORDER_ITEM_TYPE_MISC = 'TYPE_MISC';
export const ORDER_ITEM_TYPE_PROTECTION = 'TYPE_PROTECTION';
export const ORDER_ITEM_TYPE_COMMISSION = 'TYPE_COMMISSION';
export const ORDER_ITEM_TYPE_DISCOUNT = 'TYPE_DISCOUNT';
export const ORDER_ITEM_TYPE_FEE = 'TYPE_FEE';
export const ORDER_ITEM_TYPE_TAX = 'TYPE_TAX';
export const ORDER_ITEM_TYPE_LYCAN_CHANNEL_COMMISSION = 'TYPE_LYCAN_CHANNEL_COMMISSION';
*/

export const ORDER_ITEM_TYPE_ACTIVITY = 'TYPE_ACTIVITY'
export const ORDER_ITEM_TYPE_DAMAGE_DEPOSIT = 'TYPE_DAMAGE_DEPOSIT'
export const ORDER_ITEM_TYPE_EQUIPMENT = 'TYPE_EQUIPMENT'
export const ORDER_ITEM_TYPE_MISC = 'TYPE_MISC'
export const ORDER_ITEM_TYPE_PROTECTION = 'TYPE_PROTECTION'
export const ORDER_ITEM_TYPE_COMMISSION = 'TYPE_COMMISSION'
export const ORDER_ITEM_TYPE_RENTAL = 'TYPE_RENTAL'
export const ORDER_ITEM_TYPE_DISCOUNT = 'TYPE_DISCOUNT'
export const ORDER_ITEM_TYPE_FEE = 'TYPE_FEE'
export const ORDER_ITEM_TYPE_TAX = 'TYPE_TAX'
export const ORDER_ITEM_TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE =
  'TYPE_LYCAN_CHANNEL_GUEST_SERVICE_FEE'
export const ORDER_ITEM_TYPE_LYCAN_CHANNEL_ADJUSTMENT =
  'TYPE_LYCAN_CHANNEL_ADJUSTMENT'
export const ORDER_ITEM_TYPE_LYCAN_CHANNEL_COMMISSION =
  'TYPE_LYCAN_CHANNEL_COMMISSION'

export const PAYMENT_ITEM_DISCR_PAYMENT = 'payment' // Lycan determines descriminator type based on pairing of gateway automatically (consult chris)
export const PAYMENT_ITEM_DISCR_IMMEDIATE_PAYMENT = 'immediatepayment' // Website forces payment against paired gateway
export const PAYMENT_ITEM_DISCR_DEFERRED_PAYMENT = 'deferredpayment'
export const PAYMENT_ITEM_TYPE_CARD = 'TYPE_CARD'
export const PAYMENT_ITEM_STATUS_DUE = 'STATUS_DUE'
