import { CHANGE_CURRENCY } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/constants';
import { CHANGE_LOCALE } from '@rentivo/gatsby-core/src/containers/LanguageProvider/constants';
import { SEARCHBAR_SEARCH, SET_FILTER } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import {
  ADD_TO_CART,
  CHECKOUT_NEXT_STEP, RECEIVE_RESERVATION,
  SET_CUSTOMER_INFO
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { RECEIVE_ENQUIRY } from '@rentivo/gatsby-core/src/containers/EnquiryProvider/constants';
import {
  OPEN_PROPERTY_ENQUIRY_MODAL,
  RECEIVE_PROPERTY_ENQUIRY
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/constants';
import { RECEIVE_PRICING_DATA } from '@rentivo/gatsby-core/src/containers/PricingProvider/constants';
import { selectPricingDataIsAvailable } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import {
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';

export const trackingCategories = {
  WEBSITE: 'Website',
  SEARCH: 'Search',
  ENQUIRY: 'Enquiry',
  PROPERTY: 'Property',
  BOOKING: 'Booking'
};

export const debouncedTime = 1500;

export const trackingItems = {
  EVENT_WEBSITE_CHANGE_CURRENCY: {
    name: 'event/website/change-currency',
    category: trackingCategories.WEBSITE,
    action: 'Currency Changed',
    label: 'Currency',
    type: CHANGE_CURRENCY,
    getPayload: (action) => (action && action.currency) ? action.currency : undefined
  },
  EVENT_WEBSITE_CHANGE_LOCALE: {
    name: 'event/website/change-locale',
    category: trackingCategories.WEBSITE,
    action: 'Language Changed',
    label: 'Language',
    type: CHANGE_LOCALE,
    getPayload: (action) => (action && action.lang) ? action.lang : undefined
  },
  EVENT_WEBSITE_SEARCHBAR_CHANGE: {
    name: 'event/website/searchbar-change',
    category: trackingCategories.WEBSITE,
    action: 'Searchbar Changed',
    label: 'Searchbar',
    type: SET_FILTER,
    trigger: (action) => action && action.from && action.from === 'searchbar'
  },
  EVENT_WEBSITE_SEARCHBAR_SEARCH: {
    name: 'event/website/searchbar-search',
    category: trackingCategories.WEBSITE,
    action: 'Searchbar Searched',
    label: 'Searchbar',
    type: SEARCHBAR_SEARCH
  },
  EVENT_SEARCH_FILTER: {
    name: 'event/search/filter',
    category: trackingCategories.SEARCH,
    action: 'Property Search Filtered',
    label: 'Property Search',
    type: SET_FILTER,
    trigger: (action) => action && action.from && action.from === 'search' && action.filter && action.filter.id !== 'page' && action.filter.id !== 'adults' && action.filter.id !== 'children' && action.filter.id !== 'infants' && action.filter.id !== 'pets'
  },
  EVENT_SEARCH_PAGE_CHANGE: {
    name: 'event/search/page-change',
    category: trackingCategories.SEARCH,
    action: 'Page Changed',
    label: 'Property Search',
    type: SET_FILTER,
    trigger: (action) => action && action.from && action.from === 'search' && action.filter && action.filter.id === 'page',
    getPayload: (action) => (action && action.filter) ? action.filter.value : undefined
  },
  EVENT_SEARCH_SELECT_AVAILABILITY: {
    name: 'event/search/select-availability',
    category: trackingCategories.SEARCH,
    action: 'Availability Changed',
    label: 'Property Search',
    type: SET_FILTER,
    trigger: (action) => action && action.from && action.from === 'search' && action.filter && action.filter.id === 'availability',
  },
  EVENT_SEARCH_SELECT_GUESTS: {
    name: 'event/search/select-guests',
    category: trackingCategories.SEARCH,
    action: 'Guests Changed',
    label: 'Property Search',
    type: SET_FILTER,
    trigger: (action) => action && action.from && action.from === 'search' && action.filter && action.filter.id === 'guests',
  },
  EVENT_ENQUIRY_CUSTOMER_SEND: {
    name: 'event/enquiry/customer/send',
    category: trackingCategories.ENQUIRY,
    action: 'Customer Enquiry Sent',
    label: 'Customer Enquiry',
    type: RECEIVE_ENQUIRY,
    trigger: (action) => action && action.formId && action.formId === 'customer'
  },
  EVENT_ENQUIRY_BUSINESS_SEND: {
    name: 'event/enquiry/business/send',
    category: trackingCategories.ENQUIRY,
    action: 'Business Enquiry Sent',
    label: 'Business Enquiry',
    type: RECEIVE_ENQUIRY,
    trigger: (action) => action && action.formId && action.formId === 'business'
  },
  EVENT_ENQUIRY_PROPERTY_OPEN: {
    name: 'event/enquiry/property/open',
    category: trackingCategories.ENQUIRY,
    action: 'Property Enquiry Opened',
    label: 'Property Enquiry',
    type: OPEN_PROPERTY_ENQUIRY_MODAL
  },
  EVENT_ENQUIRY_PROPERTY_SEND: {
    name: 'event/enquiry/property/send',
    category: trackingCategories.ENQUIRY,
    action: 'Property Enquiry Sent',
    label: 'Property Enquiry',
    type: RECEIVE_PROPERTY_ENQUIRY
  },
  EVENT_PROPERTY_CHECK_QUOTE: {
    name: 'event/property/check-quote',
    category: trackingCategories.PROPERTY,
    action: 'Property Quote Checked',
    label: 'Property Quote',
    nonInteraction: true, // TODO: Need a better way to distinguish where this is happening. As it happens in checkout all the time.
    type: RECEIVE_PRICING_DATA,
    trigger: (action) => action && !action.inCheckout
  },
  EVENT_PROPERTY_CHECK_QUOTE_AVAILABLE: {
    name: 'event/property/check-quote/available',
    category: trackingCategories.PROPERTY,
    action: 'Property Quote Available',
    nonInteraction: true,
    label: 'Property Quote',
    type: RECEIVE_PRICING_DATA,
    trigger: (action, state) => !action.inCheckout && selectPricingDataIsAvailable(state)
  },
  EVENT_PROPERTY_CHECK_QUOTE_UNAVAILABLE: {
    name: 'event/property/check-quote/unavailable',
    category: trackingCategories.PROPERTY,
    action: 'Property Quote Unavailable',
    label: 'Property Quote',
    nonInteraction: true,
    type: RECEIVE_PRICING_DATA,
    trigger: (action, state) => !action.inCheckout && !selectPricingDataIsAvailable(state)
  },
  EVENT_BOOKING_CONFIRM_QUOTE: {
    name: 'event/booking/confirm-quote',
    category: trackingCategories.BOOKING,
    action: 'Property Quote Confirmed',
    label: 'Property Quote',
    type: ADD_TO_CART,
    facebookAction: 'AddToCart',
    trigger: (action) => action && !action.inCheckout
  },
  EVENT_BOOKING_CONFIRM_REVIEW: {
    name: 'event/booking/confirm-review',
    category: trackingCategories.BOOKING,
    action: 'Booking Review Confirmed',
    label: 'Booking Review',
    type: CHECKOUT_NEXT_STEP,
    trigger: (action) => action && action.currentStep && action.currentStep === 1
  },
  EVENT_BOOKING_CONFIRM_GUEST_INFO: {
    name: 'event/booking/confirm-guest-info',
    category: trackingCategories.BOOKING,
    action: 'Guest Info Confirmed',
    label: 'Guest Info',
    type: SET_CUSTOMER_INFO
  },
  EVENT_BOOKING_REQUEST_TO_BOOK: {
    name: 'event/booking/request-to-book',
    category: trackingCategories.BOOKING,
    action: 'Requested To Book',
    label: 'Request To Book',
    type: RECEIVE_RESERVATION,
    trigger: (action) => action && action.data && action.data.reservationType && action.data.reservationType === RESERVATION_TYPE_REQUEST_TO_BOOK,
    getPayload: (action) => (action && action.data && action.data.cartTotal && action.data.reservationData) ? { value: action.data.cartTotal, conversionValue: action.data.cartTotal, ...action.data.reservationData } : undefined
  },
  EVENT_BOOKING_INSTANT_BOOK: {
    name: 'event/booking/instant-book',
    category: trackingCategories.BOOKING,
    action: 'Instant Booked',
    label: 'Instant Book',
    type: RECEIVE_RESERVATION,
    trigger: (action) => action && action.data && action.data.reservationType && action.data.reservationType === RESERVATION_TYPE_INSTANT_BOOKING,
    getPayload: (action) => (action && action.data && action.data.cartTotal && action.data.reservationData) ? { value: action.data.cartTotal, conversionValue: action.data.cartTotal, ...action.data.reservationData } : undefined
  },
  /*

  reservationData,
            reservationType,
            cart,
            chargeExchangeRate,
            cartTotalDueToday,
            cartTotalChargeDueToday,
            cartTotal,
            chargeCurrency

   */
  /*
  EVENT_BOOKING_CONFIRM_REVIEW: 'event/booking/confirm-review',
  EVENT_BOOKING_CONFIRM_GUEST_INFO: 'event/booking/confirm-guest-info',
  EVENT_BOOKING_REQUEST_TO_BOOK: 'event/booking/request-to-book',
  EVENT_BOOKING_INSTANT_BOOK: 'event/booking/instant-book',
  EVENT_BOOKING_EXTERNAL_BOOK: 'event/booking/external-book', //TODO

  XXX: {
    name: '',
    action: CHANGE_LOCALE
  },*/
};

export const trackingItemsArray = Object.entries(trackingItems).map(entry => ({key: entry[0], ...entry[1]}));
