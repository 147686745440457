import { window } from 'browser-monads';
import { trimFirstAndLastSlash, trimLastSlash } from '@rentivo/gatsby-core/src/utils/strings';

export const addUrlParamString = (to, baseUrl, queryParamsString) => {
  if(!to) return `/`;
  if(baseUrl && to.includes(baseUrl)) to = to.replace(baseUrl, '');
  if(!queryParamsString) return to;
  const lastChar = to.substr(to.length - 1);
  if(to.includes('?')) {
    if(lastChar === '?') return `${to}${queryParamsString}`;
    if(lastChar === '&') return `${to}${queryParamsString}`;
    return `${to}&${queryParamsString}`;
  }
  return `${to}?${queryParamsString}`;
};

export const isInternal = to => {
  if(to.includes('mailto:') || to.includes('tel:')) return false;
  // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
  if (to.indexOf('://') === -1) return true;
  const toLocation = parseTo(to);
  return window.location.hostname === toLocation.hostname;
};

export const isMailOrTel = to => (to.includes('mailto:') || to.includes('tel:'));

export const parseTo = (url) => {
  const m = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/),
    r = {
      hash: m[10] || "",                   // #asd
      host: m[3] || "",                    // localhost:257
      hostname: m[6] || "",                // localhost
      href: m[0] || "",                    // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: m[1] || "",                  // http://username:password@localhost:257
      pathname: m[8] || (m[1] ? "/" : ""), // /deploy/
      port: m[7] || "",                    // 257
      protocol: m[2] || "",                // http:
      search: m[9] || "",                  // ?asd=asd
      username: m[4] || "",                // username
      password: m[5] || ""                 // password
    };
  if (r.protocol.length === 2) {
    r.protocol = "file:///" + r.protocol.toUpperCase();
    r.origin = r.protocol + "//" + r.host;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return m && r;
};

const addTrailingSlash = (url) => {
  let trimmedUrl = url;

  // Check if we are a hash url, if NOT, then add trailing slash.
  if(!url.includes('#')) {

    trimmedUrl = trimLastSlash(url);

    if(process.env.NODE_ENV === 'production') {
      if(trimmedUrl.includes('?')) {
        const splitTrimmedUrl = trimmedUrl.split('?');
        trimmedUrl = `${splitTrimmedUrl[0]}?${splitTrimmedUrl[1]}`
      } else {
        trimmedUrl = `${trimmedUrl}/`
      }
    }

  }

  return (trimmedUrl === '//') ? '/' : trimmedUrl;
};

export const generateLocaleLink = (locale, defaultLocale, pathObject) => {
  //console.log('debug', pathObject);
  if (typeof pathObject === 'string') {
    if (pathObject === '/') {
      return addTrailingSlash((locale === defaultLocale) ? pathObject : `/${locale}`);
    }

    // If absolute URL
    const pat = /^https?:\/\//i;
    if (pat.test(pathObject)) {
      return pathObject;
    } else {
      if (locale === defaultLocale) {
        return addTrailingSlash(pathObject); // Do not need to change anything
      } else {
        // We need to place the current locale in this to path
        return addTrailingSlash(`/${locale}/${trimFirstAndLastSlash(pathObject)}/`);
      }
    }

  } else {
    // This is an object, so we need to see if we have the key for provided locale
    if(typeof pathObject?.locale === 'string') {
      // Locale key exists
      return addTrailingSlash(pathObject[locale]);
    } else {
      // The selected locale does not exist so we need to try and default to the DEFAULT_LOCALE
      if(typeof pathObject?.defaultLocale === 'string') {
        return addTrailingSlash(pathObject[defaultLocale]);
      } else {
        throw new Error('Could not determine the correct link');
      }
    }
  }
};
