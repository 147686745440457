export const shortSize = (longSize, d = 'md') => {
  if(longSize === 'xs' || longSize === 'sm' || longSize === 'md' || longSize === 'lg' || longSize === 'xl') return longSize;
  if(!d) d = 'md';
  let size;
  switch (longSize) {
    case 'small':
      size = 'sm';
      break;
    case 'medium':
      size = 'md';
      break;
    case 'default':
      size = 'md';
      break;
    case 'large':
      size = 'lg';
      break;
    default:
      size = d;
  }

  return size;
};
