export const DEFAULT_DEVICE = 'computer';
export const DEFAULT_BREAKPOINT = 'lg';
export const DEVICE_MOBILE = 'mobile';
export const DEVICE_TABLET = 'tablet';
export const DEVICE_COMPUTER = 'computer';

export const DEVICE_SIZES = {
  [DEVICE_MOBILE]: ['xs', 'sm', 'md'],
  [DEVICE_TABLET]: ['lg', 'xl'],
  [DEVICE_COMPUTER]: ['xxl']
};
