import { decodeHTMLEntities } from '@rentivo/gatsby-core/src/utils/strings';

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const addIdToObjects = (objects) => {
  const entries = Object.entries(objects);
  entries.forEach(entry => {
    objects[entry[0]]['id'] = entry[0] // Add ID parameter to filter
  });
  return objects;
};

export const objectsToArray = (objects) => {
  const array = [];
  const entries = Object.entries(objects);
  entries.forEach(e => {
    array.push({
      id: e[0],
      ...e[1]
    })
  });
  return array;
};

export const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  );

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const clearObject = (obj, clearVal = '') => {
  const newObj = {};
  for(const [key] of Object.entries(obj)) {
    newObj[key] = (typeof newObj[key] === 'object') ? clearObject(newObj[key], clearVal) : clearVal;
  }
  return newObj;
};

export const decodeHTMLEntitiesForObject = (obj) => {
  try {
    return JSON.parse(decodeHTMLEntities(JSON.stringify(obj)));
  } catch (e) {
    return obj;
  }
};
