export const textStyles = (object, props) => {

  const { fontSize, fontFamily, fontWeight, lineHeight } = props;

  if(fontSize) {
    const { fontSize: themeFontSize, ...newObject } = object; // If size prop exists, remove it.
    object = { ...newObject, fontSize }; // Add new one back in.
  }

  if(fontFamily) {
    const { fontFamily: themeFontFamily, ...newObject } = object; // If size prop exists, remove it.
    object = { ...newObject, fontFamily }; // Add new one back in.
  }

  if(fontWeight) {
    const { fontWeight: themeFontWeight, ...newObject } = object; // If size prop exists, remove it.
    object = { ...newObject, fontWeight }; // Add new one back in.
  }

  if(lineHeight) {
    const { fontWeight: themeLineHeight, ...newObject } = object; // If size prop exists, remove it.
    object = { ...newObject, lineHeight }; // Add new one back in.
  }

  return object;
};

export default {
  variants: {
    default: ({theme: { typography: { text } }, ...props}) => ({
      ...textStyles(text, props)
    }),
    writing: ({theme: { typography: { textWriting } }, ...props}) => ({
      ...textStyles(textWriting, props)
    }),
    light: ({theme: { typography: { text } }, ...props}) => ({
      ...textStyles(text, props),
      color: "textLight"
    })
  },
  defaultProps: {
    variant: "default",
    size: null
  }
}
