/*
 *
 * LanguageProvider reducer
 *
 */

import { CHANGE_LOCALE, UPDATE_LANGUAGE, UPDATE_LANGUAGE_URI } from './constants';

export const initialState = {
  locale: null,
  lang: null,
  languages: [],
  defaultLang: null,
  translations: null,
  uri: null
};

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
        lang: action.lang
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        ...action.language
      };
    case UPDATE_LANGUAGE_URI:
      return {
        ...state,
        uri: action.uri
      };
    default:
      return state;
  }
}

export default languageProviderReducer;
