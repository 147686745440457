import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import CookiePopup from '@rentivo/gatsby-core/src/components/generic/CookiePopup/component';
import {
  selectCookiesActiveConfig,
  selectCookiesPolicyUrlConfig, selectCookiesPositionConfig, selectTrackingConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import Cookies from 'js-cookie';
import {
  COOKIE_ACCEPTED_ALL,
  COOKIE_ACCEPTED_ALL_TRUE
} from '@rentivo/gatsby-core/src/components/generic/CookiePopup/constants';
import { defaultOptions, TRACKING_TYPES } from '@rentivo/gatsby-core/tracking';

const mapStateToProps = createSelector(
  selectCookiesActiveConfig,
  selectCookiesPolicyUrlConfig,
  selectCookiesPositionConfig,
  selectTrackingConfig,
  (active, policyUrl, position, tracking) => {
    const activeTracking = Object.keys(tracking).filter(key => defaultOptions[key] && tracking[key].active).map(key => ({ ...defaultOptions[key], ...tracking[key] }));
    const activeCookies = Object.entries(TRACKING_TYPES).map(([key, value]) => {
      const trackers = activeTracking.filter(at => at.controlCookieName === value.cookieName);
      const trackersWithAutoStart = trackers.filter(t => t.autoStart);
      return { id: key, ...value, trackers, autoStart: (trackersWithAutoStart.length > 0) };
    }).filter(t => t.trackers.length);
    return {
      active,
      policyUrl,
      position,
      activeCookies,
      accepted: (Cookies.get(COOKIE_ACCEPTED_ALL) === COOKIE_ACCEPTED_ALL_TRUE)
    }
  }
);

CookiePopup.defaultProps = {
  forceModal: false
};

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(CookiePopup)
