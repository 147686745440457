import {
  SORT_RELEVANCE_ASC,
  SORT_NONE,
  sortMap,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/components/results/SortResults/constants'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { dataField } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilter/constants'
dayjs.extend(weekOfYear)

const now = dayjs()
const week = [parseInt(now.format('YYYY')), parseInt(now.week())]

const functions = [
  {
    random_score: {
      seed: week,
      field: '_seq_no',
    },
  },
]

export const query = ({ filterOptions, value, allFilters }) => {
  if (!value || value === SORT_NONE || value === SORT_RELEVANCE_ASC) {
  }

  const sortItem = sortMap[value]
  if (!sortItem) return null

  let query = undefined

  if (
    sortItem.dataField === 'listing.sleeps' ||
    sortItem.dataField === 'pricing.visual.nightlyLow'
  ) {
    query = {
      range: {
        [sortItem.dataField]: {
          gte: 0,
        },
      },
    }
  }

  return {
    sort: [{ [sortItem.dataField]: { order: sortItem.order } }],
    functions: functions,
    query: query,
  }
}

export const defaultQuery = query

export const initialQuery = params => {
  const { filterOptions, allFilters } = params
  const sort =
    filterOptions &&
    filterOptions.defaultValue &&
    filterOptions.defaultValue === 'CREATED_AT_DESC'
      ? [{ $createdAt: { order: 'desc' } }]
      : undefined

  return {
    functions: functions,
    sort,
  }
}
