import { get, isEqual } from 'lodash';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';
import { RESERVATION_TYPE_ENQUIRY, STATUS_PENDING } from '@rentivo/gatsby-core/src/constants/lycanConstants';
import {
  makeSelectPricingDataDisplay,
  makeSelectPricingHasPrice,
  makeSelectPricingOrderItems,
  selectPricingDataStayBreakdown, selectPricingEndDate, selectPricingStartDate,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import {
  selectApiLycanHostConfig, selectDefaultCurrencyConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { getBaseReservationData } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/utils';

export const selectPropertyEnquiry = state => get(state, 'propertyEnquiry', {});
export const selectPropertyEnquiryPropertyId = state => get(selectPropertyEnquiry(state), 'propertyId', null);
export const selectPropertyEnquiryData = state => get(selectPropertyEnquiry(state), 'data', null);
export const selectPropertyEnquiryIsFetching = state => get(selectPropertyEnquiry(state), 'isFetching', false);
export const selectPropertyEnquiryDidInvalidate = state => get(selectPropertyEnquiry(state), 'didInvalidate', false);
export const selectPropertyEnquiryError = state => get(selectPropertyEnquiry(state), 'didInvalidate', false);
export const selectPropertyEnquiryForm = state => get(selectPropertyEnquiry(state), 'form', {});

export const makeSelectPropertyEnquiryData = createDeepEqualSelector(
  selectElasticSearchConfig,
  selectApiLycanHostConfig,
  selectSiteNameConfig,
  selectDefaultCurrencyConfig,
  selectPropertyEnquiryPropertyId,
  selectPropertyEnquiryForm,
  makeSelectPricingHasPrice,
  selectPricingDataStayBreakdown,
  selectLang,
  makeSelectPricingDataDisplay,
  makeSelectPricingOrderItems,
  selectPricingStartDate,
  selectPricingEndDate,
  (app, host, siteName, defaultCurrency, propertyId, enquiryData, hasPrice, stayBreakdown, preferredLocale, pricingDataDisplay, orderItems, pricingStartDate, pricingEndDate) => {
    const { chargeCurrency } = pricingDataDisplay;
    const baseData = getBaseReservationData({stayBreakdown, siteName, chargeCurrency});

    const resData = {
      ...baseData,
      bookingCurrency: (baseData.bookingCurrency) ? baseData.bookingCurrency : currency,
      reservationType: RESERVATION_TYPE_ENQUIRY,
      reservationStatus: STATUS_PENDING,
      propertyUuid: propertyId,
      arrivalDate: enquiryData.startDate,
      departureDate: enquiryData.endDate,
      adults: enquiryData.adults,
      children: enquiryData.children,
      infants: enquiryData.infants,
      pets: enquiryData.pets,
      guest: {
        email: enquiryData.email,
        firstName: enquiryData.firstName,
        lastName: enquiryData.lastName,
        phoneNumbers: enquiryData.phone ? [enquiryData.phone] : [],
        preferredLocale: preferredLocale
      },
      guestMessage: enquiryData.guestMessage
    };

    // Check the dates are the same before adding pricing data (order items) to enquiry
    const pricingDates = { startDate: pricingStartDate, endDate: pricingEndDate };
    const enquiryDates = { startDate: enquiryData.startDate, endDate: enquiryData.endDate };
    const areDatesSameAsPricing = isEqual(pricingDates, enquiryDates);

    if (hasPrice && orderItems && areDatesSameAsPricing) resData.orderItems = orderItems; // Add order items if they exist...
    return resData;
  }
);
