import { getUrlSearchParams } from '@rentivo/gatsby-core/src/utils/urlParams';
import { numericStringToNumber } from '@rentivo/gatsby-core/src/utils/strings';
import { pushUrlParams } from '@rentivo/gatsby-core/src/utils/urlParams';

export const getDefaultValuesFromUrlParamsAndDataConfig = (dataConfig, useDefaultValue = true) => {
  const finalValues = {};
  const urlParams = getUrlSearchParams();

  for (let [internalKey, configObj] of Object.entries(dataConfig)) {
    const { urlParam: configKey, defaultValue } = configObj;
    const urlParam = urlParams.get(`${configKey}`);
    if(urlParam) {
      finalValues[internalKey] = numericStringToNumber(urlParam);
    } else if(defaultValue && useDefaultValue) {
      finalValues[internalKey] = defaultValue;
    }
  }

  return finalValues;
};

const getConfigUrlParam = (key, dataConfig) => {
  const config = dataConfig[key];
  return (config && config.urlParam) ? config.urlParam : null
};

export const setUrlParams = (payload, dataConfig) => {
  const urlSearchParams = getUrlSearchParams();

  for (let [key, value] of Object.entries(payload)) {
    const paramKey = getConfigUrlParam(key, dataConfig);
    if(paramKey) {
      if(!value || value === 0) {
        urlSearchParams.delete(paramKey);
      } else {
        urlSearchParams.set(paramKey, `${value}`);
      }
    }
  }

  pushUrlParams(urlSearchParams);
};
