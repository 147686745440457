import { CHANGE_CURRENCY, SET_CURRENCY_EXCHANGE_MAP } from '@rentivo/gatsby-core/src/containers/CurrencyProvider/constants';

export const initialState = {
  currentCurrency: null,
  currencyExchangeMap: null
};

function currencyProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CURRENCY:
      return {
        ...state,
        currentCurrency: action.currency,
      };
    case SET_CURRENCY_EXCHANGE_MAP:
      return {
        ...state,
        currencyExchangeMap: action.currencyExchangeMap,
      };
    default:
      return state;
  }
}

export default currencyProviderReducer;
