import { SET_THEME_OPTION } from '@rentivo/gatsby-core/src/theme/constants';


export const initialState = {};

function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME_OPTION:
      return {
        ...state,
        [action.key]: action.value
      };
    default:
      return state;
  }
}

export default themeReducer;
