import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/generic/CookiePopup/messages';
import cookieMessages  from '@rentivo/gatsby-core/src/components/generic/CookiePopup/cookieMessages';
import { Button, LocaleLink } from '@rentivo/gatsby-core';
import Cookies from 'js-cookie';
import {
  COOKIE_ACCEPTED_ALL,
  COOKIE_ACCEPTED_ALL_FALSE,
  COOKIE_ACCEPTED_ALL_TRUE
} from '@rentivo/gatsby-core/src/components/generic/CookiePopup/constants';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Switch,
  Flex,
  Heading,
  Text,
  Box,
  CloseButton,
  Link,
  VStack
} from '@chakra-ui/react';

const TrackingCookieSetting = ({cookie: { id, cookieName, trackers, autoStart }}) => {
  const cookie = Cookies.get(cookieName);
  const [on, setOn] = useState(cookie === COOKIE_ACCEPTED_ALL_FALSE ? false : (cookie === COOKIE_ACCEPTED_ALL_TRUE || autoStart));
  const { formatMessage } = useIntl();
  const title = formatMessage(cookieMessages[`${id}Title`]);
  const description = (cookieMessages[`${id}Description`]) ? formatMessage(cookieMessages[`${id}Description`]) : null;

  const handleOnChange = () => {
    const newChecked = !on;
    Cookies.set(cookieName, (newChecked) ? COOKIE_ACCEPTED_ALL_TRUE : COOKIE_ACCEPTED_ALL_FALSE, { sameSite:'strict' });
    setOn(newChecked);
  };

  return (
    <Flex
      justify="space-between"
      align="center"
    >
      <div>
        <Heading as="h4" size="xl">{title}</Heading>
        {description && <Text>{description}</Text>}
      </div>
      <Switch ml={4} isChecked={on} onChange={handleOnChange}/>
    </Flex>
  );
};

const CookiePopupComponent = ({active, accepted, policyUrl, position, modalVisible, setModalVisible, activeCookies, forceModal}) => {
  const [internalAccepted, setInternalAccepted] = useState(false);
  const [internalModalVisible, setInternalModalVisible] = useState(false);
  setModalVisible = (setModalVisible !== undefined) ? setModalVisible : setInternalModalVisible;
  modalVisible = (modalVisible !== undefined) ? modalVisible : internalModalVisible;

  const attrs = {
    position
  };

  const onOk = () => {
    Cookies.set(COOKIE_ACCEPTED_ALL, COOKIE_ACCEPTED_ALL_TRUE, { sameSite:'strict' });
    setInternalAccepted(true);
  };

  return (
    <>
      {(!internalAccepted && active && !accepted && policyUrl) && (
        <Box
          position="fixed"
          zIndex={99999999999}
          width={{base: '100%', md: '480px'}}
          p={{base: 3, md: 6}}
          bottom={0}
          right={attrs.position === 'right' ? 0 : 'auto'}
          left={attrs.position === 'left' ? 0 : attrs.position === 'center' ? '50%' : 'auto'}
          transform={attrs.position === 'center'? 'translateX(-50%)' : 'none'}
        >
          <Flex
            bg="whiteAlpha.900"
            boxShadow="lg"
            pt={4}
            pb={4}
            pr={3}
            pl={3}
            borderRadius="md"
            align="center"
            height="100%"
          >
            <CloseButton onClick={onOk} mr={3}/>
            <Text as="div" h="100%" flexGrow="1">
                <FormattedMessage
                  {...messages.cookiePopupText}
                  values={{
                    policy: (children) => <LocaleLink to={policyUrl} target={`_blank`} fontWeight="semibold">{children}</LocaleLink>,
                    modal: (children) => <Link fontWeight="semibold" href="javascript:void(0)" onClick={() => setModalVisible(true)}>{children}</Link>
                  }}
                />
            </Text>
            <Button ml={3} onClick={onOk} type="primary" size="small"><FormattedMessage {...messages.cookiePopupOkButton}/></Button>
          </Flex>
        </Box>
      )}
      {((!internalAccepted && active && !accepted && policyUrl) || forceModal) && (
        <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader><FormattedMessage {...messages.cookieModalTitle}/></ModalHeader>
            <ModalCloseButton as={CloseButton} />
            <ModalBody>
              <VStack spacing={4}>
                <Text><FormattedMessage
                  {...messages.cookieModalDescription}
                  values={{
                    policy: (children) => <LocaleLink to={policyUrl} target={`_blank`} color="primary.600">{children}</LocaleLink>,
                  }}
                /></Text>
                {activeCookies.map(cookie => (<TrackingCookieSetting cookie={cookie} key={cookie.id}/>))}
              </VStack>
            </ModalBody>

            <ModalFooter display="flex" justifyContent="space-between">
              <Button type="primary" onClick={() => setModalVisible(false)}><FormattedMessage {...messages.cookieModalOkButton}/></Button>
              <Button type="default" onClick={() => setModalVisible(false)}><FormattedMessage {...messages.cookieModalCancelButton}/></Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CookiePopupComponent;
