export const UPDATE_DATES = 'PricingProvider/UPDATE_DATES';
export const UPDATE_GUESTS = 'PricingProvider/UPDATE_GUESTS';
export const SET_DEFAULT_VALUES = 'PricingProvider/SET_DEFAULT_VALUES';
export const SET_VALUES = 'PricingProvider/SET_VALUES';
export const CLEAR_PRICING = 'PricingProvider/CLEAR_PRICING';
export const REQUEST_PRICING_DATA = 'PricingProvider/REQUEST_PRICING_DATA';
export const RECEIVE_PRICING_DATA = 'PricingProvider/RECEIVE_PRICING_DATA';
export const INVALIDATE_PRICING_DATA = 'PricingProvider/INVALIDATE_PRICING_DATA';

export const SET_DATES_POPOVER_OPEN = 'PricingProvider/SET_DATES_POPOVER_OPEN';
export const SET_GUESTS_POPOVER_OPEN = 'PricingProvider/SET_GUESTS_POPOVER_OPEN';
export const PRICING_DATA_CONFIG = {
  "guests": {
    "urlParam": "guests",
    "defaultValue": 0,
    "min": 0,
    "max": 24
  },
  "adults": {
    "active": true,
    "urlParam": "adults",
    "countsToTotal": true,
    "defaultValue": 0,
    "min": 0,
    "max": 24
  },
  "children": {
    "active": true,
    "urlParam": "children",
    "countsToTotal": true,
    "defaultValue": 0,
    "min": 0,
    "max": 24
  },
  "infants": {
    "active": true,
    "urlParam": "infants",
    "countsToTotal": false,
    "defaultValue": 0,
    "min": 0,
    "max": 24
  },
  "pets": {
    "active": true,
    "urlParam": "pets",
    "countsToTotal": false,
    "defaultValue": 0,
    "min": 0,
    "max": 10
  },
  "startDate": {
    "urlParam": "check_in"
  },
  "endDate": {
    "urlParam": "check_out"
  },
  "nights": {
    "urlParam": "nights"
  }
};

export const PRICING_ENQUIRY_DISPLAY_MODE_ON_ANY_RESPONSE = 'ON_ANY_RESPONSE';
export const PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE = 'ON_FAILED_PRICE';
export const PRICING_ENQUIRY_DISPLAY_MODE_NEVER = 'NEVER';

export const PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE = 'ON_SUCCESSFUL_PRICE';
export const PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE_INCLUDING_ENQUIRY = 'ON_SUCCESSFUL_PRICE_INCLUDING_ENQUIRY';
