const typography = {
  "letterSpacings": {
    "tighter": "-0.05em",
    "tight": "-0.025em",
    "normal": "0",
    "wide": "0.025em",
    "wider": "0.05em",
    "widest": "0.1em"
  },
  "lineHeights": {
    "normal": "normal",
    "none": 1,
    "shorter": 1.25,
    "short": 1.375,
    "base": 1.5,
    "tall": 1.75,
    "taller": "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem"
  },
  "fontWeights": {
    "hairline": 100,
    "thin": 200,
    "light": 300,
    "normal": 400,
    "medium": 500,
    "semibold": 600,
    "bold": 700,
    "extrabold": 800,
    "black": 900
  },
  "fonts": {
    "heading": "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif",
    "button": "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif",
    "body": "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif",
    "mono": "SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace"
  },
  "fontSizes": {
    "xs": "0.75rem",
    "sm": "0.875rem",
    "md": "1rem",
    "lg": "1.125rem",
    "xl": "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem"
  },
  "typography": {
    "text": {
      "fontFamily": "body",
      "fontWeight": "normal",
      "lineHeight": "base",
      "fontSize": "md"
    },
    "textWriting": {
      "fontFamily": "heading",
      "fontWeight": "normal",
      "lineHeight": "tall",
      "fontSize": "md"
    },
    "heading": {
      "h1": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "2xl", "lg": "3xl" },
        "color": "text"
      },
      "h2": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "xl", "lg": "2xl" },
        "color": "text"
      },
      "h3": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "lg", "lg": "xl" },
        "color": "text"
      },
      "h4": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "md", "lg": "lg" },
        "color": "text"
      },
      "h5": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": "md",
        "color": "text"
      },
      "h6": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": "md",
        "color": "text"
      }
    },
    "writingHeading": {
      "h1": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "3xl", "md": "4xl", "lg": "5xl" },
        "color": "text"
      },
      "h2": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "2xl", "md": "3xl", "lg": "4xl" },
        "color": "text"
      },
      "h3": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "xl", "md": "xl", "lg": "2xl" },
        "color": "text"
      },
      "h4": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "lg", "lg": "xl" },
        "color": "text"
      },
      "h5": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": { "base": "md", "lg": "lg" },
        "color": "text"
      },
      "h6": {
        "fontFamily": "heading",
        "fontWeight": "semibold",
        "lineHeight": "shorter",
        "fontSize": "md",
        "color": "text"
      }
    }
  }
};

export default typography;
