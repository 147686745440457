import {
  CLEAR_PROPERTY_ENQUIRY_FORM,
  INVALIDATE_PROPERTY_ENQUIRY,
  POST_PROPERTY_ENQUIRY,
  RECEIVE_PROPERTY_ENQUIRY,
  SET_PROPERTY_ENQUIRY_DEFAULT_VALUES,
  SET_PROPERTY_ENQUIRY_FORM
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/constants';

export const initialState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  data: null,
  form: {},
  propertyId: null
};

function propertyEnquiryReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_PROPERTY_ENQUIRY:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
        data: null
      };
    case POST_PROPERTY_ENQUIRY:
      return {
        ...state,
        didInvalidate: false,
        error: null,
        isFetching: true
      };
    case RECEIVE_PROPERTY_ENQUIRY:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: action.data,
      };
    case SET_PROPERTY_ENQUIRY_DEFAULT_VALUES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: null,
        data: null,
        propertyId: action.propertyId,
        form: {
          ...state.form,
          ...action.form
        },

      };
    case SET_PROPERTY_ENQUIRY_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      };
    case CLEAR_PROPERTY_ENQUIRY_FORM:
      return {
        ...state,
        form: {}
      };
    default:
      return state;
  }
}

export default propertyEnquiryReducer;
