import React from 'react';
import { Global, css, useTheme } from '@emotion/react';

export const GlobalStyle = () => {
  const theme = useTheme();
  return (
    <Global styles={css`
      .bg-primary {
        background-color: ${theme['primary-color']};
      }
    
      .bg-secondary {
        background-color: ${theme['secondary-color']};
      }
    
      .bg-danger {
        background-color: ${theme['error-color']};
      }
    
      .fa-spin {
        animation: fa-spin 1s infinite linear;
      }
    
      .fa-primary {
        fill: ${theme['fa-icon-primary']};
        opacity: 0.8;
      }
    
      .fa-secondary {
        fill: ${theme['fa-icon-secondary']};
        opacity: 1;
      }
      
      style {
        display: none!important;
      }
      
      .react-images__view--isModal img {
        margin-right:auto;
        margin-left:auto;
      }
   
      // React dates
      .DayPicker__hidden {
        visibility: visible!important;
      }
      
      .DayPicker_transitionContainer__horizontal {
        min-height: 279px;
      }
      
      .DayPicker__verticalScrollable {
        height:710px;
      }
      
      .DayPickerNavigation_svg__vertical {
        margin-left: auto;
        margin-right: auto;
      }
      
      .DayPicker_weekHeader__verticalScrollable .DayPicker_weekHeader_ul {
        justify-content: center;
      }
      
      .DayPicker__horizontal, .DayPicker__vertical, .DayPicker, .CalendarMonthGrid, .CalendarMonthGrid_month__horizontal, .CalendarMonthGrid_month__vertical, .CalendarMonth,
      .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
        background: transparent!important;
      }
    
      .CalendarMonth_caption {
        strong {
          font-weight: ${theme['typography-font-weight-thick']};
          font-family: ${theme['font-family']}!important;
        }
      }
    
      .CalendarDay__default {
        border-color:white!important;
        border-radius: 7px!important;
        box-sizing: border-box !important;
        font-weight: ${theme['typography-font-weight-thick']}!important;
        color:${theme['calendar-default-color']}!important;
        background-color: ${theme['calendar-default-bg-color']}!important;
    
        &:hover {
          background-color: ${theme['calendar-default-hover-bg-color']}!important;
        }
    
        &.CalendarDay__outside {
          opacity:0.5;
        }
      }
    
    
    
      /*
      .CalendarDay__default:not(.CalendarDay__selected):not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_span):not(.CalendarDay__hovered_offset) {
        background-color: lighten($calendarPrimaryColor, 45%)!important;
        color:$calendarPrimaryColor!important;
        &:hover {
          background-color: lighten($calendarPrimaryColor, 40%)!important;;
        }
      }*/
    
      .CalendarDay__hovered_offset {
        background: ${theme['calendar-hovered-offset-bg-color']}!important; //background
        color: ${theme['calendar-hovered-offset-color']}!important; //text
        border: 1px double white!important; //default styles include a border
        &:hover {
          background: ${theme['calendar-hovered-offset-bg-color']}!important; //background
        }
      }
    
    
    
      .CalendarMonth_table {
        tr > td.CalendarDay.CalendarDay__hovered_offset:not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected):not(.CalendarDay__selected_span) {
          background: ${theme['calendar-selected-span-bg-color']}!important; //background
        }
      }
    
      .CalendarDay__selected_span { // Will edit everything selected including everything between a range of dates
        background: ${theme['calendar-selected-span-bg-color']}!important; //background
        color: ${theme['calendar-selected-span-color']}!important; //text
        border: 1px double white!important; //default styles include a border
        &:hover {
          background: ${theme['calendar-selected-span-bg-color']}!important; //background
        }
      }
    
      // Will edit the hovered part between start & end
      .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
        background: ${theme['calendar-hovered-offset-bg-color']}!important;
        border: 1px double white!important;
        color: ${theme['calendar-hovered-offset-color']}!important;
        &:hover {
          background: ${theme['calendar-hovered-offset-bg-color']}!important;
        }
      }
    
      // Will edit selected date or the endpoints of a range of dates
      .CalendarDay__selected, .CalendarDay__selected.CalendarDay__blocked_calendar {
        background: ${theme['calendar-selected-bg-color']}!important;
        color: ${theme['calendar-selected-color']}!important;
        &:hover {
          background: ${theme['calendar-selected-bg-color']}!important;
        }
      }
      
      @media(max-width: 992px) {
          .DayPicker_transitionContainer__verticalScrollable {
            height: 100% !important;
            padding-top: ${theme['padding-lg']};
          } 
      }
    
      .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        border-style: double!important;
      }
    
      .CalendarDay__blocked_out_of_range {
        color: ${theme['calendar-blocked-color']}!important;
        background: ${theme['calendar-blocked-bg-color']}!important;
        &:hover {
          background: ${theme['calendar-blocked-bg-color']}!important;
        }
      }
    
      .CalendarDay__blocked_calendar:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):not(.CalendarDay__blocked_arrival):not(.CalendarDay__blocked_departure),
      .CalendarDay__blocked_calendar:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):not(.CalendarDay__blocked_arrival):not(.CalendarDay__blocked_departure):active,
      .CalendarDay__blocked_calendar:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):not(.CalendarDay__blocked_arrival):not(.CalendarDay__blocked_departure):hover {
        background: ${theme['calendar-blocked-bg-color']}!important;
        color: ${theme['calendar-blocked-color']}!important;
      }
    
      .CalendarDay__blocked_arrival:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset),
       .CalendarDay__blocked_departure:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset) {
        background: white!important;
        text-decoration: line-through;
        color: ${theme['gray-6']}!important;
      }
    
      .CalendarDay__blocked_arrival:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):active,
      .CalendarDay__blocked_arrival:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):hover,
       .CalendarDay__blocked_departure:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):active,
      .CalendarDay__blocked_departure:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(.CalendarDay__hovered_offset):hover {
        background: white!important;
        color: ${theme['gray-6']}!important;
        cursor:not-allowed;
      }
    
      /*
      CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2 CalendarDay__lastDayOfWeek CalendarDay__lastDayOfWeek_3 CalendarDay__hovered_offset CalendarDay__hovered_offset_4
    
      CalendarDay CalendarDay_1 CalendarDay__default CalendarDay__default_2 CalendarDay__lastDayOfWeek CalendarDay__lastDayOfWeek_3 CalendarDay__hovered_offset CalendarDay__hovered_offset_4
    
      CalendarDay CalendarDay_1 CalendarDay__defaultCursor CalendarDay__defaultCursor_2 CalendarDay__default CalendarDay__default_3 CalendarDay__hovered_offset CalendarDay__hovered_offset_4 CalendarDay__blocked_calendar CalendarDay__blocked_calendar_5
      */
    
      .DayPicker_weekHeader_ul {
        display: flex!important;
      }
    
      .DayPickerNavigation_svg__vertical {
        height: 22px;
        width: 22px;
        margin-top: 11px;
      }
    
      .map-popup {
        z-index:1;
      }
      .mapboxgl-marker {
        &.active-pin {
          z-index: 999999!important;
        }
      }
  `}/>
 );
};

export const boxStyle = props => css`
  box-shadow: ${props.theme['box-shadow-lg']};
  border-radius: ${props.theme['border-radius-base']};
  background-color: white;
`;

export const boxStyleObj = {
  boxShadow: 'lg',
  borderRadius: 'md',
  bg: 'white'
};

export const imageBgStyleObj = {
  position: "relative",
  overflow: "hidden",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  img: {
    position: "absolute",
    minWidth: "102%",
    zIndex: 1,
    minHeight: "102%",
    width: "auto",
    height: "auto",
    top: "51%",
    left: "51%",
    OObjectFit: "cover",
    objectFit: "cover",
    WebkitTransform: "translate(-51%,-51%)",
    transform: "translate(-51%,-51%)"
  }
};
