import axios from 'axios';
import { window } from 'browser-monads';
import {
  selectApiLycanHostConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  INVALIDATE_RESERVATION,
  POST_RESERVATION,
  THREEDS_CHALLENGE
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { makeSelectPropertyReservationData } from '@rentivo/gatsby-core/src/containers/CommerceProvider/api/selectors';
import { startReceiveData } from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions';
import { selectCommerce } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { selectPricingPropertyId } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import {
  RESERVATION_TYPE_REQUEST_TO_BOOK,
  STATUS_ACCEPTED,
  STATUS_CONFIRMED, STATUS_PENDING
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import get from 'lodash/get';

const createReservation = store => next => action => {
  const { dispatch } = store;

  // Pass all actions through by default
  next(action);

  switch (action.type) {
    case POST_RESERVATION:

      const state = store.getState();
      const { app } = selectElasticSearchConfig(state);
      const host = selectApiLycanHostConfig(state);
      // const host = `http://192.168.1.13:8080`;// CLEANUP
      const siteName = selectSiteNameConfig(state);
      const { cart, chargeExchangeRate, cartTotalDueToday, cartTotalChargeDueToday, cartTotal, chargeCurrency } = selectCommerce(state);
      // const enquiryPropertyId = selectPropertyEnquiryPropertyId(state);
      const propertyId = selectPricingPropertyId(state);
      // const propertyId = 'b096d8a0-3b97-46c9-b9bf-b19ddc41f87a'; // CLEANUP
      const { reservationType, token, browserInfo, pg } = action;
      const { gatewayId, currency } = pg || {};
      
      const reservationData = makeSelectPropertyReservationData({...state, reservationType, token, browserInfo });

      const extraParams = new URLSearchParams(window.location.search.substring(1));
      const forceError = extraParams.get('forceError');

      let endpoint = `${host}/api/listings/${propertyId}/reservation${(forceError) ? '?forceError=' + forceError : ''}`;

      if(gatewayId) {
        endpoint = `${host}/api/listings/${propertyId}/reservation?redirectUrl=${btoa(encodeURI(window.location.href))}&gatewayId=${gatewayId}&preferredCurrency=${currency}&${(forceError) ? 'forceError=' + forceError : ''}`;
      }

      //next({type: RECEIVE_RESERVATION});

      /*
      next({type: RECEIVE_RESERVATION});
      console.log({reservationData});
      setTimeout(() => {

        dispatch(startReceiveData({
          reservationData,
          reservationType,
          cart,
          chargeExchangeRate,
          cartTotalDueToday,
          cartTotalChargeDueToday,
          cartTotal,
          chargeCurrency
        }));
      }, 3000);
      */

      axios({
        method: 'post',
        url: endpoint,
        data: reservationData,
        headers: {
          'Accept': 'application/json',
          'X-App': `Rentivo Gatsby3: ${siteName}`,
          //'X-Index': app,
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      }).then(({ status, data }) => {
        console.log('debug', status, data);
        const { code, errors } = data;
        
        if(
          (status === 200 || status === 201) &&
          (data.reservationStatus === STATUS_CONFIRMED || data.reservationStatus === STATUS_ACCEPTED || (
              reservationType === RESERVATION_TYPE_REQUEST_TO_BOOK && data.reservationStatus === STATUS_PENDING
          ))
        ) {
          console.log('debug', {
            reservationData: {
              ...reservationData,
              ...data
            },
            reservationType,
            cart,
            chargeExchangeRate,
            cartTotalDueToday,
            cartTotalChargeDueToday,
            cartTotal,
            chargeCurrency
          })
          
          dispatch(startReceiveData({
            reservationData: {
              ...reservationData,
              ...data
            },
            reservationType,
            cart,
            chargeExchangeRate,
            cartTotalDueToday,
            cartTotalChargeDueToday,
            cartTotal,
            chargeCurrency
          }));
        } else {

          if(data.reservationStatus === 'STATUS_PENDING_PAYMENT' && data.payments.length) {

            const [failedPayment] = data.payments.filter(p => p.status === 'STATUS_FAILED');
            if(failedPayment) {

              const errorMessage = (
                failedPayment.transactions &&
                failedPayment.transactions.length &&
                failedPayment.transactions[failedPayment.transactions.length - 1].message)
                ? failedPayment.transactions[failedPayment.transactions.length - 1].message : 'Unknown card error';

              next({
                type: INVALIDATE_RESERVATION,
                error: `Sorry, we have been unable to process your payment of the amount ${failedPayment.amount} ${failedPayment.currency} with a reason of: ${errorMessage}.
                
                If this persists, please contact us quoting your reservation ID of: ${data.id}`
              });

            }
            const hasPaymentBeenChallenged = get(data, 'payments.0.transactions.0.response.pending', false);
            const transactionToken = get(data, 'payments.0.transactions.0.token', null);
            
            if(hasPaymentBeenChallenged) {
              next({type: THREEDS_CHALLENGE, transactionToken, potentialData: {
                reservationData: {
                  ...reservationData,
                  ...data
                },
                reservationType,
                cart,
                chargeExchangeRate,
                cartTotalDueToday,
                cartTotalChargeDueToday,
                cartTotal,
                chargeCurrency
              }});
            } else {
              const errorMessage = `Payment failed: ${get(data, 'payments.0.transactions.0.response.transaction.message', `We're sorry, we are unable to complete your reservation due to an internal server error. Please try again in a few minutes.`)}`;
              next({type: INVALIDATE_RESERVATION, error: errorMessage});
            }


          } else {
            if(errors && errors.length) {
              next({type: INVALIDATE_RESERVATION, error: errors});
            } else {
              next({type: INVALIDATE_RESERVATION, error: `We're sorry, we are unable to complete your booking due to an internal server error. Please try again in a few minutes.`});
            }
          }
        }

        /*
        if(code >= 200 && code < 300) {
          dispatch(startReceiveData({
            reservationData: {
              ...reservationData,
              ...data
            },
            reservationType,
            cart,
            chargeExchangeRate,
            cartTotalDueToday,
            cartTotalChargeDueToday,
            cartTotal,
            chargeCurrency
          }));
        } else {
          if(errors && errors.length) {
            next({type: INVALIDATE_RESERVATION, error: errors});
          } else {
            next({type: INVALIDATE_RESERVATION, error: 'Could not send reservation'});
          }
        }*/

      }).catch((error) => {
        const { status, data } = error.response || {};
        const { code, errors } = data || {};
        if(errors && errors.length) {
          next({type: INVALIDATE_RESERVATION, error: errors});
        } else {
          // TODO: On Syncronous reservations, a 500 server error could be a problem if the REMOTE PROVIDER has actually confirmed the error but Lycan returned a 500 due to internal issue. (ie LeaveTown)
          next({type: INVALIDATE_RESERVATION, error: `We're sorry, we are unable to complete your booking due to an internal server error. Please try again in a few minutes.`});
        }
      });

      break;

    // Do nothing if the action does not interest us
    default:
      break
  }
};

export default createReservation;
