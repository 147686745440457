import React from 'react';
import { throttle } from 'lodash';
import { extendTheme } from '@chakra-ui/react';
import rentivoTheme from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme';
import {
  ThemeProvider as ChakraThemeProvider,
  CSSReset,
  GlobalStyle,
  PortalManager,
} from '@chakra-ui/react';
import { ThemeProvider } from '@emotion/react'; // minus
import { getBreakpoint } from '@rentivo/gatsby-core/src/containers/ThemeProvider/utils';
import {
  DEFAULT_DEVICE,
  DEVICE_COMPUTER,
  DEVICE_MOBILE,
  DEVICE_TABLET
} from '@rentivo/gatsby-core/src/containers/ThemeProvider/constants';
import { ResponsiveContext } from '@rentivo/gatsby-core/src/context/ResponsiveContext';
import deepmerge from 'deepmerge';

class ThemeProviderComponent extends React.Component {

  constructor(props) {
    super(props);
    const { theme, chakraTheme } = this.props;
    const responsive = getBreakpoint(theme);
    this.chakraTheme = extendTheme(deepmerge(rentivoTheme, chakraTheme));

    this.state = {
      ...responsive,
      agentDevice: this.getDevice() || DEFAULT_DEVICE
    };
  }

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('resize', throttle(this.onResize));
    this.onResize();
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { theme } = this.props;
    const { breakpoint: currentBreakpoint, } = this.state;
    const responsive = getBreakpoint(theme);
    const { breakpoint } = responsive;

    if (breakpoint !== currentBreakpoint) {
      this.setState(responsive);
    }
  };

  getDevice() {
    const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : null;
    if (userAgent) {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(userAgent)) return DEVICE_TABLET;
      if (/Mobile|iPhone|Android/.test(userAgent)) return DEVICE_MOBILE;
      return DEVICE_COMPUTER;
    }
    return DEFAULT_DEVICE; // SSR assume tablet
  }

  render() {
    const { children, theme } = this.props;
    const mergedTheme = { ...theme, responsive: this.state }; // TODO: Do we want to do this??

    return (
      <ChakraThemeProvider theme={this.chakraTheme}>
        <CSSReset />
        <GlobalStyle />
        <PortalManager zIndex={1000}>
          <ThemeProvider theme={mergedTheme}>
            <ResponsiveContext.Provider value={this.state}>
              {children}
            </ResponsiveContext.Provider>
          </ThemeProvider>
        </PortalManager>
      </ChakraThemeProvider>
    );
  }
}

export default ThemeProviderComponent;
