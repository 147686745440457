import Badge from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/badge';

export default {
  defaultProps: {
    borderRadius: 'full'
  },
  variants: {
    subtle: (props) => ({
      container: Badge.variants.subtle(props)
    })
  }
}
