import { createBreakpoints } from '@chakra-ui/theme-tools';

/*
const browserContext = 16;
const ViewportWidthSmall = 576;
const ViewportWidthMedium = 768;
const ViewportWidthLarge = 1281;
const ViewportWidthXLarge = 1499;

const breakpoints = createBreakpoints({
  base: `${ViewportWidthLarge / browserContext}em`,
  sm: `${ViewportWidthSmall / browserContext}em`,
  md: `${ViewportWidthMedium / browserContext}em`,
  lg: `${ViewportWidthLarge / browserContext}em`,
  xl: `${ViewportWidthXLarge / browserContext}em`
});*/
/*
const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em"
});
*/

const breakpoints = {
  "sm": "36em",
  "md": "48em",
  "lg": "62em",
  "xl": "75em",
  "xxl": "100em"
};

export default breakpoints;

/*
"sm": "576px",
"md": "768px",
"lg": "992px",
"xl": "1200px",
"xxl": "1600px"
 */
