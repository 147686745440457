import React from 'react';
import { window } from 'browser-monads';

export const calculateCartTotal = (products, field = 'fullPrice') => {
  return products.reduce((total, product) => {
    return total + ( product[field] * product.quantity );
  }, 0);
};

export const getStepsAndSanitizeChildren = (children, locale, defaultLocale, routePaths) => {
  if(!children) return null;

  const basePath = (locale !== defaultLocale) ? `/${locale}/${routePaths.ROUTE_BASE_CHECKOUT}` : `/${routePaths.ROUTE_BASE_CHECKOUT}`;

  children = (children && Array.isArray(children)) ? children : (children) ? [children] : [];
  let childRoutes = children.filter(child => child.props.path && child.props.name);

  let success = children.filter(child => child.props.success);
  const successComponent = (success.length) ? success[0] : null;
  if(successComponent) {
    childRoutes.push(successComponent);
  }

  let notFound = children.filter(child => child.props.default);
  const notFoundComponent = (notFound.length) ? notFound[0] : null;

  let invalid = children.filter(child => child.props.invalid);
  const invalidComponent = (invalid.length) ? invalid[0] : null;

  let currentStep = 0;

  const steps = childRoutes.map((child, i) => {
    const path = `${basePath}/${child.props.path.replace(/^\/|\/$/g, '')}`;
    if(window.location.pathname === path || window.location.pathname.includes(path)) currentStep = i;

    return {
      name: null,
      description: null,
      ...child.props,
      path
    };
  });

  const sanitizedChildRoutes = childRoutes.map((child, i) => {
    return <React.Fragment key={child.props.path}>{React.cloneElement(child, {...child.props, path: `${basePath}/${child.props.path.replace(/^\/|\/$/g, '')}`, stepIndex: i}, null)}</React.Fragment>;
  });

  if(notFoundComponent) sanitizedChildRoutes.push(notFoundComponent);

  return {
    steps,
    currentStep,
    sanitizedChildRoutes,
    invalidComponent
  }
};

export const addPathsToChildren = (children, paths) => {
  if(!children) return null;
  children = (children && Array.isArray(children)) ? children : (children) ? [children] : [];
  children = children.filter(child => child.props.id && paths[child.props.id]);
  children = children.map((child, i) => {
    return <React.Fragment key={child.props.id}>{React.cloneElement(child, {...child.props, path: paths[child.props.id], stepIndex: i}, null)}</React.Fragment>;
  });
  return children;
};


export const addBasePathToCheckoutPaths = (checkoutPaths, basePath, locale, defaultLocale) => {
  const keys = Object.keys(checkoutPaths);

  const localePath = (locale !== defaultLocale) ? `${locale}/` : ``;
  for (const key of keys) {
    let trimmedPath = checkoutPaths[key].replace(/^\/|\/$/g, '');
    trimmedPath = (trimmedPath) ? `/${trimmedPath}` : ``;
    checkoutPaths[key] = `${localePath}${basePath}${trimmedPath}`;
  }
  return checkoutPaths;
};


export const getCurrentStep = (checkoutPaths, uri, basePath, locale, defaultLocale) => {
  const keys = Object.keys(checkoutPaths);
  if(!uri) return keys[0];
  const localePath = (locale !== defaultLocale) ? `${locale}/` : ``;
  const strippedUri = uri.replace(`${localePath}${basePath}`, '').replace(/^\/|\/$/g, '');

  for (const key of keys) {
    if(checkoutPaths[key] === '' && strippedUri === '') return key;
    if(strippedUri === checkoutPaths[key]) return key;
  }

  return keys[0];
};
