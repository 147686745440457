import layerStyles from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/layerStyles';
import breakpoints from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/breakpoints';
import colors from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/colors';
import typography from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/typography';
import borders from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/borders';
import radii from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/radii';
import shadows from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/shadows';
import sizes from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/sizes';
import transition from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/transition';
import zIndices from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/zIndices';
import { spacing } from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/spacing';
import styles from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/foundation/styles';

const foundation = {
  colors,
  borders,
  breakpoints,
  radii,
  shadows,
  space: spacing,
  sizes,
  transition,
  zIndices,
  ...typography,
  styles,
  layerStyles
};

export default foundation;
