import axios from 'axios';
import { isEqual } from 'lodash';
import {
  INVALIDATE_DATA,
  RECEIVE_DATA,
  REQUEST_DATA,
  SET_QUERY
} from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import { selectElasticSearchConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { selectSiteNameConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  buildSearchAggs,
  buildSearchFunctions, buildSearchObjects,
  buildSearchQuery,
  buildSearchSort,
  getFrom
} from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/query';
import { selectSearchFilters, selectSearchQuery } from '@rentivo/gatsby-core/src/containers/SearchProvider/selectors';

const fetchSearchData = store => next => action => {

  // Pass all actions through by default
  next(action);

  switch (action.type) {
    case SET_QUERY:
      // Do stuff
      const state = store.getState();
      const siteName = selectSiteNameConfig(state);
      const elasticSearch = selectElasticSearchConfig(state);
      const filters = selectSearchFilters(state);
      const previousFinalQuery = selectSearchQuery(state);

      const { app, credentials, url } = elasticSearch || {}; // size = 12
      if(!elasticSearch || !app || !credentials || !url) {
        next({type: INVALIDATE_DATA, error: 'Missing elastic search config options'});
        break;
      }

      // TODO: Clean this up into one function that loops through and pulls them out
      const rawQuery = buildSearchQuery(state.search.initialQuery, filters);
      let { query } = rawQuery;
      const { force } = rawQuery;
      let aggs = buildSearchObjects('aggs', state.search.initialAggs, filters);
      let script_fields = buildSearchObjects('scripts', state.search.initialScripts, filters);
      let sort = buildSearchSort(state.search.initialSort, filters);
      let functions = buildSearchFunctions(state.search.initialFunctions, filters);
      let from = getFrom(0, filters);
      let extraOptions = {
        from,
        track_total_hits: state.search.totalHits,
        size: state.search.perPage
      };

      sort = (sort && sort.length) ? [...sort] : undefined;
      functions = (functions && functions.length) ? functions : undefined;
      query = (functions && functions.length) ? {
        function_score: {
          functions,
          query,
          score_mode: "sum"
        }
      } : query;

      const _source = {
        "excludes": ["pricing.los"]
      };

      let finalQuery = {
        ...extraOptions,
        sort,
        aggs,
        script_fields,
        query,
        _source,
        force
      };

      // Check if different to previous query..., otherwise do nothing
      if(isEqual(finalQuery, previousFinalQuery)) {
        break;
      }

      next({type: REQUEST_DATA, query: finalQuery});

      const cleanedQuery = Object.assign({}, finalQuery);
      delete cleanedQuery.force;

      const searchEndpoint = `${url}/${app}/_msearch`;

      console.log('Running elastic search query');

      axios({
        method: 'post',
        baseURL: searchEndpoint,
        data: '{}\n' + JSON.stringify(cleanedQuery) + '\n',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-ndjson',
          'X-App': siteName,
          'Authorization': 'Basic ' + btoa(credentials),
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      }).then((resp) => {
        const data = resp.data;
        next({type: RECEIVE_DATA, data});
      }).catch((error) => {
        console.log({error: error.message});
        next({type: INVALIDATE_DATA, error: error.message});
      });

      break;

    // Do nothing if the action does not interest us
    default:
      break
  }
};

export default fetchSearchData;
