import { textStyles } from '@rentivo/gatsby-core/src/containers/ThemeProvider/theme/components/text';

export default {
  variants: {
    default: ({as, displayAs, theme: { typography: { heading } }, ...props}) => ({
      ...textStyles(heading[displayAs ? displayAs : as], props)
    }),
    writing: ({as, displayAs, theme: { typography: { writingHeading } }, ...props}) => {
      return {
        ...textStyles(writingHeading[displayAs ? displayAs : as], props)
      };
    }
  },
  defaultProps: {
    variant: "default",
    size: null
  }
}
