import { createSelector } from 'reselect';
import { initialState } from './reducer';
import get from 'lodash/get';

export const selectLanguage = state => state.language ? state.language : initialState;
export const selectLang = state => get(selectLanguage(state), 'lang', 'en');
export const selectLocale = state => get(selectLanguage(state), 'locale', 'en_GB');
export const selectLanguages = state => get(selectLanguage(state), 'languages', ['en']);
export const selectLocales = state => get(selectLanguage(state), 'locales', [{lang: 'en', locale: 'en_GB'}]);
export const selectLocalesOnly = state => selectLocales(state).map(({locale}) => locale);
export const selectDefaultLang = state => get(selectLanguage(state), 'defaultLang', 'en');
export const makeSelectDefaultLang = () => createSelector(selectDefaultLang, state => state);
export const selectMatchAllAppLocalesRegex = state => get(selectLanguage(state), 'matchAllAppLocalesRegex', null);
export const selectTranslations = state => get(selectLanguage(state), 'translations', null);
export const selectUri = state => get(selectLanguage(state), 'uri', null);
