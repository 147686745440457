
import Cookies from 'js-cookie';
import { selectTrackingConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const timeoutLength = 32; //https://github.com/gatsbyjs/gatsby/commit/42f509eadb06753f7b529f3682f22e012c21dc9b#diff-bf0d94c8bf47d5c1687e342c2dba1e00R31
const currentEnvironment = process.env.ENV || process.env.NODE_ENV || 'development';

export const TRACKING_TYPES = {
  analytics: {
    cookieName: 'gdpr-analytics-enabled'
  },
  marketing: {
    cookieName: 'gdpr-marketing-enabled'
  }
};

export const defaultOptions = {
  environments: ['production'],
  debug: false,
  googleTagManager: {
    type: 'analytics',
    active: false,
    autoStart: true,
    dataLayerName: 'dataLayer',
    defaultDataLayer: {
      platform: "rentivo"
    },
    routeChangeEventName: 'pageview',
    controlCookieName: TRACKING_TYPES.analytics.cookieName,
    cookieFlags: 'secure;samesite=none'
  },
  googleAnalytics: {
    type: 'analytics',
    active: false,
    head: true,
    anonymize: true,
    controlCookieName: TRACKING_TYPES.analytics.cookieName,
    autoStart: true,
    cookieFlags: 'secure;samesite=none'
  },
  googleAds: {
    type: 'marketing',
    active: false,
    head: true,
    controlCookieName: TRACKING_TYPES.marketing.cookieName,
    anonymize: true,
    cookieFlags: 'secure;samesite=none'
  },
  hotjar: {
    type: 'analytics',
    active: false,
    head: true,
    controlCookieName: TRACKING_TYPES.analytics.cookieName,
  },
  facebookPixel: {
    type: 'marketing',
    active: false,
    head: true,
    autoStart: true,
    controlCookieName: TRACKING_TYPES.marketing.cookieName
  }
};

export const onClientEntry = (_, pluginOptions) => {

  window.isClient = true;

  const tracking  = selectTrackingConfig(pluginOptions.state);
  if(!tracking) return null;

  const { environments = defaultOptions.environments, hotjar, facebookPixel, debug, googleTagManager } = tracking || {};

  if (debug) {
    console.log("onClientEntry - currentEnvironment:", currentEnvironment);
  }

  // check for the production environment
  if (!environments.includes(currentEnvironment)) {
    if (debug) {
      console.log("onClientEntry - abort tracking since the environment is to configured. environments: ", environments);
    }
    return null;
  }

  const hotjarOpt = {...defaultOptions.hotjar, ...hotjar};

  if(Cookies.get(hotjarOpt.controlCookieName) !== "false") {
    if (typeof window.trackHotjar === `function` && Cookies.get(hotjarOpt.controlCookieName) === 'true') {
      if (debug) {
        console.log(`onClientEntry - Cookies.get('${hotjarOpt.controlCookieName}') is true ==> start hotjar tracking`);
      }
      window.trackHotjar();
    }
  }

  if (typeof window.gtag === `function`) {
    window.gaLoaded = false;

    if (debug) {
      console.log(`onClientEntry - gtag function is defined. gaLoaded=${gaLoaded}`);
    }
  }

  /*
  if (typeof window.gtag === `function`) { // TODO, need to detect GTM...
    window.gtmLoaded = false;

    if (debug) {
      console.log(`onClientEntry - gtm function is defined. gaLoaded=${gtmLoaded}`);
    }
  }*/

  const facebookOpt = {...defaultOptions.facebookPixel, ...facebookPixel};

  if (typeof window.fbq === `function` && Cookies.get(facebookOpt.controlCookieName) === 'true') {
    if (debug) {
      console.log(`onClientEntry - Cookies.get('${hotjarOpt.controlCookieName}') is true ==> start facebook tracking`);
    }
    window.fbq(`init`, facebookOpt.trackingId);
    window.facebookLoaded = true;
  }

};

export const onRouteUpdate = ({location}, pluginOptions) => {

  const tracking  = selectTrackingConfig(pluginOptions.state);
  if(!tracking) return null;

  const { environments = defaultOptions.environments, googleAnalytics, googleAds, facebookPixel, googleTagManager, debug } = tracking || {};

  if (debug) {
    console.log("onRouteUpdate - currentEnvironment:", currentEnvironment);
  }

  // check for the production environment
  if (!environments.includes(currentEnvironment)) {
    if (debug) {
      console.log("onRouteUpdate - abort tracking since the environment is to configured. environments: ", environments);
    }
    return null;
  }

  const googleTagManagerOpt = {...defaultOptions.googleTagManager, ...googleTagManager};
  const facebookOpt = {...defaultOptions.facebookPixel, ...facebookPixel};
  const googleAnalyticsOpt = {...defaultOptions.googleAnalytics, ...googleAnalytics};
  const googleAdsOpt = {...defaultOptions.googleAds, ...googleAds};

  if (debug) {
    console.log(`onRouteUpdate - start tracking functions definitions`);
  }

  // GTM
  window.trackGoogleTagManager = () => {

    const { dataLayerName, routeChangeEventName } = googleTagManagerOpt;

    if (debug) {
      console.log(`onRouteUpdate - inside trackGoogleTagManager function googleTagManager options:`, googleAnalyticsOpt);
    }

    if(Cookies.get(googleAnalyticsOpt.controlCookieName) !== "false") {
      if ((googleAnalyticsOpt.autoStart || Cookies.get(googleAnalyticsOpt.controlCookieName) === "true") && googleAnalyticsOpt.trackingId && Array.isArray(window[dataLayerName])) {
        const data = dataLayerName ? window[dataLayerName] : window.dataLayer;
        const eventName = routeChangeEventName ? routeChangeEventName : `pageChange`;
        data.push({ event: eventName });

        if (debug) {
          console.log(`onRouteUpdate - inside trackGoogleTagManager - track page view for path: `, location.pathname); // TODO: Do we need to send the event?
        }

      }
    }
  };

  // Facebook
  window.trackFacebookPageView = () => {
    if (debug) {
      console.log(`onRouteUpdate - inside trackFacebookPageView function facebookPixel options:`, facebookOpt);
    }

    if(Cookies.get(facebookOpt.controlCookieName) !== "false") {
      if ((facebookOpt.autoStart || Cookies.get(facebookOpt.controlCookieName) === "true") && facebookOpt.trackingId && window.fbq === `function`) {
        window.fbq(`track`, `PageView`);
      }
    }
  };

  // Google Analytics
  window.trackGoogleAnalytics = () => {
    if (debug) {
      console.log(`onRouteUpdate - inside trackGoogleAnalytics function googleAnalytics options:`, googleAnalyticsOpt);
    }
    if(Cookies.get(googleAnalyticsOpt.controlCookieName) !== "false") {
      if ((googleAnalyticsOpt.autoStart || Cookies.get(googleAnalyticsOpt.controlCookieName) === "true") && googleAnalyticsOpt.trackingId) {
        if (debug) {
          console.log(`onRouteUpdate - inside trackGoogleAnalytics function - tracking is active!!`);
        }

        if (!window.gaLoaded && typeof window.gtag === `function`) {
          if (debug) {
            console.log(`onRouteUpdate - initialize gtag with Date (gtag('js', new Date()))`);
          }
          gtag('js', new Date());
          window.gaLoaded = true;
        }

        if (debug) {
          console.log(`onRouteUpdate - gaLoaded:`, window.gaLoaded);
        }

        if (debug) {
          console.log(`onRouteUpdate - inside trackGoogleAnalytics - track page view for path: `, location.pathname);
        }

        if (typeof window.gtag === `function`) {

          gtag('config', googleAnalyticsOpt.trackingId, {
            'anonymize_ip': googleAnalyticsOpt.anonymize.toString(),
            'page_path': location.pathname,
            'cookie_flags': googleAnalyticsOpt.cookieFlags
          });

        } else {

          if (debug) {
            console.log(`onRouteUpdate - inside trackGoogleAnalytics - gTag is not defined: `, location.pathname);
          }

        }

      } else {
        if (debug) {
          console.log(`onRouteUpdate - inside trackGoogleAnalytics function - tracking is disabled!!`);
        }
      }
    } else {
      if (debug) {
        console.log(`onRouteUpdate - inside trackGoogleAnalytics function - tracking is disabled!!`);
      }
    }
  };

  window.trackGoogleAnalyticsEvent = (category, params) => {
    if ((googleAnalytics.autoStart || Cookies.get(googleAnalyticsOpt.controlCookieName) === "true") && googleAnalyticsOpt.trackingId) {
      if (debug) {
        console.log(`onRouteUpdate - inside trackGoogleAnalyticsEvent function - tracking is active!!`);
      }
      gtag('event', category, params);
    } else {
      if (debug) {
        console.log(`onRouteUpdate - inside trackGoogleAnalyticsEvent function - tracking is disabled!!`);
      }
    }
  };


  // - Google Ads pixel
  // check if the marketing cookie exists
  window.trackGoogleAds = () => {
    if(Cookies.get(googleAdsOpt.controlCookieName) !== "false") {
      if (Cookies.get(googleAdsOpt.controlCookieName) === "true" && googleAdsOpt.trackingId) {
        gtag('config', googleAdsOpt.trackingId, {
          'anonymize_ip': googleAdsOpt.anonymize.toString(),
          'page_path': location.pathname,
          'cookie_flags': googleAdsOpt.cookieFlags
        });
      }
    }
  };

  window.trackGoogleAdsEvent = (category, params) => {
    if(Cookies.get(googleAdsOpt.controlCookieName) !== "false") {
      if (Cookies.get(googleAdsOpt.controlCookieName) === "true" && googleAdsOpt.trackingId) {
        gtag('event', category, params);
      }
    }
  };


  if (debug) {
    console.log(`onRouteUpdate - call tracking functions`);
  }
  //setTimeout workaround to try to ensure the page title loads
  setTimeout(() => {
    window.trackGoogleTagManager();
    window.trackGoogleAnalytics();
    window.trackGoogleAds();
    window.trackFacebookPageView();
  }, timeoutLength);
};
