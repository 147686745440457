export default {
  parts: ['outer', 'inner', 'timing'],
  baseStyle: {
    timing: {
      lastsFor: 24
    },
    outer: {

    },
    inner: {
      py: 2,
      px: { base: 4 }
    }
  }
};
