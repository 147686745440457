import { get, isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import {
  selectSearchFilterConfigByKey,
  selectSearchFiltersConfig,
  selectSearchResultsPerPageConfig,
} from '@rentivo/gatsby-core/src/selectors/siteConfig'
import { nullOrUndefinedToEmptyArray } from '@rentivo/gatsby-core/src/utils/arrays'
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils'
import {
  selectPageContextHasUrlSearchQuery,
  selectPageContextSearchData,
} from '@rentivo/gatsby-core/src/selectors/pageContext'

// State ----
// --------------------
export const selectSearch = state => get(state, 'search', {})

export const selectSearchFirstLoad = state =>
  get(selectSearch(state), 'firstLoad', true)
export const makeSelectSearchFirstLoad = () =>
  createSelector(selectSearchFirstLoad, state => state)

export const selectSearchMapOpen = state =>
  get(selectSearch(state), 'mapOpen', false)

export const selectSearchLoadedDefaultFilters = state =>
  get(selectSearch(state), 'loadedDefaultFilters', false)
export const makeSelectSearchLoadedDefaultFilters = () =>
  createSelector(selectSearchLoadedDefaultFilters, state => state)

export const selectSearchIsFetching = state =>
  get(selectSearch(state), 'isFetching', false)
export const makeSelectIsFetching = () =>
  createSelector(selectSearchIsFetching, state => state)

export const selectSearchDidInvalidate = state =>
  get(selectSearch(state), 'didInvalidate', false)
export const makeSearchDidInvalidate = () =>
  createSelector(selectSearchDidInvalidate, state => state)

export const selectSearchError = state =>
  get(selectSearch(state), 'error', null)
export const makeSelectSearchError = () =>
  createSelector(selectSearchError, state => state)

export const selectSearchQuery = state =>
  get(selectSearch(state), 'query', null)

export const selectSearchFilters = state =>
  get(selectSearch(state), 'filters', [])
export const selectDefaultSearchFilters = state =>
  get(selectSearch(state), 'defaultFilters', [])
export const selectSearchFilterByKey = (filters, key) => {
  if (!filters || !filters.length) return null
  const matchingFilters = filters.filter(f => f.id === key)
  return matchingFilters.length ? matchingFilters[0] : null
}

export const makeSelectSearchFilters = (filterConfigKey = null) =>
  createDeepEqualSelector(selectSearchFilters, state => {
    if (!filterConfigKey) return state
    const isArray = Array.isArray(filterConfigKey) && filterConfigKey.length > 1
    const filtersMatchingUrlParam = isArray
      ? state.filter(f => filterConfigKey.includes(f.id))
      : state.filter(f => f.id === filterConfigKey)
    if (filtersMatchingUrlParam.length) {
      return isArray ? filtersMatchingUrlParam : filtersMatchingUrlParam[0]
    } else {
      return null
    }
  })

export const selectSearchFilterLabels = state =>
  get(selectSearch(state), 'filterLabels', {})
export const makeSelectSearchFilterLabels = () =>
  createSelector(selectSearchFilterLabels, state => state)

export const selectSearchOnMapMove = state =>
  get(selectSearch(state), 'searchOnMapMove', false)
export const makeSelectSearchOnMapMove = () =>
  createSelector(selectSearchOnMapMove, state => state)

export const selectSearchRawData = state =>
  get(selectSearch(state), 'data', null)

export const selectSearchDataAndHasQueryFromProps = (
  state,
  { pageContext }
) => ({
  hasUrlSearchQuery: selectPageContextHasUrlSearchQuery(pageContext),
  searchData: selectPageContextSearchData(pageContext),
})

export const makeSelectSearchData = () =>
  createDeepEqualSelector(
    [
      selectSearchRawData,
      selectSearchFirstLoad,
      selectSearchDataAndHasQueryFromProps,
    ],
    (rawData, firstLoad, searchDataAndHasQuery) => {
      const { hasUrlSearchQuery, searchData } = searchDataAndHasQuery
      const dataToUse =
        searchData && !hasUrlSearchQuery && firstLoad ? searchData : rawData

      // Logic which should only be run once!
      let data = {
        took: 0,
        timedOut: false,
        total: 0,
        hits: [],
        aggregations: {},
      }

      if (!isEmpty(dataToUse) && dataToUse.responses.length) {
        if (dataToUse.responses[0].status !== 200) return data
        const {
          took,
          timed_out: timedOut,
          hits: {
            total: { value: total },
            hits,
          },
          aggregations,
        } = dataToUse.responses[0]
        data = {
          took,
          timedOut,
          total,
          hits: hits.map(hit => {
            let availabilityPerc = null
            if (
              hit.sort &&
              Array.isArray(hit.sort) &&
              hit.sort.length > 0 &&
              typeof hit.sort[0] === 'number'
            ) {
              availabilityPerc = hit.sort[0]
            }
            return {
              ...hit._source,
              availabilityPerc,
              id: hit._id,
              fields: hit.fields,
            }
          }),
          aggregations,
        }
      }

      //console.log('running output selector!', data);
      return data
    }
  )

export const selectSearchHitsFromSearchData = searchData =>
  get(searchData, 'hits', [])
export const makeSelectSearchHits = () =>
  createSelector(makeSelectSearchData(), searchData =>
    selectSearchHitsFromSearchData(searchData)
  )

export const selectSearchAggregationsFromSearchData = searchData =>
  get(searchData, 'aggregations', {})
export const makeSelectSearchAggregations = () =>
  createSelector(makeSelectSearchData(), searchData =>
    selectSearchAggregationsFromSearchData(searchData)
  )

export const makeSelectSearchAggregationBuckets = field =>
  createSelector(makeSelectSearchData(), searchData =>
    get(
      get(selectSearchAggregationsFromSearchData(searchData), `${field}`, []),
      'buckets',
      []
    )
  )

export const selectSearchHitsTotalFromSearchData = searchData =>
  get(searchData, 'total', 0)
export const makeSelectSearchHitsTotal = () =>
  createSelector(makeSelectSearchData(), searchData =>
    selectSearchHitsTotalFromSearchData(searchData)
  )

export const makeSelectSearchHitsPages = () =>
  createSelector(
    makeSelectSearchData(),
    selectSearchResultsPerPageConfig,
    (searchData, perPage) => {
      const totalHits = selectSearchHitsTotalFromSearchData(searchData)
      return Math.floor(totalHits / perPage)
    }
  )

// Collection
export const selectSearchCollection = state =>
  get(selectSearch(state), 'collection', null)

export const selectPageContextCollectionFromProps = (state, ownProps) =>
  get(ownProps, 'pageContext.collection', null)

export const makeSelectSearchCollection = () =>
  createDeepEqualSelector(
    selectSearchCollection,
    selectPageContextCollectionFromProps,
    (collection, contextCollection) => {
      return contextCollection ? contextCollection : collection
    }
  )

export const selectSearchIdFromCollection = collection =>
  get(collection, 'id', null)
export const makeSelectSearchCollectionId = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchIdFromCollection(collection)
  )

export const selectSearchCollectionUri = collection =>
  get(collection, 'uri', null)
export const selectSearchCollectionSlug = collection =>
  get(collection, 'slug', null)
export const selectSearchCollectionTitle = collection =>
  get(collection, 'title', null)
export const selectSearchCollectionContent = collection =>
  get(collection, 'content', null)
export const selectSearchCollectionFeaturedImage = collection =>
  get(collection, 'featuredImage.node', null)
export const selectSearchCollectionPage = collection =>
  get(collection, 'page', null)

export const selectSearchPageTitleFromCollection = collection =>
  get(selectSearchCollectionPage(collection), 'pageTitle', null)
export const makeSelectSearchCollectionPageTitle = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchPageTitleFromCollection(collection)
  )

export const selectSearchPageSubtitleFromCollection = collection =>
  get(selectSearchCollectionPage(collection), 'pageSubtitle', null)
export const makeSelectSearchCollectionPageSubtitle = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchPageSubtitleFromCollection(collection)
  )

export const selectSearchSeoFromCollection = collection =>
  get(collection, 'seo', null)

export const selectSearchSeoTitleFromCollection = collection =>
  get(selectSearchSeoFromCollection(collection), 'seoTitle', null)
export const makeSelectSearchCollectionSeoTitle = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchSeoTitleFromCollection(collection)
  )

export const selectSearchSeoDescFromCollection = collection =>
  get(selectSearchSeoFromCollection(collection), 'seoMetaDescription', null)

export const selectSearchCollectionContentFromCollection = collection =>
  get(collection, 'content', null)

export const makeSelectSearchCollectionSeoDesc = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchSeoDescFromCollection(collection)
  )

export const selectSearchInfoFromCollection = collection =>
  get(collection, 'collection', {})

export const selectSearchInfoNameFromCollection = collection =>
  get(
    selectSearchInfoFromCollection(collection),
    'name',
    selectSearchCollectionTitle(collection)
  )
export const makeSelectSearchCollectionInfoName = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchInfoNameFromCollection(collection)
  )

export const selectSearchInfoUseCollectionRulesFromCollection = collection =>
  get(selectSearchInfoFromCollection(collection), 'useCollectionRules', false)
export const makeSelectSearchCollectionInfoUseCollectionRules = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchInfoUseCollectionRulesFromCollection(collection)
  )

export const selectSearchInfoInTreeFromCollection = collection =>
  get(selectSearchInfoFromCollection(collection), 'inTree', true)
export const makeSelectSearchCollectionInfoInTree = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchInfoInTreeFromCollection(collection)
  )

export const selectSearchInfoTypeFromCollection = collection =>
  get(selectSearchInfoFromCollection(collection), 'collectionType', 'mixed')
export const selectSearchInfoDestinationFromCollection = collection =>
  get(selectSearchInfoFromCollection(collection), 'destination', {})

export const selectSearchInfoDestinationNameFromCollection = collection =>
  get(selectSearchInfoDestinationFromCollection(collection), 'name', null)
export const makeSelectSearchCollectionInfoDestinationName = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchInfoDestinationNameFromCollection(collection)
  )

export const selectSearchInfoDestinationTypeFromCollection = collection =>
  get(selectSearchInfoDestinationFromCollection(collection), 'type', null)

export const selectSearchInfoDestinationBoundingBoxFromCollection = collection =>
  get(
    selectSearchInfoDestinationFromCollection(collection),
    'boundingBox',
    null
  )

export const selectSearchInfoFiltersFromCollection = collection =>
  get(selectSearchInfoFromCollection(collection), 'filters', [])

export const combineDestinationWithFilters = (filters, destination) => {
  filters = filters ? filters : []
  if (
    destination &&
    destination.name &&
    destination.boundingBox &&
    destination.boundingBox.coordinates
  ) {
    filters = filters.filter(f => f.filterKey !== 'location')
    filters.push({
      filterKey: 'location',
      filterValue: `${destination.name}~${destination.boundingBox.coordinates}`,
    })
  }
  return filters
}

export const makeSelectSearchCollectionInfoFilters = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    combineDestinationWithFilters(
      selectSearchInfoFiltersFromCollection(collection),
      selectSearchInfoDestinationFromCollection(collection)
    )
  )

export const selectSearchRelationshipsFromCollection = collection =>
  get(collection, 'relationships', {})

export const selectSearchChildrenFromCollection = collection =>
  nullOrUndefinedToEmptyArray(
    get(
      selectSearchRelationshipsFromCollection(collection),
      'elementChildren',
      []
    )
  )
export const makeSelectSearchCollectionChildren = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchChildrenFromCollection(collection)
  )

export const selectSearchParentsFromCollection = collection =>
  nullOrUndefinedToEmptyArray(
    get(
      selectSearchRelationshipsFromCollection(collection),
      'elementParents',
      []
    )
  )

export const makeSelectSearchCollectionParents = () =>
  createSelector(makeSelectSearchCollection(), collection =>
    selectSearchParentsFromCollection(collection)
  )

export const makeSelectSearchCollectionPageHeader = () =>
  createSelector(makeSelectSearchCollection(), collection => ({
    collectionName: selectSearchInfoNameFromCollection(collection),
    collectionDestinationName: selectSearchInfoDestinationNameFromCollection(
      collection
    ),
    useCollectionRules: selectSearchInfoUseCollectionRulesFromCollection(
      collection
    ),
    collectionPageTitle: selectSearchPageTitleFromCollection(collection),
    collectionPageSubtitle: selectSearchPageSubtitleFromCollection(collection),
    collectionSeoTitle: selectSearchSeoTitleFromCollection(collection),
    collectionSeoDesc: selectSearchSeoDescFromCollection(collection),
    collectionContent: selectSearchCollectionContentFromCollection(collection),
    collectionSlug: selectSearchCollectionSlug(collection),
  }))

export const makeSelectSearchCollectionTree = () =>
  createSelector(makeSelectSearchCollection(), collection => ({
    showTree: selectSearchInfoInTreeFromCollection(collection),
    collectionName: selectSearchInfoNameFromCollection(collection),
    children: selectSearchChildrenFromCollection(collection),
    parents: selectSearchParentsFromCollection(collection),
  }))
