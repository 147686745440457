import { isNumeric } from '@rentivo/gatsby-core/src/utils/numbers';

export const numericStringToNumber = (string) => {
    return (isNumeric(string)) ? parseFloat(string) : `${string}`;
};

export const stringToFloat = (string) => {
    if(!string) return null;
    return parseFloat(string);
};

export const uuidV4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
};

export const uriDecode = (str) => {
    try {
        return decodeURIComponent(str);
    } catch (e) {
        return str;
    }
};

export const stringContains = (string, contains) => string && typeof string === 'string' ? string.indexOf(contains) > -1 : false;

export const isValidJSON = (string) => (/^[\],:{}\s]*$/.test(string.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')));

export const stringToInt = (string) => {
    if(!string) return null;
    return parseInt(string);
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    const l = s.toLowerCase();
    return l.charAt(0).toUpperCase() + l.slice(1);
};

export const newlinesToBr = (str) => {
    if(!str) return null;
    return str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};

export const trimLastSlash = (string) => {
    if(string === '/') return '/';
    return string.replace(/\/$/, '');
};

export const trimFirstSlash = (string) => {
    return string.replace(/^\/+/g, '');
};

export const trimFirstAndLastSlash = (string) => {
    return string.replace(/^\/|\/$/g, '');
};

export const parseFormattedMessage = (message) => {
    if(!message) return null;
    if(message === ' ' || message === '&nbsp;') return null;
    return message;
};

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '0, 0, 0';
};

export const areStringsSimilar = (string1, string2) => {
    if(string1 && string2) {
        return (string1.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "") === string2.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ""));
    } else {
        return (string1 === string2);
    }
}; // TODO; Remove spaces too

export const commaSepToArray = str => {
    if(str) {
        return str.split(/[\s,]+/);
    } else {
        return [];
    }
};

export const makeId = (length = 5) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const convertStringPixelToNumber = (stringOrNumber) => {
    stringOrNumber = `${stringOrNumber}`;
    return parseInt(stringOrNumber.replace('px', ''));
};

export const slugToTitle = (slug) => {
    let words = slug.split(/[- _]+/);

    for(let i = 0; i < words.length; i++) {
        const word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
};

export const removePx = (string) => parseInt(string, 10);

export const stripHtmlRegex = (html) => {
    if(!html) return '';
    return html.replace(/(<([^>]+)>)/gi, "");
};

export const hasHtml = (string) => /<.+>/g.exec( string );

export const replaceStringWithVariable = (string, variables = {}) => {
    const replaceArray = Object.keys(variables);
    const replaceWith = Object.values(variables);
    for(let i = 0; i < replaceArray.length; i++) {
        string = (string && typeof string === 'string') ? string.replace(new RegExp('{' + replaceArray[i] + '}', 'gi'), replaceWith[i]) : string;
    }
    return string;
};

export const truncate = (input, length = 200, ellipse = '...') => {
    if (input.length > length) {
        return input.substring(0, length) + ellipse;
    }
    return input;
};

export const truncateWords = (str, max = 25, ellipsis = '...') => {
    const array = str.trim().split(' ');
    ellipsis = array.length > max ? '...' : '';
    return array.slice(0, max).join(' ') + ellipsis;
};

export const decodeHTMLEntities = (string) => {
    const entities = {
        'amp': '&',
        'apos': '\'',
        '#x27': '\'',
        '#x2F': '/',
        '#39': '\'',
        '#47': '/',
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'quot': '"',
        '#038': '&',
        '#8217': "'",
        '#039': "'"
    };

    return string.replace(/&([^;]+);/gm, function (match, entity) {
        return entities[entity] || match;
    });
};

export const lycanizeString = (string) => {
    if(!string || typeof string !== 'string') return '';
    return string.replace(/ /g,"_").toUpperCase();
};

export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}