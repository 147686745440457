import { defineMessages } from 'react-intl';

export const scope = 'components.generic.CookiePopup';

export default defineMessages({
  cookiePopupText: {
    id: `${scope}.cookiePopup.text`,
    defaultMessage: 'By using this website, you agree to our <modal>cookie policy</modal>.',
  },
  cookiePopupOkButton: {
    id: `${scope}.cookiePopup.ok.button`,
    defaultMessage: 'Ok'
  },
  cookieModalTitle: {
    id: `${scope}.cookieModal.title`,
    defaultMessage: 'Cookie preferences'
  },
  cookieModalDescription: {
    id: `${scope}.cookieModal.description`,
    defaultMessage: 'You can set your privacy preferences using the controls below. These can be changed at any time by visiting Your Privacy Controls. Find out more about how we use your information in our <policy>Cookie Policy</policy>.'
  },
  cookieModalOkButton: {
    id: `${scope}.cookieModal.okButton`,
    defaultMessage: 'Save Changes'
  },
  cookieModalCancelButton: {
    id: `${scope}.cookieModal.cancelButton`,
    defaultMessage: 'Cancel'
  }
});
