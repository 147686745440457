export default {
  boxed: {
    bg: "#ffffff",
    border: "1px solid",
    borderColor: "gray.200",
    boxShadow: "md"
  },
  filled: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  highlight: {
    color: "secondary.500"
  },
  impactLink: {
    color: "primary.500",
    fontWeight: "semibold",
    borderBottom: "2px solid",
    borderColor: "primary.500",
    "_hover": {
      color: "primary.700",
      textDecoration: "none"
    }
  },
  coverImage: {
    position: "relative",
    overflow: "hidden",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "img": {
      position: "absolute",
      minWidth: "102%",
      minHeight: "102%",
      zIndex: 1,
      width: "auto",
      height: "auto",
      top: "51%",
      left: "51%",
      objectFit: "cover",
      transform: "translate(-51%, -51%)"
    }
  },
  /*
  coverImage: {
    position: "absolute",
    minWidth: "101%",
    minHeight: "101%",
    zIndex: 1,
    width: "auto",
    height: "auto",
    top: "50.5%",
    left: "50.5%",
    objectFit: "cover",
    transform: "translate(-50%, -50%)"
  }*/
}
