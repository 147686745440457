import { uuidV4 } from '@rentivo/gatsby-core/src/utils/strings';

export function getBaseReservationData({stayBreakdown, siteName, chargeCurrency}) {
  return {
    reservationId: uuidV4(), // reservationId sent to Lycan, expectation is that Lycan will use this as a marketplace supplied ID and will not associate new ID's with existing reservations on Lycan
    bookingSource: siteName,
    bookingCurrency: chargeCurrency,
    arrivalDate: stayBreakdown.arrivalDate,
    departureDate: stayBreakdown.departureDate,
    adults: stayBreakdown.adults,
    children: stayBreakdown.children,
    infants: stayBreakdown.infants,
    pets: stayBreakdown.pets
  };
}
