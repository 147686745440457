export default {
  parts: ['header', 'logo', 'container'],
  baseStyle: {
    type: "light",
    logo: {

    },
    header: {
      height: "56px",
      boxShadow: "lg",
      bg: "white"
    },
    container: {
      maxW: "container.full"
    }
  }
};
