export const INVALIDATE_DATA = 'SearchProvider/INVALIDATE_DATA';
export const REQUEST_DATA = 'SearchProvider/REQUEST_DATA';
export const RECEIVE_DATA = 'SearchProvider/RECEIVE_DATA';
export const SET_FILTER_LABELS = 'SearchProvider/SET_FILTER_LABELS';
export const SET_FILTER = 'SearchProvider/SET_FILTER';
export const REMOVE_FILTER = 'SearchProvider/REMOVE_FILTER';
export const SET_DEFAULT_FILTERS = 'SearchProvider/SET_DEFAULT_FILTERS';
export const CLEAR_FILTERS = 'SearchProvider/CLEAR_FILTERS';
export const SET_MAP_OPEN = 'SearchProvider/SET_MAP_OPEN';
export const SET_QUERY = 'SearchProvider/SET_QUERY';
export const SET_SIZE = 'SearchProvider/SET_SIZE';
export const SET_FROM = 'SearchProvider/SET_FROM';
export const SET_PAGE = 'SearchProvider/SET_PAGE';
export const SET_INITIAL_QUERY = 'SearchProvider/SET_INITIAL_QUERY';
export const SET_INITIAL_AGGS = 'SearchProvider/SET_INITIAL_AGGS';
export const SET_INITIAL_SCRIPTS = 'SearchProvider/SET_INITIAL_SCRIPTS';
export const SET_INITIAL_SORT = 'SearchProvider/SET_INITIAL_SORT';
export const SET_INITIAL_FUNCTIONS = 'SearchProvider/SET_INITIAL_FUNCTIONS';
export const SET_INITIAL_SETTINGS = 'SearchProvider/SET_INITIAL_SETTINGS';
export const SET_SEARCH_ON_MAP_MOVE = 'SearchProvider/SET_SEARCH_ON_MAP_MOVE';
export const SET_COLLECTION = 'SearchProvider/SET_COLLECTION';
export const SET_FIRST_LOAD = 'SearchProvider/SET_FIRST_LOAD';
export const SEARCHBAR_SEARCH = 'SearchProvider/SEARCHBAR_SEARCH';

export const FILTER_NUMBER_TYPE_SELECT = 'SELECT';
export const FILTER_NUMBER_TYPE_COUNTER = 'COUNTER';

export const URL_PARAM_ARRAY_ITEM_SEP = '__';
export const URL_PARAM_ARRAY_SEP = '~';

//export const SET_ERROR = 'SearchProvider/SET_ERROR';

/*
export const SET_IS_LOADING = 'SearchProvider/SET_IS_LOADING';
export const SET_IS_ERROR = 'SearchProvider/SET_IS_ERROR';
export const SET_ERROR = 'SearchProvider/SET_ERROR';
export const SET_QUERY = 'SearchProvider/SET_QUERY';
export const SET_RESULTS = 'SearchProvider/SET_RESULTS';
export const SET_DATA = 'SearchProvider/SET_DATA';
export const FETCH_PROPERTIES = 'SearchProvider/FETCH_PROPERTIES';
*/
