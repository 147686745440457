import PropTypes from 'prop-types';

export const stylePropTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export const typographyStylePropTypes = PropTypes.shape({
  color: PropTypes.string,
  colorHover: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
});

export const HEADER_LAYOUT_THEME_OPTIONS = ['LEFT_LOGO'];
export const MENU_THEME_OPTIONS = ['LIGHT', 'DARK'];
