import { window } from 'browser-monads';
import { get, find, isEqual } from 'lodash';
import {numericStringToNumber, trimLastSlash} from '@rentivo/gatsby-core/src/utils/strings';
import { URL_PARAM_ARRAY_SEP } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import { getUrlSearchParams, buildPath, pushState } from '@rentivo/gatsby-core/src/utils/urlParams';

export const removeFilterUrlParams = (urlParams) => {
  urlParams = (Array.isArray(urlParams)) ? urlParams : [urlParams];
  const urlSearchParams = new URLSearchParams(window.location.search);

  urlParams.forEach(up => {
    urlSearchParams.delete(up);
  });

  return pushState(buildPath(urlSearchParams));
};

export const setFilterUrlParam = (filter) => {
  if(!filter) return false;
  const { defaultValue, value, urlParam } = filter || {};

  const urlParams = getUrlSearchParams();

  // If value does NOT equal defaultValue, add it back. This means, when it's at the default, it's not applied.
  if(!isEqual(defaultValue, value)) {
    urlParams.set(urlParam, urlParamValueTransformer(value, filter));
  } else {
    urlParams.delete(urlParam);
  }

  return pushState(buildPath(urlParams));
};

export const getValueFromOptions = (value, filterOptions) => {

  value = decodeURIComponent(value);
  value = trimLastSlash(value);

  // Determine if this value is JSON.
  if(value.includes('[') && value.includes(']')) {
    value = `{ "value": ${value} }`;
    if (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      value = JSON.parse(value).value;
    } else{
      value = null;
    }

    if(filterOptions && filterOptions.options && filterOptions.options.length) {
      const match = find(filterOptions.options, (o) => isEqual(o.urlValue, value));
      return (match) ? match.value : value;
    } else {
      return value;
    }
  }

  // Check if filterOptions has options to parse value from
  if(filterOptions && filterOptions.options && filterOptions.options.length) {
    // Does the value match an option, if so, use
    const match = find(filterOptions.options, (o) => numericStringToNumber(o.urlValue) === numericStringToNumber(value));
    return (match) ? numericStringToNumber(match.value) : numericStringToNumber(value);
  } else {
    return numericStringToNumber(value);
  }
};

export const getUrlValueFromOptions = (value, filterOptions) => {

  if(Array.isArray(value)) {
    return JSON.stringify(value);
  }

  if(filterOptions && filterOptions.options && filterOptions.options.length) {
    const match = find(filterOptions.options, (o) => numericStringToNumber(o.value) === numericStringToNumber(value));
    return (match) ? numericStringToNumber(match.urlValue) : numericStringToNumber(value);
  } else {
    return numericStringToNumber(value);
  }
};

export const urlParamValueTransformer = (value, filterOptions) => {
  if(Array.isArray(value)) {
    value = value.map(v => getUrlValueFromOptions(v, filterOptions));
    return value.join(URL_PARAM_ARRAY_SEP);
  } else {
    return getUrlValueFromOptions(value, filterOptions);
  }
};

export const urlParamValueParser = (value, filterOptions) => {
  if(!value) return null;
  if(value.includes(URL_PARAM_ARRAY_SEP)) {
    return value.split(URL_PARAM_ARRAY_SEP).map(v => getValueFromOptions(v, filterOptions));
  } else {
    return getValueFromOptions(value, filterOptions);
  }
};

const getUrlAndCollectionParamsToLoop = (urlParams, collectionFilters) => {
  const params = {};

  if(collectionFilters && collectionFilters.length) {
    collectionFilters.forEach(({filterKey, filterValue}) => {
      params[filterKey] = filterValue;
    })
  }

  // Make URlParams override collection filters..
  let numberOfUrlParams = 0;
  urlParams.forEach((value, key) => {
    params[key] = value;
    numberOfUrlParams++;
  });

  return params;
};

export const getDefaultFiltersFromUrlAndCollection = ({reduxState, filtersConfig, queryMap, tagValueMap, collectionFilters }, useUrlParams = true) => {
  const urlParams = (useUrlParams) ? getUrlSearchParams() : [];

  const allFilters = [];
  const defaultFilters = [];

  const paramsToLoop = getUrlAndCollectionParamsToLoop(urlParams, collectionFilters);

  for (let [key, value] of Object.entries(paramsToLoop)) {
    const filterOptions = find(filtersConfig, (f) => f.urlParam === key);

    const parsedValue = urlParamValueParser(value, filterOptions);

    if(filterOptions && filterOptions.active && parsedValue) {
      allFilters.push({
        filterOptions,
        id: filterOptions.id,
        value: parsedValue,
      });
    }
  }

  allFilters.forEach(({value, filterOptions}) => {
    const filterKey = filterOptions.id;

    // Create default query to run & push filter to state
    const queryFunction = get(queryMap, filterKey, false);
    const tagValueFunction = get(tagValueMap, filterKey, false);

    const query = (queryFunction) ? queryFunction({filterOptions, allFilters, value, reduxState}) : false;
    const tagValue = (tagValueFunction) ? tagValueFunction({filterOptions, allFilters, value, reduxState}) : value;

    defaultFilters.push({
      ...filterOptions,
      value,
      query,
      tagValue
    })
  });

  return defaultFilters;
};
