export const arrayToObject = (array) => {
  const object = {};

  array.forEach((item, i) => {
    const key = (item.id) ? item.id : i;
    const { id, ...rest } = item;
    object[key] = rest;
  });

  return object;
};

export const isArray = (arr) => Array.isArray(arr);

export const nullOrUndefinedToEmptyArray = (item) => item ? item : [];

export const groupArrayByChunks = (arr, len) => {
  let chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
};

export const findItems = (array, condition) => {
  array = (array && array.length) ? array : [];
  return array.filter(a => condition(a));
};

export const findItem = (array, condition) => {
  const items = findItems(array, condition);
  return (items && items.length) ? items[0] : null;
};

export const shuffle = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
