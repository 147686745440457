/*
 *
 * LanguageProvider actions
 *
 */

import { CHANGE_LOCALE, UPDATE_LANGUAGE, UPDATE_LANGUAGE_URI } from '@rentivo/gatsby-core/src/containers/LanguageProvider/constants';

export function changeLocale(locale, lang) {
  return {
    type: CHANGE_LOCALE,
    locale,
    lang,
  };
}

export function updateLanguage(language) {
  return {
    type: UPDATE_LANGUAGE,
    language: language,
  };
}

export function updateLanguageUri(uri) {
  return {
    type: UPDATE_LANGUAGE_URI,
    uri
  };
}
