import { nestedDataField } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/DiscountsFilter/constants';
import { selectSearchResultsIncludeDiscountsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

export const query = ({filterOptions, value}) => {
  if(!value || value.length === 0) return null;

  value = (Array.isArray(value)) ? value : [value];
  const query = {"bool": {"must": []}};

  value.map((value) => {
    query.bool.must.push({
      "nested": {
        "path": nestedDataField,
        "query": {
          "term": {
            [nestedDataField + ".classification.keyword"]: value
          }
        }
      }
    });
  });

  return query;
};

export const defaultQuery = query;

export const initialQuery = ({reduxState}) => {
  if(!reduxState) return null;
  const includeDiscount = selectSearchResultsIncludeDiscountsConfig(reduxState);
  if(includeDiscount) {
    return {
      "scripts": {
        discounts: {
          script: {
            id: 'lycan_discounts_lookup'
          }
        }
      }
    };
  } else {
    return null;
  }
};
