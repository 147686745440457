import { mode } from "@chakra-ui/theme-tools"

const styles = {
  global: (props) => ({
    body: {
      fontFamily: "body",
      color: mode("text", "whiteAlpha.900")(props),
      bg: mode("white", "gray.800")(props),
      transition: "background-color 0.2s",
      lineHeight: "base",
    },
    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
    ".chakra-popover__popper > section:empty": {
      display: "none"
    },
    ".wpg-blocks": {
      /*
      "> .wpg-block .wpg-block:not(.wpg-b_inner-row-block):not(.wpg-b_quote):not(.wpg-b_pullquote)": {
        paddingLeft: 0,
        paddingRight: 0
      },*/
      /*
      ".chakra-container .chakra-container": {
        paddingLeft: 0,
        paddingRight: 0
      },*/
      /*
      ".wpg-block:not(.wpg-b_row-block):not(.wpg-b_paragraph):not(.wpg-b_heading):not(.wpg-b_hero-gallery)": {
        marginBottom: 6,
        "&:last-child": {
          marginBottom: 0
        }
      },
      ".wpg-b_paragraph": {
        marginBottom: 5,
        "&:last-child": {
          marginBottom: 0
        }
      },
      ".wpg-b_heading": {
        marginBottom: 3,
        "&:last-child": {
          marginBottom: 0
        }
      },
      */
      ".wp-block-image": {
        "figure.size-large img": {
          maxW: "100%"
        },
        "figure.size-medium img": {
          maxW: "container.lg"
        },
        "figure.size-small img": {
          maxW: "container.sm"
        },
        "figure.size-thumbnail img": {
          maxW: "300px"
        },
        "figure.alignleft": {
          float: "left",
          maxW: "calc(5 * (100vw / 12))",
          mt: 0,
          ml: 0,
          mr: 3
        },
        "figure.alignright": {
          float: "right",
          maxW: "calc(5 * (100vw / 12))",
          mt: 0,
          ml: 3,
          mr: 0
        },
        "figure.aligncenter": {
          ml: "auto",
          mr: "auto",
          textAlign: "center",
          display: "inline-block"
        }
      },
      ".light-text": {
        color: "whiteAlpha.800",
        "h1, h2, h3, h4, h5, h6": {
          color: "white"
        }
      }
    }
  })
};

export default styles;


/*
.wp-block-image.size-large img { width: 1200px; max-width: 100%; }
  .wp-block-image.size-medium img { width: 992px; max-width: 100%; }
  .wp-block-image.size-small img { width: 568px; max-width: 100%; }
  .wp-block-image.size-thumbnail img { width: 300px; max-width: 100%; }


> *.alignleft {
float: left;
max-width: ~"calc(5 * (100vw / 12))";
margin-top: 0;
margin-left: 0;
margin-right: 1rem;
}

> *.alignright {
  float: right;
  max-width: ~"calc(5 * (100vw / 12))";
  margin-top: 0;
  margin-right: 0;

  margin-left: 1rem;

@media(max-width: ${props => props.theme['screen-lg']}) {
    margin-left: 0;
  }
}

> *.aligncenter {
  margin-left: auto;
  margin-right: auto;
}
 */
