import axios from 'axios';
import {
  INVALIDATE_ENQUIRY,
  POST_ENQUIRY,
  RECEIVE_ENQUIRY
} from '@rentivo/gatsby-core/src/containers/EnquiryProvider/constants';
import {
  selectApiLycanChannelIdConfig,
  selectApiLycanHostConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { makeSelectEnquiryData } from '@rentivo/gatsby-core/src/containers/EnquiryProvider/selectors';

const createEnquiry = store => next => action => {

  // Pass all actions through by default
  next(action);

  switch (action.type) {
    case POST_ENQUIRY:

      const formId = action.formId;
      const payload = action.payload;

      const state = store.getState();
      const { app } = selectElasticSearchConfig(state);
      const host = selectApiLycanHostConfig(state);
      const channelId = selectApiLycanChannelIdConfig(state);
      const siteName = selectSiteNameConfig(state);
      const enquiryEndpoint = `${host}/api/public/channels/${channelId}/form`;
      //const enquiryData = makeSelectEnquiryData(state);
      const enquiryData = {
        name: formId,
        fields: {
          ...payload
        }
      };

      /*
      {
        name: 'customer-contact',
        fields: {
          email: eqData.email,
          firstName: eqData.firstName,
          lastName: eqData.lastName,
          phone: eqData.phone,
          website: eqData.website,
          message: eqData.guestMessage,
          body: eqData.guestMessage,
          arrivalDate: eqData.arrivalDate,
          departureDate: eqData.departureDate,
          adults: parseInt(eqData.adults),
          children: parseInt(eqData.children),
          infants: parseInt(eqData.infants),
          pets: parseInt(eqData.pets),
        }
      };
       */

      /*
      setTimeout(() => {
        next({type: RECEIVE_ENQUIRY, data: enquiryData, formId});
      }, 3000);
      */

      axios({
        method: 'post',
        url: enquiryEndpoint,
        data: enquiryData,
        headers: {
          'Accept': 'application/json',
          'X-App': siteName,
          'X-Index': app,
          //'Bypass-Tunnel-Reminder': 'Yes'
        }
      }).then(({ status, data }) => {
        if (status >= 200 || status < 300) {
          next({type: RECEIVE_ENQUIRY, data: enquiryData, formId});
        } else {
          next({type: INVALIDATE_ENQUIRY, error: 'Could not send enquiry', formId});
        }
      }).catch((error) => {
        console.log({error: error.message});
        next({type: INVALIDATE_ENQUIRY, error: error.message, formId});
      });

      break;

    // Do nothing if the action does not interest us
    default:
      break
  }
};

export default createEnquiry;
