import React, { useContext } from 'react';

export const ResponsiveContext = React.createContext({});

export const withResponsive = Component => (props) => (
  <ResponsiveContext.Consumer>
    {responsive => <Component {...props} {...responsive} />}
  </ResponsiveContext.Consumer>
);

export const MobileView = ({children, wrap = false, el: El = 'div'}) => {
  const { isMobile } = useContext(ResponsiveContext);
  if(!wrap) return (isMobile) ? children : null;
  return (isMobile) ? <El>{children}</El> : null;
};

export const MobileOnlyView = ({children, wrap = false, el: El = 'div'}) => {
  const { isMobileOnly } = useContext(ResponsiveContext);
  if(!wrap) return (isMobileOnly) ? children : null;
  return (isMobileOnly) ? <El>{children}</El> : null;
};

export const TabletView = ({children, wrap = false, el: El = 'div'}) => {
  const { isTablet } = useContext(ResponsiveContext);
  if(!wrap) return (isTablet) ? children : null;
  return (isTablet) ? <El>{children}</El> : null;
};

export const ComputerView = ({children, wrap = false, el: El = 'div'}) => {
  const { isComputer } = useContext(ResponsiveContext);
  if(!wrap) return (isComputer) ? children : null;
  return (isComputer) ? <El>{children}</El> : null;
};

export const ComputerOrTabletView = ({children, wrap = false, el: El = 'div'}) => {
  const { isComputerOrTablet } = useContext(ResponsiveContext);
  if(!wrap) return (isComputerOrTablet) ? children : null;
  return (isComputerOrTablet) ? <El>{children}</El> : null;
};
