import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton/component';
import PropTypes from 'prop-types';
import { stylePropTypes } from '@rentivo/gatsby-core/src/constants/propTypes';

Button.defaultProps = {
  type: 'default',
  ghost: false,
  block: false,
};

Button.propTypes = {
  type: PropTypes.string,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  ...stylePropTypes
};

export default Button;
