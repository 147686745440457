import {
  RECEIVE_ES_DATA,
  REQUEST_ES_DATA,
  INVALIDATE_ES_DATA,
  RESET_ES_DATA,
  CLEAR_ITEM
} from '@rentivo/gatsby-core/src/containers/ESProvider/constants';

export const itemDefault = {
  data: undefined,
  loading: true,
  didInvalidate: false,
  error: null
};

export const initialState = {
  items: {},
};

const updateDataElement = (state, key, newData) => {
  const item = (state.items[key]) ? state.items[key] : itemDefault;
  const updatedItem = { ...item, ...newData };
  return {
    ...state,
    items: {
      ...state.items,
      [key]: updatedItem
    }
  }
};

function esReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ES_DATA:
      return updateDataElement(state, action.key, { loading: true, error: null, data: undefined, didInvalidate: false });
    case INVALIDATE_ES_DATA:
      return updateDataElement(state, action.key, { loading: false, data: undefined, error: action.error, didInvalidate: true });
    case RECEIVE_ES_DATA:
      return updateDataElement(state, action.key, { loading: false, data: action.data, error: null, didInvalidate: false });
    case RESET_ES_DATA:
      return updateDataElement(state, action.key, { data: (action.data) ? action.data : undefined });
    case CLEAR_ITEM:
      const items = state.items;
      items[action.key] = undefined;
      return {
        ...state,
        items
      };
    default:
      return state;
  }
}

export default esReducer;
