import React, { forwardRef, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { shortSize } from '@rentivo/gatsby-core/src/utils/transformers';
import LocaleLink from '@rentivo/gatsby-core/src/components/navigation/LocaleLink';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import debounce from 'lodash/debounce';

const ButtonComponent = forwardRef(({
                                      children,
                                      type,
                                      htmlType = 'button',
                                      variant,
                                      block,
                                      ghost,
                                      size,
                                      colorScheme,
                                      href,
                                      to,
                                      dispatch,
                                      onClick,
                                      useDebounce,
                                      debounceDelay = 500,
                                      ...rest
                                    }, ref) => {

  const extraProps = {};
  const linkProps = {};

  type = (colorScheme || variant) ? undefined : type;

  if(ghost && type !== 'default') {
    type = `${type}Ghost`;
  }

  if(block) {
    rest.width = '100%';
    rest.display = 'block';
    linkProps.width = '100%';
    linkProps.display = 'block';
  }

  if(onClick && useDebounce) {
    extraProps.onClick = useCallback(debounce((...args) => onClick(...args), debounceDelay), []);
  }

  return (
    <ConditionalWrapper condition={(href || to)} wrapper={(children) => <LocaleLink className={`button-link`} to={to ? to : href} {...linkProps}>{children}</LocaleLink>}>
      <Button
        ref={ref}
        size={shortSize(size)}
        variant={(!type && variant) ? variant : (type) ? type : undefined}
        colorScheme={colorScheme}
        type={htmlType}
        onClick={onClick}
        {...rest}
        {...extraProps}
      >
        {children}
      </Button>
    </ConditionalWrapper>
  );
});

export default ButtonComponent;
