import get from 'lodash/get';
import { createDeepEqualSelector } from '@rentivo/gatsby-core/src/selectors/utils';
import {
  selectApiLycanHostConfig, selectDefaultCurrencyConfig,
  selectElasticSearchConfig,
  selectSiteNameConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import {
  makeSelectPricingDataDisplay,
  makeSelectPricingHasPrice,
  selectPricingDataStayBreakdown, selectPricingOrderItemsFromData, selectPricingPaymentItemsFromData, selectPricingDataPairings
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { getBaseReservationData } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/utils';
import {
  RESERVATION_TYPE_ENQUIRY,
  STATUS_PENDING,
  STATUS_PENDING_PAYMENT
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import {
  commercePricingSelectorEmulator,
  makeSelectCommercePropertyAndPricingInCart, selectCommerceChargeCurrency, selectCommerceCustomerBilling,
  selectCommerceCustomerInfo
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import { createSelector } from 'reselect';

const commercePricingSelector = createSelector(
  selectPricingDataStayBreakdown,
  makeSelectPricingHasPrice,
  makeSelectPricingDataDisplay,
  (stayBreakdown, hasPrice, pricingDataDisplay) => {
    return {
      stayBreakdown,
      hasPrice,
      pricingDataDisplay
    }
  }
);

export const makeSelectPropertyReservationData = createDeepEqualSelector(
  selectSiteNameConfig,
  selectDefaultCurrencyConfig,
  (state) => state.reservationType,
  (state) => state.token,
  (state) => state.browserInfo,
  commercePricingSelectorEmulator(commercePricingSelector),
  makeSelectCommercePropertyAndPricingInCart,
  selectCommerceCustomerInfo,
  selectCommerceCustomerBilling,
  selectLang,
  selectCommerceChargeCurrency,
  (siteName, defaultCurrency, reservationType, paymentToken, browserInfo, pricing, { propertyInCart, pricingInCart }, customerInfo, billingInfo, preferredLocale, chargeCurrency) => {

    // TODO: Why not get reservationType from checkout flow?

    // TODO: What about the other items in the cart?
    // TODO: And finally, what about multiple properties?

    const { stayBreakdown, pricingDataDisplay, hasPrice } = pricing;
    const { id: propertyId } = propertyInCart;
    const baseData = getBaseReservationData({stayBreakdown, siteName, chargeCurrency});

    const termsAndConditions = get(billingInfo, 'termsAndConditions', true);
    const marketingOptIn = get(billingInfo, 'marketingOptIn', false);

    const resData = {
      ...baseData,
      adults: pricingInCart.adults,
      children: pricingInCart.children,
      infants: pricingInCart.infants,
      pets: pricingInCart.pets,
      bookingCurrency: (baseData.bookingCurrency) ? baseData.bookingCurrency : currency,
      reservationType: reservationType,
      reservationStatus: paymentToken !== null && paymentToken !== undefined ? STATUS_PENDING_PAYMENT : STATUS_PENDING,
      propertyUuid: propertyId,
      marketingOptIn,
      termsAndConditions,
      bookingLocale: preferredLocale,
      quoteRef: pricingInCart.data['XID'], // TODO: Does this even work???
      guest: {
        email: customerInfo.email,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        phoneNumbers: customerInfo.phone ? [customerInfo.phone] : [],
        preferredLocale
      },
      guestAddress: {
        addressLine1: customerInfo.addressLine1,
        addressLine2: customerInfo.addressLine2,
        addressCity: customerInfo.city,
        addressState: customerInfo.state,
        addressPostalCode: customerInfo.zip
      },
      guestMessage: (customerInfo.guestMessage) ? customerInfo.guestMessage : null,
      orderItems: selectPricingOrderItemsFromData(pricingDataDisplay, hasPrice),
      payments: selectPricingPaymentItemsFromData(pricingDataDisplay, hasPrice, reservationType, pricingInCart.data.splitDetails),
    };

    if (customerInfo.country != null && customerInfo.country.length === 2) {
      resData.guestAddress.addressCountryISO2 = customerInfo.country;
    } else {
      resData.guestAddress.addressCountry = customerInfo.country;
    }

    if (paymentToken !== undefined && paymentToken !== null) {
      resData.paymentToken = paymentToken;
      resData.browserInfo = browserInfo;
    }

    return resData;
  }
);
