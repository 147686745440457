import {
  CLEAR_PRICING,
  INVALIDATE_PRICING_DATA, RECEIVE_PRICING_DATA, REQUEST_PRICING_DATA,
  SET_DATES_POPOVER_OPEN,
  SET_DEFAULT_VALUES,
  SET_GUESTS_POPOVER_OPEN,
  SET_VALUES,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants';
import { setUrlParams } from '@rentivo/gatsby-core/src/containers/PricingProvider/urlParams';
import dayjs from 'dayjs';

export const initialState = {
  propertyId: null,
  propertyName: null,
  property: null,
  guests: 0,
  adults: 0,
  children: 0,
  infants: 0,
  pets: 0,
  startDate: null,
  endDate: null,
  inputsCache: {},
  datesPopoverOpen: false,
  guestsPopoverOpen: false,
  isFetching: false,
  didInvalidate: false,
  data: null,
  loading: false,
  error: null,
  lastUpdated: null
};

function pricingReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PRICING:
      return initialState;
    case INVALIDATE_PRICING_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
        data: null
      };
    case REQUEST_PRICING_DATA:
      return {
        ...state,
        didInvalidate: false,
        error: null,
        isFetching: true
      };
    case RECEIVE_PRICING_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: action.data,
        inputsCache: action.inputsCache,
        lastUpdated: dayjs().valueOf()
      };
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        ...action.payload
      };
    case SET_VALUES:
      setUrlParams(action.payload, action.dataConfig);
      return {
        ...state,
        ...action.payload
      };
    case SET_DATES_POPOVER_OPEN:
      return {
        ...state,
        datesPopoverOpen: action.open
      };
    case SET_GUESTS_POPOVER_OPEN:
      return {
        ...state,
        guestsPopoverOpen: action.open
      };
    default:
      return state;
  }
}

export default pricingReducer;
