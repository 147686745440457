import initialQueryMap from '@rentivo/gatsby-core/src/containers/SearchProvider/maps/initialQueryMap';

export const buildSearchQuery = (initialQuery, filters = []) => {
  if(!filters.length) return initialQuery;
  const force = [];
  const query = {
    bool: {
      must: []
    }
  };

  filters.forEach(f => {
    if(f.query === true) {
      force.push(f.id);
    } else {
      if(f.query) {
        if(f.query.query) {
          query.bool.must.push(f.query.query);
        } else {
          if(!f.query.sort && !f.query.aggs && !f.query.functions && !f.query.from) { // Do not push sort / aggs or functions...
            query.bool.must.push(f.query);
          }
        }
      }
    }
  });

  if(!query.bool.must.length) return initialQuery;

  return { query, force };
};

export const buildSearchAggs = (initialAggs, filters = []) => {
  if(!filters.length) return initialAggs;

  const aggs = {

  };

  filters.forEach(f => {
    if(f.query && f.query.aggs) {
      const entries = Object.entries(f.query.aggs);
      entries.forEach(e => {
        aggs[e[0]] = e[1];
      });
    }
  });

  if(Object.keys(aggs).length === 0) return initialAggs;

  return aggs;
};

export const buildSearchObjects = (key, initial, filters = []) => {
  if(!filters.length) return initial;

  const keys = {};

  filters.forEach(f => {
    if(f.query && f.query[key]) {
      const entries = Object.entries(f.query[key]);
      entries.forEach(e => {
        keys[e[0]] = e[1];
      });
    }
  });

  if(Object.keys(keys).length === 0) return initial;

  return keys;
};

export const buildSearchSort = (initialSort, filters = []) => {
  if(!filters.length) return initialSort;

  const sort = [];

  filters.forEach(f => {
    if(f.query && f.query.sort) {
      f.query.sort.forEach(s => {
        sort.push(s);
      });
    }
  });

  if(sort.length === 0) return initialSort;

  return sort;
};

export const buildSearchFunctions = (initialFunctions, filters = []) => {
  if(!filters.length) return initialFunctions;

  const functions = [];

  filters.forEach(f => {
    if(f.query && f.query.functions) {
      f.query.functions.forEach(f => {
        functions.push(f);
      });
    }
  });

  if(functions.length === 0) return initialFunctions;

  return functions;
};

export const getFrom = (initialFrom, filters = []) => {
  if(!filters.length) return initialFrom;
  let from = initialFrom;
  filters.forEach(f => {
    if(f.query && f.query.from) {
      from = f.query.from;
    }
  });

  return from;
};

export const prepareInitialQuery = (filtersConfig, initialFilterQueryMap, reduxState) => {
  const queryMap = { ...initialQueryMap, ...initialFilterQueryMap };

  const entries = Object.entries(queryMap);

  // Sift through and pull out just queries
  const queries = [];
  entries.forEach(e => {
    const filterId = e[0];
    const queryFunction = e[1];

    if(filtersConfig[filterId]) {
      const query = queryFunction({reduxState, filterOptions: filtersConfig[filterId]});
      if(query) {
        if(query.query) {
          queries.push(query.query);
        } else if(!query.aggs && !query.functions && !query.sort && !query.from) {
          queries.push(query);
        }
      }
    }
  });

  if(queries.length) {

    const query = {
      bool: {
        must: []
      }
    };

    queries.forEach(q => {
      query.bool.must.push(q);
    });

    return query;

  } else {
    return {
      "match_all": {}
    };
  }
};

export const prepareInitialAggs = (filtersConfig, initialFilterQueryMap, reduxState) => {
  const queryMap = { ...initialQueryMap, ...initialFilterQueryMap };
  const entries = Object.entries(queryMap);

  // Sift through and pull out just queries
  const queries = [];
  entries.forEach(e => {
    const filterId = e[0];
    const queryFunction = e[1];

    if(filtersConfig[filterId]) {
      const query = queryFunction({reduxState, filterOptions: filtersConfig[filterId]});
      if(query && query.aggs) {
        queries.push(query);
      }
    }
  });

  if(queries.length) {

    const aggs = {};

    queries.forEach(q => {
      const entries = Object.entries(q);
      entries.forEach(e => {
        aggs[e[0]] = e[1];
      });
    });

    return aggs.aggs;
  } else {
    return {};
  }
};

export const prepareInitialObjects = (key, filtersConfig, initialFilterQueryMap, reduxState) => {
  const queryMap = { ...initialQueryMap, ...initialFilterQueryMap };
  const entries = Object.entries(queryMap);

  // Sift through and pull out just queries
  const queries = [];
  entries.forEach(e => {
    const filterId = e[0];
    const queryFunction = e[1];

    if(filtersConfig[filterId]) {
      const query = queryFunction({reduxState, filterOptions: filtersConfig[filterId]});
      if(query && query[key]) {
        queries.push(query);
      }
    }
  });

  if(queries.length) {

    const keys = {};

    queries.forEach(q => {
      const entries = Object.entries(q);
      entries.forEach(e => {
        keys[e[0]] = e[1];
      });
    });

    return { ...keys[key] };
  } else {
    return {};
  }
};

export const prepareInitialSort = (filtersConfig, initialFilterQueryMap, reduxState) => {
  const queryMap = { ...initialQueryMap, ...initialFilterQueryMap };
  const entries = Object.entries(queryMap);

  // Sift through and pull out just queries
  const sort = [];

  entries.forEach(e => {
    const filterId = e[0];
    const queryFunction = e[1];

    if(filtersConfig[filterId]) {
      const query = queryFunction({reduxState, filterOptions: filtersConfig[filterId]});
      if(query) {
        if(query.sort) {
          query.sort.forEach(s => {
            sort.push(s);
          });
        }
      }
    }
  });

  return sort;
};

export const prepareInitialFunctions = (filtersConfig, initialFilterQueryMap, functionsConfig = [], reduxState) => {
  const queryMap = { ...initialQueryMap, ...initialFilterQueryMap };
  const entries = Object.entries(queryMap);

  // Sift through and pull out just queries
  const functions = [];

  entries.forEach(e => {
    const filterId = e[0];
    const queryFunction = e[1];

    if(filtersConfig[filterId]) {
      const query = queryFunction({reduxState, filterOptions: filtersConfig[filterId]});
      if(query) {
        if(query.functions) {
          query.functions.forEach(f => {
            functions.push(f);
          });
        }
      }
    }
  });

  return [...functions, ...functionsConfig];
};
