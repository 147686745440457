import React from 'react';
import LanguageProvider from '@rentivo/gatsby-core/src/containers/LanguageProvider';
import { RouterProvider } from '@rentivo/gatsby-core/src/context/RouterContext';
import { PageContextProvider } from '@rentivo/gatsby-core/src/context/PageContext';
import { GlobalStyle } from '@rentivo/gatsby-core/src/containers/App/styles';
import { Helmet } from 'react-helmet';
import CookiePopup from '@rentivo/gatsby-core/src/components/generic/CookiePopup';
import { withProfiler } from '@sentry/gatsby';
//import loadable from '@loadable/component';

import App from '@rentivo/gatsby-core/src/containers/App';
//const App = loadable(() => import('@rentivo/gatsby-core/src/containers/App'));


const Page = ({element, props}) => {
  let { pageContext } = props;
  const { lang } = pageContext;
  const { uri, path, location } = props;
  const routerProps = { uri, path, location };

  pageContext = {
    ...pageContext,
    ...routerProps
  };

  const isClient = (typeof window !== 'undefined' && window.isClient);

  return (
    <LanguageProvider locale={lang} key={lang}>
      <Helmet>
        <html lang={lang} />
      </Helmet>
      <RouterProvider {...routerProps}>
        <PageContextProvider value={pageContext}>
          <GlobalStyle/>
          <div className="cookie-wrapper">
            {isClient && <CookiePopup/>}
          </div>
          <App {...props}>{element}</App>
        </PageContextProvider>
      </RouterProvider>
    </LanguageProvider>
  );
};

export default withProfiler(Page);
