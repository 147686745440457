import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_IN_CART,
  SET_DEFAULT_VALUES,
  CHECKOUT_NEXT_STEP,
  CHECKOUT_PREV_STEP,
  SET_CUSTOMER_INFO,
  CHECKOUT_SET_STEP,
  SET_CUSTOMER_BILLING,
  POST_RESERVATION,
  POSTING_REQUEST_TO_BOOK_RESERVATION, POSTING_INSTANT_BOOK_RESERVATION, RECEIVE_RESERVATION, CLEAR_CART
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { navigate } from 'gatsby';
import {
  selectCommerceCurrentStep,
  selectCommerceSteps
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import {
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import { clearPricing } from '@rentivo/gatsby-core/src/containers/PricingProvider/actions';

export const startSetDefaultValues = (params) => {
  return async (dispatch) => {
    await dispatch(setDefaultValues(params));
  };
};

export function setDefaultValues(payload) {
  return {
    type: SET_DEFAULT_VALUES,
    payload
  };
}

export function addToCart(product, inCheckout) {
  return {
    type: ADD_TO_CART,
    product,
    inCheckout
  };
}

export function removeFromCart(productOrProductId) {
  const productId = (typeof productOrProductId === 'object') ? productOrProductId.id : productOrProductId;
  return {
    type: REMOVE_FROM_CART,
    productId
  };
}

export function updateInCart(product) {
  return {
    type: UPDATE_IN_CART,
    product
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export const startChangeStep = (type = 'next') => {
  return async (dispatch, getState) => {

    if(type === 'next') await dispatch(nextStep());
    if(type === 'prev') await dispatch(prevStep());
    if(Number.isInteger(type)) await dispatch(setStep(type));

    const state = getState();
    const currentStep = selectCommerceCurrentStep(state);
    const steps = selectCommerceSteps(state);
    return navigate(steps[currentStep].path);
  };
};

export function nextStep() {
  return {
    type: CHECKOUT_NEXT_STEP
  };
}

export function prevStep() {
  return {
    type: CHECKOUT_PREV_STEP
  };
}

export function setStep(stepIndex) {
  return {
    type: CHECKOUT_SET_STEP,
    stepIndex
  };
}

export function setCustomerInfo(info, merge = false) {
  return {
    type: SET_CUSTOMER_INFO,
    info,
    merge
  };
}

export function setCustomerBilling(billing, merge = false) {
  return {
    type: SET_CUSTOMER_BILLING,
    billing,
    merge
  };
}

export function createReservation(reservationType = RESERVATION_TYPE_INSTANT_BOOKING, token, browserInfo, pg) {
  return {
    type: POST_RESERVATION,
    reservationType,
    token,
    browserInfo,
    pg
  };
}

export function postingRequestToBookReservation() {
  return {
    type: POSTING_REQUEST_TO_BOOK_RESERVATION
  };
}

export function postingInstantBookReservation() {
  return {
    type: POSTING_INSTANT_BOOK_RESERVATION
  };
}

export const startCreateRequestToBookReservation = () => {
  return async (dispatch) => {
    dispatch(postingRequestToBookReservation());
    dispatch(createReservation(RESERVATION_TYPE_REQUEST_TO_BOOK, null));
  };
};

export const startCreateInstantBookReservation = (token, browserInfo, pg) => {
  return async (dispatch) => {
    dispatch(postingInstantBookReservation());
    dispatch(createReservation(RESERVATION_TYPE_INSTANT_BOOKING, token, browserInfo, pg));
  };
};


export function receiveData(data) {
  return {
    type: RECEIVE_RESERVATION,
    data
  };
}

export const startReceiveData = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const steps = selectCommerceSteps(state);
    await dispatch(receiveData(data)); // TODO: Clear all of checkout data...
    await dispatch(startChangeStep(steps.length - 1)); // Always go to the last step.
    dispatch(clearCart());
    dispatch(clearPricing());
  };
};
