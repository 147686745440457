
const colorSchemeVariant = (paletteColor = 'primary', ghost = false) => {
  return {
    bg: (ghost) ? "transparent" : paletteColor + ".500",
    color: (ghost) ? paletteColor + ".500" : "white",
    borderColor: paletteColor + ".500",
    "_hover": {
      bg: paletteColor + ".600",
      borderColor: paletteColor + ".600",
      color: "white"
    },
    "_focus": {
      bg: paletteColor + ".600",
      borderColor: paletteColor + ".600",
      color: "white"
    },
    "_active": {
      bg: paletteColor + ".600",
      borderColor: paletteColor + ".600",
      color: "white"
    }
  }
};

const pseudoItems = (props, focusProps = props, activeProps = props) => ({
  "_hover": props,
  "_focus": focusProps,
  "_active": activeProps
});

export default {
  baseStyle: {
    transitionDuration: '0.1s',
    borderWidth: "1px",
    borderStyle: "solid",
    lineHeight: "1.2",
    borderRadius: "md",
    fontWeight: "semibold",
    _focus: {
      boxShadow: "outline",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none",
    },
    _hover: {
      _disabled: {
        bg: "initial"
      }
    }
  },
  sizes: {
    sm: {
      h: "32px"
    },
    md: {
      h: "40px"
    },
    lg: {
      h: "48px"
    },
    xl: {
      h: "56px",
      fontSize: "lg",
      px: "32px"
    },
  },
  variants: {
    primary: colorSchemeVariant('primary'),
    primaryGhost: colorSchemeVariant('primary', true),
    secondary: colorSchemeVariant('secondary'),
    secondaryGhost: colorSchemeVariant('secondary', true),
    default: {
      bg: "#ffffff",
      color: "text",
      borderColor: "gray.200",
      //boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
      "_hover": {
        borderColor: "primary.500",
      },
      "_focus": {
        borderColor: "primary.500",
        boxShadow: "none"
      },
      "_active": {
        borderColor: "primary.500",
        boxShadow: "none"
      }
    },
    white: {
      bg: "#ffffff",
      color: "text",
      borderColor: "#ffffff",
      ...pseudoItems({
        borderColor: "gray.100",
        bg: "gray.100"
      })
    },
    whiteGhost: {
      bg: "transparent",
      color: "white",
      borderColor: "white",
      ...pseudoItems({
        borderColor: "white",
        bg: "white",
        color: "text"
      })
    },
    muted: {
      bg: "transparent",
      color: "textLight",
      border: "none",
      ...pseudoItems({
        bg: "rgba(0,0,0,0.05)",
        color: "textLight",
        boxShadow: "none"
      }, {
        bg: "rgba(0,0,0,0.1)",
        color: "textLight",
        border: "none",
        boxShadow: "none"
      })
    },
    menuLight: {
      bg: "transparent",
      color: "text",
      border: "none",
      pl: "0.5rem",
      pr: "0.5rem",
      ...pseudoItems({
        bg: "gray.100",
        color: "text",
        boxShadow: "none"
      }, {
        bg: "transparent",
        color: "text",
        border: "none",
        boxShadow: "none"
      }),
    },
    menuDark: {
      bg: "transparent",
      color: "white",
      border: "none",
      pl: "0.5rem",
      pr: "0.5rem",
      ...pseudoItems({
        bg: "whiteAlpha.300",
        color: "white",
        boxShadow: "none",
      }, {
        bg: "transparent",
        color: "white",
        border: "none",
        boxShadow: "none"
      })
    }
  },
  defaultProps: {
    size: "md"
  }
}
