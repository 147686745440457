export const oddOrEven = (x) => {
  return ( x & 1 ) ? "odd" : "even";
};

export const isEven = (x) => x%2 === 0;

export const isNumeric = (num) => {
  return !isNaN(num)
};

export const separateNumberFromString = (numString) => {
  let number = 0;
  let string = '';

  const matched = numString.match(/\d+/)[0];
  number = (matched.length > 0) ? parseFloat(matched[0]) : number;
  string = (number > 0) ? numString.replace(number,'').trim() : string;

  return {
    number,
    string
  }
};

export const mapRange = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

export const roundHalf = (num) => Math.round(num*2)/2;
