import { mode, transparentize } from '@chakra-ui/theme-tools';

export const variantSubtle = (props) => {
  const { colorScheme: c, theme } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme)

  return {
    bg: mode(`${c}.100`, darkBg)(props),
    color: mode((c === 'gray') ? 'gray.600' : `${c}.800`, `${c}.200`)(props),
  }
};


export default {
  variants: {
    subtle: variantSubtle
  }
}
