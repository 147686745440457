import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_IN_CART,
  SET_DEFAULT_VALUES,
  CHECKOUT_NEXT_STEP,
  CHECKOUT_PREV_STEP,
  PRODUCT_TYPE_PROPERTY,
  SET_CUSTOMER_INFO,
  CHECKOUT_SET_STEP,
  SET_CUSTOMER_BILLING,
  INVALIDATE_RESERVATION,
  THREEDS_CHALLENGE,
  RECEIVE_RESERVATION,
  POST_RESERVATION,
  CLEAR_CART,
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants'
import { calculateCartTotal } from '@rentivo/gatsby-core/src/containers/CommerceProvider/utils'
import {
  RESERVATION_TYPE_INSTANT_BOOKING,
  RESERVATION_TYPE_REQUEST_TO_BOOK,
} from '@rentivo/gatsby-core/src/constants/lycanConstants'

export const initialState = {
  cart: [],
  chargeCurrency: 'USD',
  chargeExchangeRate: 1,
  cartTotalDueToday: 0,
  cartTotalChargeDueToday: 0,
  cartTotal: 0,
  currentStep: 0,
  steps: [],
  customer: null,
  isFetching: false,
  didInvalidate: false,
  isThreeDSChallenge: false,
  transactionToken: null,
  data: null,
  potentialData: null,
  error: null,
  reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
}

let cart = []

function commerceReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_RESERVATION:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        isThreeDSChallenge: false,
        error: action.error,
        data: null,
      }
    case POST_RESERVATION:
      return {
        ...state,
        didInvalidate: false,
        isThreeDSChallenge: false,
        error: null,
        isFetching: true,
      }
    case THREEDS_CHALLENGE:
      return {
        ...state,
        didInvalidate: false,
        isThreeDSChallenge: true,
        transactionToken: action.transactionToken,
        potentialData: action.potentialData,
        error: null,
        isFetching: false,
      }
    case RECEIVE_RESERVATION:
      return {
        ...state,
        isFetching: false,
        isThreeDSChallenge: false,
        didInvalidate: false,
        data: action.data,
      }
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    case ADD_TO_CART:
      let chargeInfo = {}

      if (action.product.type === PRODUCT_TYPE_PROPERTY) {
        // Filter old properties & replace...
        cart = state.cart.filter(p => p.type !== PRODUCT_TYPE_PROPERTY)
        cart = [...cart, action.product]

        chargeInfo = {
          chargeCurrency: action.product.chargeCurrency,
          chargePrice: action.product.chargePrice,
          chargeExchangeRate: action.product.chargeExchangeRate,
        }
      } else {
        cart = [...state.cart, action.product] // Just adding...
        if (state.cart.filter(p => p.id === action.product.id).length) {
          // Don't add if item already exists replace it
          cart = state.cart.map(p =>
            p.id === action.product.id ? action.product : p
          )
        }
      }

      return {
        ...state,
        ...chargeInfo,
        cart,
        cartTotalDueToday: calculateCartTotal(cart, 'price'),
        cartTotalChargeDueToday: calculateCartTotal(cart, 'chargePrice'),
        cartTotal: calculateCartTotal(cart),
      }
    case REMOVE_FROM_CART:
      cart = state.cart.filter(p => p.id !== action.productId)
      return {
        ...state,
        cart,
        cartTotalDueToday: calculateCartTotal(cart, 'price'),
        cartTotalChargeDueToday: calculateCartTotal(cart, 'chargePrice'),
        cartTotal: calculateCartTotal(cart),
      }
    case UPDATE_IN_CART:
      cart = state.cart.map(p =>
        p.id === action.product.id ? { ...p, ...action.product } : p
      )
      return {
        ...state,
        cart,
        cartTotalDueToday: calculateCartTotal(cart, 'price'),
        cartTotalChargeDueToday: calculateCartTotal(cart, 'chargePrice'),
        cartTotal: calculateCartTotal(cart),
      }
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
        cartTotalDueToday: 0,
        cartTotalChargeDueToday: 0,
        cartTotal: 0,
      }
    case CHECKOUT_NEXT_STEP:
      return {
        ...state,
        currentStep:
          state.currentStep + 1 < state.steps.length
            ? state.currentStep + 1
            : state.currentStep,
      }
    case CHECKOUT_PREV_STEP:
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
      }
    case CHECKOUT_SET_STEP:
      return {
        ...state,
        currentStep: action.stepIndex,
      }
    case SET_CUSTOMER_INFO:
      return {
        ...state,
        customer: {
          ...state.customer,
          info: action.merge
            ? { ...state.customer.info, ...action.info }
            : action.info,
        },
      }
    case SET_CUSTOMER_BILLING:
      return {
        ...state,
        customer: {
          ...state.customer,
          billing: action.merge
            ? { ...state.customer.billing, ...action.billing }
            : action.billing,
        },
      }
    default:
      return state
  }
}

export default commerceReducer
