// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---rentivo-gatsby-theme-barcelona-src-pages-404-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/pages/404.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-pages-404-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-author-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Author/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-author-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-blog-category-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/BlogCategory/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-blog-category-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-blog-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Blog/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-blog-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-blog-tag-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/BlogTag/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-blog-tag-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-checkout-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Checkout/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-checkout-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-page-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Page/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-page-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-property-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Property/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-property-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-property-public-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/PropertyPublic/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-property-public-index-js" */),
  "component---rentivo-gatsby-theme-barcelona-src-templates-search-index-js": () => import("./../../../../rentivo-gatsby-theme-barcelona/src/templates/Search/index.js" /* webpackChunkName: "component---rentivo-gatsby-theme-barcelona-src-templates-search-index-js" */)
}

