import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import LocaleLinkComponent from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/component';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';
import {
  selectDefaultLang,
  selectLang,
} from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import {
  selectRoutePathsConfig,
  selectSiteBaseUrlConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

LocaleLinkComponent.defaultProps = {
  activeClassName: 'active',
  className: '',
  el: 'a',
  rel: '',
  target: '_self',
  state: {}
};

const mapStateToProps = createSelector(
  selectRoutePathsConfig,
  selectLang,
  selectDefaultLang,
  selectSiteBaseUrlConfig,
  (routePaths, locale, defaultLang, baseUrl) => {
    return {
      routePaths,
      locale,
      defaultLang,
      baseUrl
    }
  }
);

const enhance = compose(
  connect(mapStateToProps),
  withRouter,
);

export default enhance(LocaleLinkComponent);
