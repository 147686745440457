import { get, toUpper, isEqual } from 'lodash';
import dayjs from 'dayjs';
import {
  PRICING_ENQUIRY_DISPLAY_MODE_NEVER,
  PRICING_ENQUIRY_DISPLAY_MODE_ON_ANY_RESPONSE,
  PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE,
  PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE,
  PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE_INCLUDING_ENQUIRY
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants';
import {
  BOOKABLE_TYPE_ENQUIRY_ONLY, BOOKABLE_TYPE_ENQUIRY_WITH_PRICE,
  BOOKABLE_TYPE_INSTANT_BOOKABLE,
  BOOKABLE_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';

export const shouldFirePricingRequest = (pricing) => {
  const { guests, adults, children, infants, pets, startDate, endDate, propertyId, propertyName, lastUpdated, inputsCache, data } = pricing;
  const canFire = (startDate && endDate && guests && guests > 0);
  if(canFire) {
    if(!lastUpdated || !data) return true; // Update if no data exists
    const isOverTime = (dayjs().subtract(10, 'minute').valueOf() > lastUpdated); // If over time
    const current = { guests, adults, children, infants, pets, startDate, endDate, propertyId, propertyName };
    return (isOverTime || !isEqual(current, inputsCache)); // And if different
  }
};

export const getHasPrice = (total, errors) => {
  return total > 0 && errors.length === 0;
};

export const getCanRequestToBook = ({requestToBookDisplayMode, isRequestToBook, isEnquiry, hasPrice}) => {
  switch (requestToBookDisplayMode) {
    case PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE:
      return isRequestToBook && hasPrice;
    case PRICING_RTB_DISPLAY_MODE_ON_SUCCESSFUL_PRICE_INCLUDING_ENQUIRY:
      return (isRequestToBook || isEnquiry) && hasPrice;
    default:
      // Should never be called
      return false;
  }
};

export const getCanSendEnquiry = ({enquiryDisplayMode, isEnquiry, hasPrice}) => {
  switch (enquiryDisplayMode) {
    case PRICING_ENQUIRY_DISPLAY_MODE_NEVER:
      return false;
    case PRICING_ENQUIRY_DISPLAY_MODE_ON_ANY_RESPONSE:
      return true;
    case PRICING_ENQUIRY_DISPLAY_MODE_ON_FAILED_PRICE:
      return isEnquiry && hasPrice === false;
    default:
      // Should never be called
      return false;
  }
};

export const calculateMinimumPayableAmount = (splitDetails, total) => {
  if (splitDetails === null) return parseFloat(total).toFixed(2); //TODO: Potential bug for currencies with more than 1 point.
  return parseFloat(calculateSplitDetailsDueAmount(splitDetails));
};

export const calculateSplitDetailsDueAmount = (splitDetails) => {
  if (splitDetails === null) return 0;
  let total = 0;


  if (splitDetails?.deposit?.dueNow && splitDetails?.deposit?.amount) {
    total += parseFloat(splitDetails.deposit.amount);
  }

  if (splitDetails?.balance?.dueNow && splitDetails?.balance?.amount) {
    total += parseFloat(splitDetails.balance.amount);
  }

  return total.toFixed(2);
};

export const extractSpreedlyPairing = (pairings, index = 0) => {
  if (pairings.length === 0) return null;
  return pairings[index];
};

export const retrieveChargeCurrency = (priceCurrency, splitDetails, spreedlyPairing) => {
  // Check for split details currency first as a priority
  if (splitDetails !== null && get(splitDetails, 'currency', null) !== null) {
    return toUpper(splitDetails['currency']);
  }

  if (spreedlyPairing !== null) {

    const currencies = spreedlyPairing['supportedCurrencies'];
    for (let i = 0; i < currencies.length; i++) {
      // Search for price currency if it exists use that
      if (currencies[i] === priceCurrency) {
        return priceCurrency;
      }
    }

    if (spreedlyPairing['preferredCurrency'] !== undefined && spreedlyPairing['supportedCurrencies'].includes(spreedlyPairing['preferredCurrency'])) {
      return spreedlyPairing['preferredCurrency'];
    }

    // The same currency as the price generated does not exist
    if (currencies.length > 0) {
      return currencies[0];
    }
  }

  // Default currency return
  return priceCurrency;
};

export const calculateChargeAmountDue = (chargeCurrency, payableCurrencies) => {
  if (chargeCurrency === null) return 0;

  const chargeCurrencyData = retrieveChargeCurrencyData(chargeCurrency, payableCurrencies);

  if (chargeCurrencyData === null) return 0;
  return parseFloat(calculateSplitDetailsDueAmount(chargeCurrencyData));
};

export const retrieveChargeCurrencyData = (chargeCurrency, payableCurrencies) => {
  if (!payableCurrencies) return null;
  if (!payableCurrencies[chargeCurrency]) return null;
  return payableCurrencies[chargeCurrency];
};

export const retrieveChargeCurrencyExchangeRate = (currency, payableCurrencies) => {
  const data = retrieveChargeCurrencyData(currency, payableCurrencies);
  return (data && data.converter) ? data.converter.ratio : 1;
};


export const getPricingRoles = ({enquiryDisplayMode, requestToBookDisplayMode, isAvailable, bookableType, hasPrice, isPriced}) => {
  const isInstantBook = (bookableType === BOOKABLE_TYPE_INSTANT_BOOKABLE);
  const isRequestToBook = (bookableType === BOOKABLE_TYPE_REQUEST_TO_BOOK);
  const isEnquiryOnly = (bookableType === BOOKABLE_TYPE_ENQUIRY_ONLY);
  const isEnquiryWithPrice = (bookableType === BOOKABLE_TYPE_ENQUIRY_WITH_PRICE);
  const isEnquiry = (isEnquiryOnly || isEnquiryWithPrice);
  const canRequestToBook = getCanRequestToBook({requestToBookDisplayMode, isRequestToBook, isEnquiry, hasPrice});
  const canSendEnquiry = getCanSendEnquiry({enquiryDisplayMode, isEnquiry, hasPrice});
  const requiresCheckoutPage = (isInstantBook || canRequestToBook);
  const canMakeReservation = (isAvailable && requiresCheckoutPage);

  return {
    enquiryDisplayMode,
    requestToBookDisplayMode,
    isAvailable,
    isPriced,
    isInstantBook,
    isRequestToBook,
    isEnquiryOnly,
    isEnquiryWithPrice,
    isEnquiry,
    canRequestToBook,
    canSendEnquiry,
    canMakeReservation,
    hasPrice
  };
};
