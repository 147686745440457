import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '@rentivo/gatsby-core/src/state/reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createDebounce from 'redux-debounced';
import fetchSearchData from '@rentivo/gatsby-core/src/containers/SearchProvider/api/fetchSearchData';
import fetchPricingData from '@rentivo/gatsby-core/src/containers/PricingProvider/api/fetchPricingData';
import { composeWithDevTools } from 'redux-devtools-extension';
import createPropertyEnquiry from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/api/createEnquiry';
import createEnquiry from '@rentivo/gatsby-core/src/containers/EnquiryProvider/api/createEnquiry';
import {
  RECEIVE_PRICING_DATA,
  SET_DEFAULT_VALUES as SET_DEFAULT_PRICING_VALUES
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants';
import { ADD_TO_CART, SET_DEFAULT_VALUES as SET_DEFAULT_COMMERCE_VALUES } from '@rentivo/gatsby-core/src/containers/CommerceProvider/constants';
import { selectCommerceCart } from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import createReservation from '@rentivo/gatsby-core/src/containers/CommerceProvider/api/createReservation';
import fireEvents from '@rentivo/gatsby-core/src/containers/EventsProvider/middleware';

const middleware = [
  thunk,
  fetchSearchData,
  fetchPricingData,
  createPropertyEnquiry,
  createEnquiry,
  createReservation,
  fireEvents,
  createDebounce(),
  //createReduxEnhancer
];

const longBlob = '<<LONG_BLOB>>';
const actionSanitizer = (action) => {
  switch(action.type) {
    case SET_DEFAULT_PRICING_VALUES:
      return action.payload ? { ...action, payload: { ...action.payload, property: longBlob, data: longBlob } } : action;
    case SET_DEFAULT_COMMERCE_VALUES:
      return action.payload ? { ...action, payload: { ...action.payload, steps: longBlob } } : action;
    case RECEIVE_PRICING_DATA:
      return action.data ? { ...action, data: longBlob } : action;
    case ADD_TO_CART:
      return action.product ? { ...action, product: { ...action.product, property: longBlob, pricing: longBlob } } : action;
    default:
      return action;
  }
};

const stateSanitizer = (state) => {
  let cart = selectCommerceCart(state);
  cart = cart.map(c => {
    return (c && c.property && c.pricing) ? { ...c, property: longBlob, /*pricing: longBlob*/ } : c; // Remove property from cart...
  });

  return {
    ...state,
    pricing: {
      ...state.pricing,
      property: longBlob
    },
    commerce: {
      ...state.commerce,
      cart,
      //steps: longBlob
    }
  }
};

let composeEnhancer = composeWithDevTools({
  trace: true,
  traceLimit: 25,
  actionsBlacklist: ['persist/REHYDRATE'],
  actionSanitizer,
  stateSanitizer
});

if (process.env.NODE_ENV === `production`) {
  composeEnhancer = compose;
}

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['currency']
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

/*
const enhancer = compose(
  applyMiddleware(...middleware),
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) && window.__REDUX_DEVTOOLS_EXTENSION__()
);*/

// compose(f, g, h) is identical to doing
//  * (...args) => f(g(h(...args))).

// preloadedState will be passed in by the plugin
export default (preloadedState) => reduxCreateStore(persistedReducer, preloadedState, composeEnhancer(applyMiddleware(...middleware)));

//export default reduxCreateStore(rootReducer, {});
