import { trackEvent } from '@rentivo/gatsby-core/src/containers/EventsProvider/utils';
import { trackingItemsArray } from '@rentivo/gatsby-core/src/containers/EventsProvider/constants';
import Cookies from 'js-cookie';
import { defaultOptions } from '@rentivo/gatsby-core/tracking';
import {
  selectTrackingConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

const fireEvents = store => next => action => {

  // Pass all actions through by default
  next(action);

  const state = store.getState();
  const { googleAnalytics: ga, facebookPixel: fp, debug } = selectTrackingConfig(state) || {};
  const gaOpts = { ...defaultOptions.googleAnalytics, ...ga };
  const fpOpts = { ...defaultOptions.facebookPixel, ...fp };

  const canTrackGoogle = (ga && ga.active && ga.trackingId && (Cookies.get(gaOpts.controlCookieName) === 'true' || gaOpts.autoStart));
  const canTrackFacebook = (fp && fp.active && fp.trackingId && (Cookies.get(fpOpts.controlCookieName) === 'true' || fpOpts.autoStart));

  // TODO: When autoStart is turned off... we need a way to turn it on! Via cookie consent...
  const matchedEvents = trackingItemsArray.filter(e => e.type === action.type).filter(e => !e.trigger || (e.trigger && typeof e.trigger === 'function' && e.trigger(action, state)));

  matchedEvents.forEach(({category, action: actionName, getPayload, label, facebookAction, nonInteraction = false}) => {
    let payload = {};
    if(getPayload && typeof getPayload === 'function') {
      payload = getPayload(action, state);
      payload = (typeof payload === 'object') ? payload : { value: payload };
    }

    // TODO: Debounce track event (only if they are different)?
    trackEvent({
      category,
      action: actionName,
      label,
      payload,
      nonInteraction,
      facebookAction,
      debug,
      canTrackGoogle,
      canTrackFacebook
    });

  });

};

export default fireEvents;
