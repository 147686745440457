const colors = {
  "transparent": "transparent",
  "current": "currentColor",
  "black": "#000000",
  "white": "#FFFFFF",
  "primary": {
    "50": "#FFF6F7",
    "100": "#FFEDEF",
    "200": "#FFD3D7",
    "300": "#FFB9BE",
    "400": "#FF848E",
    "500": "#FF4F5D",
    "600": "#E64754",
    "700": "#992F38",
    "800": "#73242A",
    "900": "#4D181C"
  },
  "secondary": {
    "50": "#F4FBFA",
    "100": "#EAF6F5",
    "200": "#C9E9E6",
    "300": "#A9DBD7",
    "400": "#69C0B9",
    "500": "#28A59B",
    "600": "#24958C",
    "700": "#18635D",
    "800": "#124A46",
    "900": "#0C322F"
  },
  "gray": {
    "50": "#F9FAFB",
    "100": "#F3F4F6",
    "200": "#E5E7EB",
    "300": "#D1D5DB",
    "400": "#9CA3AF",
    "500": "#6B7280",
    "600": "#4B5563",
    "700": "#374151",
    "800": "#1F2937",
    "900": "#111827"
  },
  "text": "#000000",
  "textLight": "#718096",
  "link": "#FF4F5D",
  "linkHover": "#E64754"
};

export default colors;
