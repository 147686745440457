import { navigate } from 'gatsby';

// State
import { createSelector } from 'reselect';
import {
  selectDefaultLang,
  selectLang
} from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';

export const selectState = state => state;
export const selectGlobal = state => state.global;

// Other global based selectors
export const makeSelectLocaleNavigate = createSelector(
  selectLang,
  selectDefaultLang,
  (locale, defaultLang) => {
    return (path) => {
      path = path.replace(/^\/|\/$/g, '');
      const langPath = (locale === defaultLang) ? `/${path}` : `/${locale}/${path}`;
      return navigate(langPath);
    }
  }
);
