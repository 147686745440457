import {
  DEFAULT_BREAKPOINT,
  DEVICE_COMPUTER, DEVICE_MOBILE,
  DEVICE_SIZES, DEVICE_TABLET
} from '@rentivo/gatsby-core/src/containers/ThemeProvider/constants';
import { removePx } from '@rentivo/gatsby-core/src/utils/strings';

export const getDevice = (screenSize) => {
  for (const [device, breakpoints] of Object.entries(DEVICE_SIZES)) {
    if(breakpoints.includes(screenSize)) return device;
  }
  return DEVICE_COMPUTER;
};

export const getBreakpoint = (theme) => {
  const sizes = {
    xs: removePx(theme['screen-xs']),
    sm: removePx(theme['screen-sm']),
    md: removePx(theme['screen-md']),
    lg: removePx(theme['screen-lg']),
    xl: removePx(theme['screen-xl']),
    xxl: removePx(theme['screen-xxl']),
  };
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : sizes[DEFAULT_BREAKPOINT]; // Assume lg screen as backup. (SSR will use this)
  let result = {};
  Object.keys(sizes).some(name => {
    const breakpoint = sizes[name];
    if (breakpoint && breakpoint >= windowWidth) {
      const device = getDevice(name);
      result = {
        screenSize: name,
        breakpoint: sizes[name],
        device: getDevice(name),
        isMobile: (device === DEVICE_MOBILE || device === DEVICE_TABLET),
        isMobileOnly: (device === DEVICE_MOBILE),
        isTablet: (device === DEVICE_TABLET),
        isComputer: (device === DEVICE_COMPUTER),
        isComputerOrTablet: (device === DEVICE_COMPUTER || device === DEVICE_TABLET)
      };
      return true;
    }
    result = {
      screenSize: 'xxl',
      breakpoint: sizes['xxl'],
      device: DEVICE_COMPUTER,
      isMobile: false,
      isMobileOnly: false,
      isTablet: false,
      isComputer: true,
      isComputerOrTablet: true
    };
    return false;
  });

  return result;
};
