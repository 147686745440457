
export const trackEvent = ({canTrackGoogle, canTrackFacebook, facebookAction, category, action, label, payload, nonInteraction = false, transport, hitCallback, callbackTimeout = 1000, debug = false}) => {
  if ((typeof window !== `undefined`)) {

    if(window.gtag && typeof window.gtag === 'function' && action && canTrackGoogle) {
      let trackingEventOptions = {
        event_category: category,
        event_label: label,
        non_interaction: nonInteraction
      };

      trackingEventOptions = (typeof payload === 'object') ? { ...trackingEventOptions, ...payload } : (payload) ? { ...trackingEventOptions, value: payload } : trackingEventOptions;
      if(debug) console.log('GTAG Event', action, trackingEventOptions);
      window.gtag(`event`, action, trackingEventOptions);
    }

    if(window.fbq && typeof window.fbq === 'function' && canTrackFacebook && facebookAction) {
      payload = (typeof payload === 'object') ? payload : {};
      if(debug) console.log('Facebook Event', facebookAction, payload);
      window.fbq(`track`, facebookAction, payload);
    }
  }
};
