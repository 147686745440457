import React, { useCallback, useMemo } from 'react';
import { Link } from '@chakra-ui/react';
import { navigate, Link as ReachLink } from 'gatsby';
import {
    addUrlParamString,
    generateLocaleLink,
    isInternal, isMailOrTel
} from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/utils';
import { addQueryToRoutePath } from '@rentivo/gatsby-core/src/utils/urlParams';
import { useLocation } from '@reach/router';

const shouldNavigate = event =>
  !event.defaultPrevented &&
  event.button === 0 &&
  !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const LocaleLinkComponent = ({
   locale,
   baseUrl,
   itemProp,
   state: stateFromProps,
   defaultLang,
   to: toProps,
   rel,
   layerStyle,
   target,
   children,
   el,
   activeClassName,
   className,
   location,
   dispatch,
   routePaths,
   queryParamsString,
   match,
   ...rest
}) => {
  const { search, pathname } = useLocation();
  const state = {
    prevPathname: pathname,
    prevSearch: search,
    prevPath: pathname + search,
    ...stateFromProps // TODO: This refreshes pages as it has getState in property. Do we need this anymore?
  };

  const { toLocale, isInternalTo, as, isMailOrTelTo } = useMemo(() => {
    const toWithParam = addUrlParamString(toProps, baseUrl, queryParamsString);
    const toLocale = generateLocaleLink(locale, defaultLang, toWithParam);
    const isInternalTo = isInternal(toLocale);
    const isMailOrTelTo = isMailOrTel(toLocale);

    const as = (el) ? el : 'a';
    return {
      to: toWithParam,
      toLocale,
      isInternalTo,
      as,
      isMailOrTelTo
    }
  }, [toProps, baseUrl, queryParamsString, locale, defaultLang, el]);

  const handleLinkClick = useCallback((e) => {
    if(shouldNavigate(e)) {
      e.preventDefault();

      const { pathname } = location;
      const isInternalTo = isInternal(toLocale);
      const to = (pathname.includes(routePaths.ROUTE_BASE_SEARCH) && toLocale.includes(routePaths.ROUTE_BASE_SEARCH)) ? addQueryToRoutePath(toLocale) : toLocale;

      if (target === '_blank') {
        window.open(toLocale, '_blank');
      } else if(isInternalTo) {
        navigate(to);
      } else {
        window.location = to;
      }
    }
  }, [location, toLocale, routePaths, target, isInternalTo]);

  if (target === '_blank' || !isInternalTo) {
    return <Link
      as={as}
      onClick={isMailOrTelTo ? undefined : handleLinkClick}
      itemProp={itemProp}
      href={isInternalTo ? toLocale : toProps}
      className={className}
      {...rest}
    >
      {children}
    </Link>;
  } else {
    return <Link
      isExternal={true}
      layerStyle={layerStyle}
      as={ReachLink}
      to={toLocale}
      className={className}
      itemProp={itemProp}
      state={state}
      {...rest}
    >
      {children}
    </Link>;
  }
};

export default LocaleLinkComponent;
