export const SET_DEFAULT_VALUES = 'CommerceProvider/SET_DEFAULT_VALUES';
export const ADD_TO_CART = 'CommerceProvider/ADD_TO_CART';
export const REMOVE_FROM_CART = 'CommerceProvider/REMOVE_FROM_CART';
export const UPDATE_IN_CART = 'CommerceProvider/UPDATE_IN_CART';
export const CLEAR_CART = 'CommerceProvider/CLEAR_CART';
export const CHECKOUT_NEXT_STEP = 'CommerceProvider/CHECKOUT_NEXT_STEP';
export const CHECKOUT_PREV_STEP = 'CommerceProvider/CHECKOUT_PREV_STEP';
export const CHECKOUT_SET_STEP = 'CommerceProvider/CHECKOUT_SET_STEP';
export const SET_CUSTOMER_INFO = 'CommerceProvider/SET_CUSTOMER_INFO';
export const SET_CUSTOMER_BILLING = 'CommerceProvider/SET_CUSTOMER_BILLING';
export const POSTING_REQUEST_TO_BOOK_RESERVATION = 'CommerceProvider/POSTING_REQUEST_TO_BOOK_RESERVATION';
export const POSTING_INSTANT_BOOK_RESERVATION = 'CommerceProvider/POSTING_INSTANT_BOOK_RESERVATION';
export const POST_RESERVATION = 'CommerceProvider/POST_RESERVATION';
export const RECEIVE_RESERVATION = 'CommerceProvider/RECEIVE_RESERVATION';
export const INVALIDATE_RESERVATION = 'CommerceProvider/INVALIDATE_RESERVATION';
export const THREEDS_CHALLENGE = 'CommerceProvider/THREEDS_CHALLENGE';

export const PRODUCT_TYPE_PROPERTY = 'property';
export const CHECKOUT_STEP_REVIEW_PATH = '/';
export const CHECKOUT_STEP_CUSTOMER_INFO_PATH = '/info';
export const CHECKOUT_STEP_CONFIRM_PATH = '/confirm';
export const CHECKOUT_STEP_SUCCESS_PATH = '/success';
