import get from 'lodash/get';
import {
  CLEAR_ENQUIRY_FORM, INVALIDATE_ENQUIRY, POST_ENQUIRY, RECEIVE_ENQUIRY,
  SET_ENQUIRY_DEFAULT_VALUES,
  SET_ENQUIRY_FORM
} from '@rentivo/gatsby-core/src/containers/EnquiryProvider/constants';

export const initialFormState = {
  isFetching: false,
  didInvalidate: false,
  error: null,
  data: null
};

export const initialState = {
  formsValues: {},
  forms: {}
};

function enquiryProviderReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_ENQUIRY:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.formId]: {
            ...state.forms[action.formId],
            isFetching: false,
            didInvalidate: true,
            error: action.error,
            data: null
          }
        }
      };
    case POST_ENQUIRY:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.formId]: {
            ...state.forms[action.formId],
            didInvalidate: false,
            error: null,
            isFetching: true
          }
        }
      };
    case RECEIVE_ENQUIRY:
      return {
        ...state,
        formsValues: {
          ...state.formsValues,
          [action.formId]: {
            ...state.formsValues[action.formId],
            guestMessage: undefined,
            message: undefined
          }
        },
        forms: {
          ...state.forms,
          [action.formId]: {
            ...state.forms[action.formId],
            isFetching: false,
            didInvalidate: false,
            data: action.data
          }
        }
      };
    case SET_ENQUIRY_DEFAULT_VALUES:
      return {
        ...state,
        formsValues: {
          ...state.formsValues,
          [action.formId]: {
              ...get(state, `formsValues.${action.formId}`, {}),
              ...action.form
          }
        },
        forms: {
          ...state.forms,
          [action.formId]: initialFormState
        }
      };
    case SET_ENQUIRY_FORM:
      return {
        ...state,
        formsValues: {
          ...state.formsValues,
          [action.formId]: {
            ...get(state, `formsValues.${action.formId}`, {}),
            ...action.payload
          }
        }
      };
    case CLEAR_ENQUIRY_FORM:
      return {
        ...state,
        formsValues: {
          ...state.formsValues,
          [action.formId]: {}
        }
      };
    default:
      return state;
  }
}

export default enquiryProviderReducer;
