import React, { useContext } from 'react';
import { createSelector } from 'reselect';
import {
  makeSelectPageContextCollection,
  makeSelectPageContextHasUrlSearchQuery,
  makeSelectPageContextSearchData
} from '@rentivo/gatsby-core/src/selectors/pageContext';

export const PageContextContext = React.createContext({});

export const usePageContext =  () => {
  return useContext(PageContextContext);
};

export const withPageContext = (selector = null) => WrappedComponent => {
  return (props) => {
    const pageContext = useContext(PageContextContext);
    const selectorPageContext = (selector) ? selector({...pageContext}, props) : {};
    return <WrappedComponent {...props} pageContext={pageContext} {...selectorPageContext}/>;
  };
};

export const withPageContextSearchData = () => WrappedComponent => {
  return (props) => {
    const pageContext = useContext(PageContextContext);
    const selector = createSelector(
      makeSelectPageContextHasUrlSearchQuery(),
      makeSelectPageContextSearchData(),
      (hasUrlSearchQuery, searchData) => ({
        hasUrlSearchQuery,
        searchData
      })
    );
    const selectorPageContext = selector({...pageContext});
    return <WrappedComponent {...props} {...selectorPageContext}/>;
  };
};

export const withPageContextCollection = () => WrappedComponent => {
  return (props) => {
    const pageContext = useContext(PageContextContext);
    const selector = createSelector(
      makeSelectPageContextCollection(),
      (contextCollection) => ({
        contextCollection
      })
    );
    const selectorPageContext = selector({...pageContext});
    return <WrappedComponent {...props} {...selectorPageContext}/>;
  };
};

export const PageContextProvider = ({ value, children }) => {
  return (
    <PageContextContext.Provider value={value}>
      {children}
    </PageContextContext.Provider>
  );
};
