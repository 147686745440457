import dayjs from 'dayjs';
import {
  INVALIDATE_DATA,
  RECEIVE_DATA,
  REMOVE_FILTER,
  REQUEST_DATA,
  SET_DEFAULT_FILTERS,
  SET_FILTER,
  SET_FILTER_LABELS,
  SET_INITIAL_AGGS,
  SET_INITIAL_SCRIPTS,
  SET_INITIAL_SORT,
  SET_INITIAL_QUERY,
  SET_INITIAL_FUNCTIONS,
  SET_SIZE,
  SET_INITIAL_SETTINGS,
  SET_SEARCH_ON_MAP_MOVE,
  SET_COLLECTION, SET_FIRST_LOAD, CLEAR_FILTERS, SET_MAP_OPEN,
} from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import { removeFilterUrlParams, setFilterUrlParam } from '@rentivo/gatsby-core/src/containers/SearchProvider/utils/urlParams';

export const initialState = {
  firstLoad: true,
  isFetching: false,
  didInvalidate: false,
  loadedDefaultFilters: false,
  searchOnMapMove: false,
  mapMoved: false,
  mapOpen: false,
  error: null,
  data: {},
  query: null,
  perPage: 12,
  totalHits: 10000,
  filters: [],
  filterLabels: {},
  initialQuery: {},
  initialAggs: {},
  initialScripts: {},
  initialSort: [],
  initialFunctions: [],
  collection: null,
  lastUpdated: null
};

const updateFilterQueries = (filters, urlParam, queryMap, tagValueMap, reduxState) => {

  return filters.map(f => {

    if(f.urlParam !== urlParam) { // Don't update filter in question
      if(f.listensTo && f.listensTo.includes(urlParam) && queryMap) { // Only update filters which are listening OR filters that just changed...
        // Create default query to run & push filter to state
        const queryFunction = queryMap[f.id];
        const tagValueFunction = tagValueMap[f.id];

        const { value, tagValue, query, ...filterOptions } = f;
        const newQuery = (queryFunction) ? queryFunction({filterOptions, allFilters: filters, value: f.value, reduxState}) : false;
        const newTagValue = (tagValueFunction) ? tagValueFunction({filterOptions, allFilters: filters, value: f.value, reduxState}) : f.value;

        f.query = newQuery;
        f.tagValue = newTagValue;
      }
    } else {
      // Just update its tag value if its the filter in question. Important for features, as their tags change, even though the filter hasn't
      const tagValueFunction = tagValueMap[f.id];
      const { value, tagValue, query, ...filterOptions } = f;
      f.tagValue = (tagValueFunction) ? tagValueFunction({filterOptions, allFilters: filters, value: f.value, reduxState}) : f.value;
    }
    return f;
  });
};

function searchReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_DATA:
      return {
        ...state,
        firstLoad: false,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
        data: null,
        results: []
      };
    case REQUEST_DATA:
      return {
        ...state,
        didInvalidate: false,
        error: null,
        query: action.query,
        isFetching: true
      };
    case RECEIVE_DATA:
      return {
        ...state,
        firstLoad: false,
        isFetching: false,
        didInvalidate: false,
        data: action.data,
        lastUpdated: dayjs(),
        loadedDefaultFilters: true
      };
    case SET_FIRST_LOAD:
      return {
        ...state,
        firstLoad: action.firstLoad
      };
    case SET_FILTER:
      if(!action.filter) return state;
      if(!action.filter.active) return state;

      let setUpdatedFilters = state.filters;

      // Remove if it exists
      setUpdatedFilters = setUpdatedFilters.filter(f => f.urlParam !== action.filter.urlParam);

      // If value does NOT equal defaultValue, add it back. This means, when it's at the default, it's not applied.
      if(`${action.filter.defaultValue}` !== `${action.filter.value}`) {
        setUpdatedFilters = [...setUpdatedFilters, action.filter];
      }

      // Manage URL params
      if(action.applyUrlParam) setFilterUrlParam(action.filter);

      // Update all other filter queries, if they are listening
      const filtersWithUpdatedQueries = (action.runQuery) ? updateFilterQueries(setUpdatedFilters, action.filter.urlParam, action.queryMap, action.tagValueMap, action.reduxState) : setUpdatedFilters;

      return {
        ...state,
        filters: filtersWithUpdatedQueries
      };

    case REMOVE_FILTER:
      let removeUpdatedFilters = state.filters;

      // Manage URL params
      if(action.urlParam) {
        const removeUrlParamsToDelete = [];
        removeUrlParamsToDelete.push(action.urlParam);
        removeUpdatedFilters.forEach(f => {
          if(f.dependsOn === action.urlParam) removeUrlParamsToDelete.push(f.urlParam);
        });

        removeFilterUrlParams(removeUrlParamsToDelete);
      }

      // Filter & filters which depend upon it
      const removedFilters = removeUpdatedFilters.filter(f => f.urlParam !== action.urlParam && f.dependsOn !== action.urlParam); // remove filter & related filters

      // Update all other filter queries, if they are listening
      const removedFiltersWithUpdatedQueries = updateFilterQueries(removedFilters, action.urlParam, action.queryMap, action.tagValueMap, action.reduxState);

      return {
        ...state,
        filters: removedFiltersWithUpdatedQueries
      };

    case SET_DEFAULT_FILTERS:
      return {
        ...state,
        loadedDefaultFilters: true,
        filters: action.filters,
        defaultFilters: action.filters
      };
    case SET_FILTER_LABELS:
      return {
        ...state,
        filterLabels: action.filterLabels
      };
    case SET_INITIAL_QUERY:
      return {
        ...state,
        initialQuery: action.initialQuery
      };
    case SET_INITIAL_AGGS:
      return {
        ...state,
        initialAggs: action.initialAggs
      };
    case SET_INITIAL_SCRIPTS:
      return {
        ...state,
        initialScripts: action.initialScripts
      };
    case SET_INITIAL_SORT:
      return {
        ...state,
        initialSort: action.initialSort
      };
    case SET_INITIAL_FUNCTIONS:
      return {
        ...state,
        initialFunctions: action.initialFunctions
      };
    case SET_SIZE:
      return {
        ...state,
        size: action.size
      };
    case SET_INITIAL_SETTINGS:
      return {
        ...state,
        ...action.settings
      };
    case SET_SEARCH_ON_MAP_MOVE:
      return {
        ...state,
        searchOnMapMove: action.searchOnMapMove
      };
    case SET_COLLECTION:
      return {
        ...state,
        collection: action.collection
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: [],
        query: null
      };
    case SET_MAP_OPEN:
      return {
        ...state,
        mapOpen: action.mapOpen
      };
    default:
      return state;
  }
}

export default searchReducer;
