import { dataField } from '@rentivo/gatsby-core/src/containers/SearchProvider/components/filters/PriceFilter/constants';
import {selectSearchFilterByKey} from '../../../selectors';

export const query = ({filterOptions, value, allFilters}) => {
  if(!value) return null;

  const { histogramInterval } = filterOptions;

  const losFilter = selectSearchFilterByKey(allFilters, 'los');

  if (losFilter) {
    return null;
  } else {
    return {
      "query": {
        "range" : {
          [dataField]: {
            "gte" : value[0],
            "lte" : value[1],
            "boost" : 2.0
          }
        }
      },
      "aggs": {
        [dataField]: {
          "histogram": {
            "field": dataField,
            "interval": parseInt(histogramInterval),
            "offset": 0
          }
        }
      }
    }
  }

};

export const defaultQuery = query;

export const initialQuery = ({filterOptions}) => {
  const { histogramInterval } = filterOptions;

  return {
    "aggs": {
      [dataField]: {
        "histogram": {
          "field": dataField,
          "interval": parseInt(histogramInterval),
          "offset": 0
        }
      }
    }
  }
};
