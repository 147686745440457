import { createSelector } from 'reselect';
import { get} from 'lodash';

// Locale
export const selectPageContextLang = pageContext => get(pageContext, 'lang', 'en');

// Search Data
export const selectPageContextSearchData = pageContext => get(pageContext, 'searchData', null);
export const makeSelectPageContextSearchData = () => createSelector(selectPageContextSearchData, state => state);

// Search URL
export const selectPageContextHasUrlSearchQuery = pageContext => {
  return (get(pageContext, 'location.search', "").length > 0);
};
export const makeSelectPageContextHasUrlSearchQuery = () => createSelector(selectPageContextHasUrlSearchQuery, state => state);

// Collection
export const selectPageContextCollection = pageContext => get(pageContext, 'collection', null);
export const makeSelectPageContextCollection = () => createSelector(selectPageContextCollection, state => state);

// Property
export const selectPageContextProperty = pageContext => get(pageContext, 'property', null);
