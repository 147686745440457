/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEVICES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  LARGE: 'LARGE'
};
export const ROUTE_CHANGE = 'app/ROUTE_CHANGE';
export const UPDATE_SITE_CONFIG = 'app/UPDATE_SITE_CONFIG';
export const UPDATE_WINDOW_SIZE = 'app/UPDATE_WINDOW_SIZE';
export const UPDATE_IS_LOADER_ON = 'app/UPDATE_IS_LOADER_ON';
export const INIT_WINDOW_SIZE = {
  windowHeight: (typeof window !== 'undefined') ? window.innerHeight : 0,
  windowWidth: (typeof window !== 'undefined') ? window.innerWidth : 0
};

export const BREAKPOINTS = {
    [DEVICES.MOBILE]: 768,
    [DEVICES.TABLET]: 992,
    [DEVICES.DESKTOP]: 1170,
    [DEVICES.LARGE]: 1599
};

export const CURRENT_DEVICE = (windowWidth) => {
  let initDevice = DEVICES.MOBILE;

  if(windowWidth > BREAKPOINTS.MOBILE && windowWidth <= BREAKPOINTS.DESKTOP) {
    initDevice = DEVICES.TABLET;
  }

  if(windowWidth > BREAKPOINTS.TABLET && windowWidth <= BREAKPOINTS.LARGE) {
    initDevice = DEVICES.DESKTOP;
  }

  if(windowWidth > BREAKPOINTS.LARGE) {
    initDevice = DEVICES.LARGE;
  }

  return initDevice;
};
export const INIT_DEVICE = CURRENT_DEVICE(INIT_WINDOW_SIZE.windowWidth);
