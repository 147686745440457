import {
  UPDATE_DATES,
  UPDATE_GUESTS,
  SET_DEFAULT_VALUES,
  SET_VALUES,
  SET_DATES_POPOVER_OPEN,
  SET_GUESTS_POPOVER_OPEN,
  REQUEST_PRICING_DATA,
  INVALIDATE_PRICING_DATA,
  RECEIVE_PRICING_DATA,
  CLEAR_PRICING,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/constants'
import { getDefaultValuesFromUrlParamsAndDataConfig } from '@rentivo/gatsby-core/src/containers/PricingProvider/urlParams'
import { selectPropertyPricingDataConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig'
import { debounce } from 'lodash'
import { shouldFirePricingRequest } from '@rentivo/gatsby-core/src/containers/PricingProvider/utils'
import {
  makeSelectPricingAsProduct,
  selectPricing,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors'
import {
  addToCart,
  setStep,
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/actions'

export function setDefaultValues({
  payload = {},
  dataConfig,
  propertyId,
  propertyName,
  property,
  providedPricing,
}) {
  const defaultDataFromUrlParams = getDefaultValuesFromUrlParamsAndDataConfig(
    dataConfig
  )

  payload = {
    ...payload,
    ...defaultDataFromUrlParams,
    propertyId,
    propertyName,
    property,
    ...providedPricing,
  }

  return {
    type: SET_DEFAULT_VALUES,
    payload,
  }
}

export const startSetDefaultValues = params => {
  return async dispatch => {
    await dispatch(setDefaultValues(params))
    const { inCheckout = false, propertyId } = params
    if (propertyId) dispatch(startRequestPricingDataDebounced(inCheckout))
  }
}

export function clearPricing() {
  return {
    type: CLEAR_PRICING,
  }
}

export function setValues(payload = {}, dataConfig) {
  return {
    type: SET_VALUES,
    payload,
    dataConfig,
  }
}

export const startUpdateDates = (dates, inCheckout = false) => {
  return async (dispatch, getState) => {
    const state = getState()
    const dataConfig = selectPropertyPricingDataConfig(state)
    dispatch(updateDates(dates))
    await dispatch(setValues(dates, dataConfig, dispatch))
    dispatch(startRequestPricingDataDebounced(inCheckout))
  }
}

export function updateDates({ startDate, endDate }) {
  return {
    type: UPDATE_DATES,
    startDate,
    endDate,
  }
}

export const startUpdateGuests = (guests, inCheckout = false) => {
  return async (dispatch, getState) => {
    const state = getState()
    const dataConfig = selectPropertyPricingDataConfig(state)
    dispatch(updateGuests(guests))
    await dispatch(setValues(guests, dataConfig, dispatch))
    dispatch(startRequestPricingDataDebounced(inCheckout))
  }
}

export function updateGuests(guests) {
  return {
    type: UPDATE_GUESTS,
    ...guests,
  }
}

export function setDatesPopoverOpen(open) {
  return {
    type: SET_DATES_POPOVER_OPEN,
    open,
  }
}

export function setGuestsPopoverOpen(open) {
  return {
    type: SET_GUESTS_POPOVER_OPEN,
    open,
  }
}

const startRequestPricingDataFunction = debounce(
  (dispatch, ...args) =>
    setTimeout(() => dispatch(startRequestPricingData(...args)), 25),
  25
)
const startRequestPricingDataDebounced = (...args) => dispatch =>
  startRequestPricingDataFunction(dispatch, ...args)

export function startRequestPricingData(inCheckout = false, currency = null) {
  return async (dispatch, getState) => {
    const state = getState()
    const pricing = selectPricing(state)
    if (currency || shouldFirePricingRequest(pricing)) {
      dispatch(requestPricingData(inCheckout, currency))
    }
  }
}

export function requestPricingData(inCheckout = false, currency = null) {
  return {
    type: REQUEST_PRICING_DATA,
    inCheckout,
    currency,
  }
}

export function invalidateData(error = null) {
  return {
    type: INVALIDATE_PRICING_DATA,
    error,
  }
}

export const startReceiveData = (
  data,
  inputsCache,
  inCheckout = false,
  currency = null
) => {
  return async dispatch => {
    await dispatch(receiveData(data, inputsCache, inCheckout, currency))
    if (inCheckout) {
      dispatch(startAddPricingToCart(inCheckout))
    }
  }
}

export function receiveData(data, inputsCache, inCheckout) {
  return {
    type: RECEIVE_PRICING_DATA,
    data,
    inputsCache,
    inCheckout,
  }
}

export const startAddPricingToCart = (inCheckout = false) => {
  return async (dispatch, getState) => {
    // Reset checkout steps? Why? Resetting so if you go to property page, it starts at the first step.
    if (!inCheckout) {
      await dispatch(setStep(0))
    }

    const state = getState()
    const product = makeSelectPricingAsProduct(state)
    await dispatch(addToCart(product, inCheckout))
  }
}
