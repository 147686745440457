import get from 'lodash/get';
import { document, window } from 'browser-monads';

export const getUrlSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

export const hasUrlParams = () => {
  const urlParams = Array.from(getUrlSearchParams().keys());
  return (urlParams.length > 0);
};

export const buildPath = (urlParams) => {
  const urlParamsString = urlParams.toString();
  return (urlParamsString) ? `${window.location.pathname}?${urlParamsString}` : `${window.location.pathname}`;
};

export const pushState = (path) => {
  return window.history.pushState({}, document.title, path);
};

export const pushUrlParams = (urlParams) => pushState(buildPath(urlParams));

export const slugify = (string) => {
  if(!string) return '';
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
};

export const addQueryToRoutePath = (to, url = get(window, 'location.search', '')) => {
  const splitUrl = url.split('?');

  if(splitUrl.length > 1) {
    return `${to}?${splitUrl[1]}`;
  } else {
    return to;
  }
};
